import React, { useEffect, useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Login from './pages/login';

import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Home from './pages/home';
import ClientDashboard from './pages/clients';
import ErrorPage from './pages/error-page';
import Profile from './pages/profile';
import Leads from './pages/leads';
import Products from './pages/products';
import ManagementDashboard from './pages/management';
import Training from './pages/training/training/index';
import ClientPage from './pages/clientpage';
import Routing from './pages/routing';
import Auth0 from './pages/auth0';
import Chat from './pages/chat';

// import jwtDecode from 'jwt-decode'; // Install this package if not already installed


// Components
import Navigator from './components/Navigator';
import Loader from './components/Loader';
import Footer from './components/Footer';
import Header from './components/Header';

import { OfflineProvider } from './components/OfflineContext'
import { NetworkProvider, useNetwork } from './components/NetworkProvider';

import { FiClock } from 'react-icons/fi';



// Styles
import './App.css';
import { FiWifiOff } from 'react-icons/fi';
const Layout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  // const [isOnline, setIsOnline] = useState(navigator.onLine); // Initial online status
  const [isSlowConnection, setIsSlowConnection] = useState(false); // For slow connection detection
  const { isOnline } = useNetwork();

  // Simulating loading delay
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 100); // Adjust the timeout as needed
    return () => clearTimeout(timeout);
  }, []);

  // Network status detection
  useEffect(() => {
    // const handleOnline = () => setIsOnline(true);
    // const handleOffline = () => setIsOnline(false);

    // window.addEventListener("online", handleOnline);
    // window.addEventListener("offline", handleOffline);

    // Detect slow internet using the Network Information API
    const handleConnectionChange = () => {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        // If the effective connection type is slow or downlink speed is low, show the slow connection message
        const slowConnection = connection.effectiveType === "2g" || connection.effectiveType === "3g" || connection.downlink < 1.5;
        setIsSlowConnection(slowConnection);
      }
    };

    if (navigator.connection) {
      handleConnectionChange(); // Check connection initially
      navigator.connection.addEventListener("change", handleConnectionChange);
    }

    return () => {
      // window.removeEventListener("online", handleOnline);
      // window.removeEventListener("offline", handleOffline);
      if (navigator.connection) {
        navigator.connection.removeEventListener("change", handleConnectionChange);
      }
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {!isOnline && (
        <div
          className="flex flex-row items-center justify-center rounded-full bg-black text-white text-xxs md:w-1/5 w-2/5"
          style={{
            position: "fixed",
            top: "10px", // Slightly away from the top for better visibility
            left: "50%", // Start at the center of the viewport
            transform: "translateX(-50%)", // Offset by half its own width
            textAlign: "center",
            padding: "5px",
            zIndex: 9999,
          }}
        >
          <FiWifiOff /> <span className="ml-3">Vous êtes hors ligne</span>
        </div>
      )}

      {/* Only show the slow connection message if the user is online but has a slow connection */}
      {isOnline && isSlowConnection && (
        <div
          className="flex flex-row items-center justify-center rounded-full bg-yellow-600 text-white text-xxs w-1/3"
          style={{
            position: "fixed",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            textAlign: "center",
            padding: "5px",
            zIndex: 9999,
          }}
        >
          {/* <FiWifi /> <span className="ml-3"></span> */}
        </div>
      )}

      <section className="flex flex-row bg-white">
        <div className="z-10 border-r-1 border-white">
          <Navigator />
        </div>
        <section
          className="w-full bg-white flex flex-col h-screen"
          style={{
            overflowY: "auto",
            overflowX: "hidden"
          }}
        >
          <Header />
          <div
            className="w-full bg-white flex flex-col h-screen"
            style={{
              overflowY: "auto",
            }}
          >
            {children}
          </div>
          <div className="flex-grow"></div>
          <Footer />
        </section>
      </section>
    </>
  );
};
const ProtectedRoute = ({ isAuthenticated, children }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return children;
};

// Register the service workder
const registerServiceWorker = () => {
  if('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      // Force the new service worker to become active immediately
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      navigator.serviceWorker.ready.then(() => {
        const token = localStorage.getItem('token');
        registration.active.postMessage({ type: 'SET_TOKEN', token });
      })
    }).catch(error => {
      console.log('ServiceWorker registration failed: ', error);
    });
  }
}

const TOKEN_CHECK_INTERVAL = 6000; // 1 minute

const checkTokenExpiry = (token) => {

  if (!token) return false; // Treat missing token as expired

  try {
    // Split the token into its parts (header, payload, signature)
    const payloadBase64 = token.split('.')[1]; // The payload is the second part of the token
    if (!payloadBase64) return true;

    // Decode the Base64 string into a JSON object
    const payload = JSON.parse(atob(payloadBase64));

    // Extract the `exp` field (expiration time) from the payload
    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    return payload.exp < now; // Token is expired if `exp` is less than now
  } catch (error) {
    console.error('Error parsing token:', error);
    return false; // Treat invalid token as expired
  }
};

import build_param from "./build_params.json";

const frontendPath = build_param.frontendPath;

const App = () => {
  const { isLoading, isAuthenticated, error } = useAuth0();
  // const [prefetchStatus, setPrefetchStatus] = useState('idle');
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffect(() => {
    registerServiceWorker();
  }, []);

  const { handleRedirectCallback } = useAuth0();

  const { logout } = useAuth0();

    const logout_auth0 = async () => {
        localStorage.removeItem("token");
        localStorage.removeItem("token_test");
        localStorage.removeItem("user");
        localStorage.removeItem("layoutData");
        localStorage.removeItem("language");
        logout({
            logoutParams: {
                returnTo: `${frontendPath}/`,
            },
        });
    };

  // const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    // Check the token initially
    if (checkTokenExpiry(token)) {
      setIsTokenExpired(true);
      // logout_auth0()
    }

    // Set up an interval to check the token every minute
    const intervalId = setInterval(() => {
      const token = localStorage.getItem('token');
      if (checkTokenExpiry(token)) {
        console.log('im logged out')
        setIsTokenExpired(true);
        // logout_auth0()
      }
    }, TOKEN_CHECK_INTERVAL);

    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []);

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      console.log("hihi")
      console.log(url)
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        console.log("waiting")
        // await navigate("/home")
        await handleRedirectCallback(url);
        await Browser.close()
        // await handleRedirectCallback(url);
        console.log("closing")

      }
      // No-op on Android
      // await Browser.close();
    });
  }, [handleRedirectCallback]);


  if (isLoading) {
    return <div><Loader/></div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }



  return (
   <div className='App bg-white'>
    {isAuthenticated ? (

      <BrowserRouter>
       <NetworkProvider>
        <Layout>
          <OfflineProvider>
          {isTokenExpired && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000,
              }}
            >
              <div className='flex flex-col rounded-md border border-black p-10 bg-white items-center m-10'>
              <div className='flex flex-row items-center'><FiClock/><div><h1 className='text-lg font-semibold ml-5'>Session expirée</h1></div></div>
              <p className='w-full'>Votre session a expiré. Veuillez vous reconnecter pour continuer.</p>
                <button className='bg-black rounded-full text-white px-4 py-2 mt-2' onClick={() => {
    logout_auth0(); // Call your custom logout function
    // window.location.reload(); // Reload the page
  }}>Connecter</button>
              </div>
            </div>
          )}
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/auth0" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Auth0 /></ProtectedRoute>} />
              <Route exact path="/clients" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ClientDashboard /></ProtectedRoute>} />
              <Route exact path="/home" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Home /></ProtectedRoute>} />
              <Route exact={true} path="*" element={<ErrorPage />} />
              <Route exact path="/profile" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Profile /></ProtectedRoute>} />
              <Route exact path="/leads" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Leads /></ProtectedRoute>} />
              <Route exact path="/products" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Products /></ProtectedRoute>} />
              <Route exact path="/management/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ManagementDashboard /></ProtectedRoute>} />
              <Route exact path="/training" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Training /></ProtectedRoute>} />
              <Route exact path="/client/:clientId" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ClientPage/></ProtectedRoute>} />
              <Route exact path="/routing" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Routing/></ProtectedRoute>} />
              <Route path="/chat" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Chat/></ProtectedRoute>} />

            </Routes>
            </OfflineProvider>
        </Layout>
        </NetworkProvider>
      </BrowserRouter>
    ) : <Login />}
   </div>
  )
};

export default App;
