/*eslint-disable*/

import React, { useState, useEffect } from "react";

import FastAPIClient from "../../../client";
import config from "../../../config";
const client = new FastAPIClient(config);
const { v4: uuidv4 } = require("uuid");

import { FormattedMessage, IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

import Throbber from "../../../style/Throbber";

import DiscussedProductCategories from "./DiscussedProductCategories";
import Negociation from "./Negociations";
import DiscussedPromotions from "./DiscussedPromotions";
import CustomerSatisfaction from "./CustomerSatisfaction";
import SubmittedDocuments from "./SubmittedDocuments";
import KeyValueList from "./KeyValueList";

import "./style.css";

import { FiMic, FiTrash2 } from "react-icons/fi";

import SpeechRecognition, {
    useSpeechRecognition,
} from "react-speech-recognition";

const NextSteps = ({ field_name, field_id, handleInputChange, responses }) => {
    const [steps, setSteps] = useState(
        responses[field_id] || [{ Propale: "", Date: "" }]
    );

    const handleStepChange = (index, key, value) => {
        const updatedSteps = steps.map((step, i) =>
            i === index ? { ...step, [key]: value } : step
        );
        setSteps(updatedSteps);
        handleInputChange(field_id, updatedSteps);
    };

    const addStep = () => {
        setSteps([...steps, { text: "", date: "" }]);
    };

    const removeStep = (index) => {
        const updatedSteps = steps.filter((_, i) => i !== index);
        setSteps(updatedSteps);
        handleInputChange(field_id, updatedSteps);
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="font-semibold w-full text-left">{field_name}</div>

            {steps.map((step, index) => (
                <div
                    key={index}
                    className="flex flex-col md:flex-row gap-4 items-center w-full"
                >
                    <input
                        type="text"
                        placeholder="Description"
                        value={step.text}
                        onChange={(e) =>
                            handleStepChange(index, "Propale", e.target.value)
                        }
                        className="p-2 border border-gray-300 rounded-md w-full md:w-1/2"
                    />

                    <input
                        type="date"
                        value={step.date}
                        onChange={(e) =>
                            handleStepChange(index, "Date", e.target.value)
                        }
                        className="p-2 border border-gray-300 rounded-md w-full md:w-1/4"
                    />

                    <button
                        onClick={() => removeStep(index)}
                        className="text-red-500 p-2"
                    >
                        <FiTrash2 size={20} />
                    </button>
                </div>
            ))}

            <button
                onClick={addStep}
                className="mt-4 px-4 py-2 bg-black text-white rounded-full w-full md:w-1/4"
            >
                Rajouter une action
            </button>
        </div>
    );
};

const SpeechToTextTextarea = ({
    field_name,
    field_id,
    handleInputChange,
    responses,
}) => {
    const [isListening, setIsListening] = useState(false);
    const [userInput, setUserInput] = useState(""); // State to handle manual input

    const {
        transcript,
        listening,
        resetTranscript,
        startListening,
        stopListening,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            alert("Sorry, your browser does not support speech recognition.");
        }
    }, [browserSupportsSpeechRecognition]);

    useEffect(() => {
        // Keep userInput in sync with the transcript when listening
        if (listening) {
            setUserInput(transcript);
        }
    }, [transcript, listening]);

    const handleMouseDown = () => {
        SpeechRecognition.startListening({
            language: "fr-FR",
            continuous: true,
        });
        setIsListening(true);
    };

    const handleMouseUp = () => {
        SpeechRecognition.stopListening();
        setIsListening(false);
    };

    const handleChange = (e) => {
        setUserInput(e.target.value);
        handleInputChange(field_name, e.target.value); // Notify parent about changes
    };

    return (
        <div className="mt-2 flex flex-col space-y-2 text-left">
            <textarea
                id={field_id}
                value={userInput}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded-lg resize-y w-full"
                placeholder=""
                rows={4}
            />
            {/* <div className="flex gap-2">
                <button
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    className={`py-2 px-4 rounded ${
                        isListening
                            ? "bg-red-500 text-white"
                            : "bg-black text-white"
                    }`}
                >
                    {isListening ? "Listening..." : <FiMic />}
                </button>
            </div> */}
        </div>
    );
};

// const Dictaphone = () => {
//     const {
//         transcript,
//         listening,
//         resetTranscript,
//         browserSupportsSpeechRecognition,
//     } = useSpeechRecognition();

//     if (!browserSupportsSpeechRecognition) {
//         return <span>Browser doesn't support speech recognition.</span>;
//     }

//     return (
//         <div>
//             <p>Microphone: {listening ? "on" : "off"}</p>
//             <button
//                 onClick={() =>
//                     SpeechRecognition.startListening({ language: "fr-FR" })
//                 }
//             >
//                 Start (French)
//             </button>
//             <button onClick={SpeechRecognition.stopListening}>Stop</button>
//             <button onClick={resetTranscript}>Reset</button>
//             <p>{transcript}</p>
//         </div>
//     );
// };

const messages = {
    en: messages_en,
    fr: messages_fr,
};

const ButtonComponent = ({ selectedValue, onChange }) => {
    const handleButtonClick = (value) => {
        onChange(value);
    };

    // Styles for the buttons
    const getButtonStyles = (value) => {
        if (selectedValue === null) {
            return "bg-white border border-gray-600 text-gray-600"; // Both gray if none is selected
        }
        if (selectedValue === value) {
            return value === "oui"
                ? "bg-gold text-black" // Oui selected
                : "bg-red-500 text-white"; // Non selected
        }
        return "bg-white border border-gray-600 text-gray-600"; // Default gray
    };

    return (
        <div className="flex space-x-4">
            <button
                onClick={() => handleButtonClick("oui")}
                className={`py-1 rounded-full px-6 ${getButtonStyles("oui")}`}
            >
                Oui
            </button>
            <button
                onClick={() => handleButtonClick("non")}
                className={`py-1 rounded-full px-6 ${getButtonStyles("non")}`}
            >
                Non
            </button>
        </div>
    );
};

const ReportForm = ({
    reportlayout,
    setToggle,
    clientId,
    userId,
    meetingId,
    userName,
    meeting_type_id,
    locale = "fr",
    setErrorMessage,
    setErrorMessageType,
}) => {
    const [selectedLayout, setSelectedLayout] = useState(null);
    const [responses, setResponses] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Sample reports data (you can extend this structure with more report types)

    useEffect(() => {
        if (meeting_type_id) {
            setSelectedLayout(String(meeting_type_id)); // Ensure it's a string
        }
    }, [meeting_type_id]);

    const language = "fr"; // This can be dynamically set based on user preference

    const translations = {
        en: {
            interest_expressed: "Interest Expressed",
            discussed: "Discussed",
        },
        fr: {
            interest_expressed: "Intérêt manifesté",
            discussed: "Discutée",
            transmitted: "Transmis",
            to_transmit: "A transmettre",
            specific_interest: "Intérêt spécifique",
            product_interest: "Intérêt produit",
            comments: "Commentaires",
            provided_documents: "Documents transmis",
        },

        // Add more languages as needed
    };

    // Get current date formatted as MM/DD/YYYY
    const currentDate = new Date().toLocaleDateString("fr-FR");

    // Handle input changes and update the response state
    const handleInputChange = (fieldName, value) => {
        setResponses((prev) => {
            const newResponses = { ...prev, [fieldName]: value };

            // Check the field that is being updated
            const field = reportlayout[selectedLayout]?.find(
                (field) => field.field_id === fieldName
            );

            // If this field has no visibility condition, check for its dependencies
            if (field) {
                // Iterate through all the fields in the report to find those that depend on the current field
                reportlayout[selectedLayout].forEach((f) => {
                    if (
                        f.visibility_conditions &&
                        f.visibility_conditions.field_id === fieldName
                    ) {
                        const conditionValue = f.visibility_conditions.value;

                        // If the field is conditionally visible based on the value of the current field
                        // and the condition is no longer satisfied, clear the field
                        if (
                            (conditionValue === "yes" && value === "no") ||
                            (conditionValue === "no" && value === "yes")
                        ) {
                            newResponses[f.field_id] = ""; // Clear the dependent field value
                        }
                    }
                });
            }

            return newResponses;
        });
    };

    // Handle form submission
    const handleSaveVisit = async () => {
        setIsSubmitting(true);
        const payload = {
            report_id: uuidv4(),
            meeting_id: meetingId || uuidv4(),
            meeting_type_id: selectedLayout,
            client_id: clientId,
            user_id: userId,
            responses: JSON.stringify(responses), // Save the responses as a JSON string
            created_at: new Date().toISOString(),
        };
        console.log("payload", payload);
        await client.saveReport(payload);
        setIsSubmitting(false);
        setToggle(false);
        setErrorMessage("Les données ont été enregistrées."); // Show error message
        setErrorMessageType("success"); // Show error message
        setTimeout(() => {
            setErrorMessage(null); // Clear error after 3 seconds
        }, 3000);
    };

    // Render the fields dynamically based on report type and visibility conditions
    // Render the fields dynamically based on report type and visibility conditions
    const renderReportFields = () => {
        // const selectedReport = reports[selectedLayout] || [];
        let selectedReport = [];

        // Filter the rows where meeting_type_id matches the selectedLayout
        const filteredRows = reportlayout.filter(
            (item) => item.meeting_type_id === selectedLayout
        );

        if (filteredRows.length > 0) {
            // Extract the fields arrays from the filtered rows
            const fields = filteredRows.map((item) => item.fields);
            selectedReport = fields[0];
        }

        // console.log("fields", fields[0]); // Output the array of fields arrays

        console.log("selectedReport", selectedReport);

        return selectedReport
            .sort((a, b) => a.field_id.localeCompare(b.field_id)) // Sort by field_id
            .map((field) => {
                const {
                    field_id,
                    field_name,
                    input_type,
                    visibility_conditions: rawVisibilityConditions,
                    input_options,
                } = field;

                console.log(
                    "Raw visibility_conditions:",
                    rawVisibilityConditions
                );

                let visibility_conditions = null;

                try {
                    visibility_conditions = JSON.parse(rawVisibilityConditions); // Convert string to object
                } catch (error) {
                    console.error(
                        "Error parsing visibility_conditions:",
                        error
                    );
                }

                // Check visibility conditions
                if (visibility_conditions) {
                    const conditionField =
                        responses[visibility_conditions.field_id];

                    if (conditionField !== visibility_conditions.value) {
                        return null; // Don't render this field if visibility condition isn't met
                    }
                }

                return (
                    <div key={field_id} className="w-full mt-5">
                        {input_type === "yes_no" && (
                            <div className="flex flex-wrap md:flex-nowrap gap-4 text-left">
                                {/* Label */}
                                <label
                                    htmlFor={field_id}
                                    className="w-full md:w-1/3 font-semibold"
                                >
                                    {field_name}
                                </label>

                                {/* Input (ButtonComponent for yes/no input) */}

                                <div className="w-full md:w-2/3 mt-2 md:mt-0">
                                    <ButtonComponent
                                        selectedValue={responses[field_id]}
                                        onChange={(value) =>
                                            handleInputChange(field_id, value)
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {input_type === "dropdown" && (
                            <select
                                id={field_id}
                                value={responses[field_id] || ""}
                                onChange={(e) =>
                                    handleInputChange(field_id, e.target.value)
                                }
                                className="mt-2 p-2 border border-gray-300 rounded-full flex flex-row"
                            >
                                <option value="">
                                    Sélectionnez une option.
                                </option>
                                {JSON.parse(input_options).map((option) => (
                                    <option
                                        key={option.label}
                                        value={option.label}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        )}

                        {input_type === "text" && (
                            <div className="mt-2 flex flex-col space-y-2 text-left">
                                <div className="font-semibold">
                                    {field_name}
                                </div>
                                <SpeechToTextTextarea
                                    field_id={field_id}
                                    field_name={field_name}
                                    value={responses[field_id] || ""}
                                    handleInputChange={handleInputChange}
                                    className="p-2 border border-gray-300 rounded-lg resize-y w-full"
                                    placeholder=""
                                    rows={4}
                                    input_tye={"text"}
                                />
                                {/* <SpeechToTextTextarea /> */}
                            </div>
                        )}

                        {input_type === "discussed_product_categories" && (
                            <DiscussedProductCategories
                                field_name={field_name} // Pass the field name
                                field_id={field_id} // Pass the field id
                                handleInputChange={handleInputChange} // Pass the input change handler
                                responses={responses} // Pass the responses
                                options={JSON.parse(input_options)}
                            />
                        )}
                        {input_type === "discussed_promotions" && (
                            <DiscussedPromotions
                                field_name={field_name} // Pass the field name
                                field_id={field_id} // Pass the field id
                                handleInputChange={handleInputChange} // Pass the input change handler
                                responses={responses} // Pass the responses
                                options={JSON.parse(input_options)}
                            />
                        )}

                        {/* Render date input */}
                        {input_type === "date" && (
                            <div className="flex flex-wrap md:flex-nowrap gap-4 text-left">
                                {/* Label */}
                                <label
                                    htmlFor={field_id}
                                    className="w-full md:w-1/2 font-semibold"
                                >
                                    {field_name}
                                </label>
                                <input
                                    type="date"
                                    id={field_id}
                                    value={responses[field_id] || ""}
                                    onChange={(e) =>
                                        handleInputChange(
                                            field_id,
                                            e.target.value
                                        )
                                    }
                                    className="mt-2 p-2 border border-gray-300 rounded-full"
                                />
                            </div>
                        )}

                        {input_type === "next_steps" && (
                            <NextSteps
                                field_name={field_name}
                                field_id={field_id}
                                handleInputChange={handleInputChange}
                                responses={responses}
                            />
                        )}

                        {input_type === "provided_documents" && (
                            <SubmittedDocuments
                                fields={[field]} // Pass an array with the current field
                                handleInputChange={handleInputChange} // Pass the input change handler
                                responses={responses} // Pass the responses
                                options={JSON.parse(input_options)}
                            />
                        )}

                        {input_type === "key_value_list" && (
                            <KeyValueList
                                field_id={field_id} // Pass the field_id
                                handleInputChange={handleInputChange} // Pass the input change handler
                                responses={responses} // Pass the responses
                            />
                        )}
                        {input_type === "product_negociation" && (
                            <Negociation
                                field_id={field_id} // Pass the field_id
                                handleInputChange={handleInputChange} // Pass the input change handler
                                responses={responses} // Pass the responses
                                options={JSON.parse(input_options)}
                            />
                        )}
                        {input_type === "customer_satisfaction" && (
                            <CustomerSatisfaction
                                field_id={field_id} // Pass the field_id
                                handleInputChange={handleInputChange} // Pass the input change handler
                                responses={responses} // Pass the responses
                                options={JSON.parse(input_options)}
                            />
                        )}
                    </div>
                );
            });
    };

    // Handle layout change (select dropdown change)
    const handleLayoutChange = (e) => {
        setSelectedLayout(e.target.value);
    };

    // When selectedLayout changes, reset the responses to initial state
    useEffect(() => {
        setResponses({}); // Reset responses to initial state
    }, [selectedLayout]); // This will trigger every time `selectedLayout` changes

    useEffect(() => {
        console.log("responses", responses);
    }, [responses]);

    useEffect(() => {
        console.log("layout", reportlayout);
    }, [reportlayout]);

    return (
        <section className="fixed inset-0 z-50 w-full bg-white flex flex-col items-center md:ml-20 pl-5 pr-5">
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className="absolute z-50 flex flex-col w-full md:w-4/5 items-center relative min-h-screen pt-20">
                    <div className="h-full overflow-y-auto w-full">
                        {isSubmitting && (
                            <div className="absolute top-1/2 left-1/2 z-10">
                                <Throbber />
                            </div>
                        )}
                        <div className="mt-4 text-left text-xl w-full">
                            <FormattedMessage
                                id="report.title"
                                values={{ number: 1 }}
                            />
                        </div>
                        <div className="flex flex-col md:flex-row text-left w-full mt-5">
                            <div className="w-1/3 font-semibold">
                                <FormattedMessage
                                    id="report.visit_date"
                                    values={{ number: 1 }}
                                />
                            </div>
                            <div className="w-2/3 text-gray-600">
                                {currentDate}
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row text-left w-full mt-5">
                            <div className="w-1/3 font-semibold">
                                <FormattedMessage
                                    id="report.attendees"
                                    values={{ number: 1 }}
                                />
                            </div>
                            <div className="w-2/3 text-gray-600">
                                {userName}
                            </div>
                        </div>

                        {meetingId === null && (
                            <div className="mt-5 w-full flex flex-col md:flex-row md:items-center">
                                <div className="w-full md:w-1/3 mb-2">
                                    <label
                                        htmlFor="layout"
                                        className="text-left block font-semibold"
                                    >
                                        <FormattedMessage
                                            id="report.select_report"
                                            values={{ number: 1 }}
                                        />
                                    </label>
                                </div>
                                {/* <Dictaphone /> */}
                                <div className="w-full md:w-2/3 text-left">
                                    <select
                                        id="layout"
                                        className="border border-gray-300 p-2 rounded-full w-full"
                                        value={selectedLayout}
                                        onChange={handleLayoutChange} // Use the handler here
                                    >
                                        <option value="">
                                            {" "}
                                            <FormattedMessage
                                                id="report.select_report"
                                                values={{ number: 1 }}
                                            />
                                        </option>
                                        {reportlayout
                                            .filter((layout) =>
                                                [
                                                    "MT-01",
                                                    "MT-04",
                                                    "MT-05",
                                                    "MT-06",
                                                    "MT-07",
                                                    "MT-08",
                                                    "MT-12",
                                                    "MT-13",
                                                    "MT-14",
                                                ].includes(
                                                    layout.meeting_type_id
                                                )
                                            )
                                            .sort((a, b) =>
                                                a.description.localeCompare(
                                                    b.description
                                                )
                                            )
                                            .map((layout) => (
                                                <option
                                                    key={layout.meeting_type_id}
                                                    value={
                                                        layout.meeting_type_id
                                                    }
                                                >
                                                    {layout.description}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        )}

                        {/* Render dynamic report fields */}

                        {reportlayout.length > 0 && renderReportFields()}
                    </div>

                    {/* Actions */}
                    {/* Spacer to push the buttons to the bottom */}
                    <div className="flex-grow"></div>

                    {/* Buttons at the bottom */}
                    <div className="flex justify-center w-full mb-20 mt-5 text-lg">
                        <div className="flex space-x-20">
                            <button
                                onClick={() => setToggle(false)}
                                className="w-32 bg-gray-200 rounded-full text-black px-4 py-2 font-semibold"
                            >
                                <FormattedMessage
                                    id="report.cancel"
                                    values={{ number: 1 }}
                                />
                            </button>

                            <button
                                onClick={handleSaveVisit}
                                className="w-32 bg-black rounded-full text-white px-4 py-2 font-semibold"
                            >
                                <FormattedMessage
                                    id="report.save"
                                    values={{ number: 1 }}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        </section>
    );
};

export default ReportForm;
