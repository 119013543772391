/*eslint-disable*/
import React, { useState } from "react";
import {
    FiMaximize2,
    FiX,
    FiChevronUp,
    FiChevronDown,
    FiArrowUp,
    FiArrowDown,
    FiBarChart2,
    FiInfo,
} from "react-icons/fi";

import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const CollapseComponent = ({
    text,
    icon,
    children,
    maximize, // Option to maximize
    toggle, // Option to toggle the collapse
    open,
    tooltipText, 
    showPopUp,
    setShowPopUp
}) => {
    // const [isPopupOpen, setIsPopupOpen] = useState(false); // Track if popup is open
    const [isOpen, setIsOpen] = useState(open); // Track the toggle state

    const togglePopup = () => {
        setShowPopUp(!showPopUp); // Toggle popup visibility
    };

    const toggleCollapse = () => {
        setIsOpen(!isOpen); // Toggle collapse visibility
    };

    const iconCircleStyle = `flex w-8 h-8 bg-gray-100 ml-2 mt-2 rounded-full items-center justify-center`;

    return (
        <div className="overflow-hidden w-full mb-3">
            <div className="ml-1 md:ml-3 mr-1 md:mr-0 border border-gray-200 bg-white rounded-md">
                <div className="flex flex-row w-full pl-2 justify-between mb-2 mt-2 pr-3">
                    <div className="flex flex-row w-5/6 items-center">
                        <div className={iconCircleStyle}>{icon}</div>
                        <div className="flex text-lg md:text-base xl:text-lg text-black text-left ml-2 md:w-4/6 w-full mt-2 relative">
                            {text}
                            {tooltipText && (
                                <div className="relative flex flex-row items-center ml-2 group">
                                    {/* Info Icon */}
                                    <FiInfo className="text-black text-sm hover:text-black cursor-pointer" />
                                    {/* Tooltip */}
                                    <div className="absolute top-1/2 left-full transform -translate-y-1/2 ml-2 hidden group-hover:flex bg-gray-800 text-white text-sm px-4 py-2 rounded-lg shadow-lg z-3 w-[calc(100vw-4rem)] max-w-[400px]">
                                        {/* Tooltip Text */}
                                        <span>{tooltipText}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex xl:w-1/12 md:w-1/12 sm:w-1/12 w-1/12 items-center mt-2">
                        {isOpen && maximize && (
                            // Maximize button centered
                            <div className="flex w-full justify-center">
                                <button onClick={togglePopup}>
                                    <FiMaximize2 />
                                </button>
                            </div>
                        )} 
                        {toggle ? (
                            // Chevron aligned to the right
                            <div className="flex w-full justify-end md:pr-0 pr-2">
                                <button
                                    onClick={toggleCollapse}
                                    className="text-xl"
                                >
                                    {isOpen ? (
                                        <FiChevronUp />
                                    ) : (
                                        <FiChevronDown />
                                    )}
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>

                {isOpen && (
                    <div
                        className={`ml-0 mr-0 mb-5 text-left flex flex-col items-center overflow-y-auto relative ${
                            maximize && "no-scrollbar"
                        } max-h-[300px] sm:max-h-[200px] md:max-h-[200px] lg:max-h-[300px]`}
                    >
                        {children}
                    </div>
                )}
            </div>

        </div>
    );
};

export default CollapseComponent;
