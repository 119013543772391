import React, { useState, useEffect } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Loader from "../../../components/Loader";
import FastAPIClient from "../../../client";
import fast_api_config from "../../../config";

import MeetingModal from "../../../components/ModalComponents/MeetingModal";

// Instantiate the FastAPI client
const client = new FastAPIClient(fast_api_config);

const formatModalDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// Helper function to generate an array of dates for a given month
const generateDates = (date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const dates = [];

    for (let i = firstDay.getDate(); i <= lastDay.getDate(); i++) {
        const formattedDate = new Date(year, month, i)
            .toISOString()
            .split("T")[0];
        dates.push(formattedDate);
    }
    return dates;
};

// Helper function to get the French abbreviation of the day
const getFrenchDayAbbreviation = (date) => {
    const dayNames = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
    return dayNames[date.getDay()];
};

// Helper function to check if the day is a weekend (Saturday or Sunday)
const isWeekend = (date) => date.getDay() === 0 || date.getDay() === 6;

// Helper function to get initials from a name
const getInitials = (name) => {
    return name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("")
        .slice(0, 2);
};

const toCamelCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const SalesReps = ({ setSuccessMessage, setErrorMessage }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const now = new Date();
    const dates = generateDates(currentDate);
    const [user, setUser] = useState(false);
    const currentMonth = currentDate.toLocaleString("fr-FR", {
        month: "long",
        year: "numeric",
    });

    const getUserName = () => {
        client.fetchUser().then((data) => {
            setUser(data);
        });
    };

    useEffect(() => {
        getUserName();
    }, []);

    const camelCaseMonth = toCamelCase(currentMonth);

    const today = new Date().toISOString().split("T")[0]; // Get today’s date in "YYYY-MM-DD"

    const changeMonth = (direction) => {
        const newDate = new Date(currentDate);
        newDate.setMonth(currentDate.getMonth() + direction);
        setCurrentDate(newDate);
    };

    const [meetings, setMeetings] = useState([]);
    const [users, setUsers] = useState([]);

    const getMeetings = async () => {
        try {
            const data = await client.getMeetings();
            const filteredMeetings = data.data?.results.filter(
                (meeting) => meeting.meeting_type_id === "MT-11"
            );
            setMeetings(filteredMeetings);
        } catch (error) {
            console.error("Error fetching meetings:", error);
        } finally {
            setRefreshing(false);
        }
    };

    const getUsers = async () => {
        try {
            const data = await client.getUsers(user.warehouse_id);
            // const filteredUsers = data.results.filter((user) =>
            //     userIdsToCheck.includes(user.user_id)
            // );
            setUsers(data.results); // Set the fetched user data
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setRefreshing(false);
        }
    };

    useEffect(() => {
        getMeetings();
        getUsers();
    }, [user]);

    const [refreshing, setRefreshing] = useState(true);

    // Check if a user has a meeting on a given date
    const hasMeetingOnDate = (userId, date) => {
        return meetings.some((meeting) => {
            const meetingDate = meeting.start_date
                ? new Date(meeting.start_date)
                : null;
            const formattedDate = meetingDate
                ? meetingDate.toISOString().split("T")[0]
                : null;

            return (
                meeting.attendees?.some(
                    (attendee) => String(attendee) === String(userId)
                ) && formattedDate === date
            );
        });
    };

    // const checkIfMeetingOnDate = (userId, date) => {
    //     return meetings.some((meeting) => {
    //         const meetingDate = meeting.start_date
    //             ? new Date(meeting.start_date)
    //             : null;
    //         const formattedDate = meetingDate
    //             ? meetingDate.toISOString().split("T")[0]
    //             : null;

    //         // Debug logs
    //         console.log("Checking meeting for userId:", userId);
    //         console.log("Meeting attendees:", meeting.attendees);
    //         console.log("Formatted date from meeting:", formattedDate);
    //         console.log("Date being checked:", date);

    //         const attendeeMatch = meeting.attendees?.some(
    //             (attendee) => attendee.id === userId
    //         );
    //         console.log("Attendee match result:", attendeeMatch);

    //         const dateMatch = formattedDate === date;
    //         console.log("Date match result:", dateMatch);

    //         return attendeeMatch && dateMatch;
    //     });
    // };

    const initialStartDate = formatModalDate(now); // Current date and time
    const initialEndDate = formatModalDate(
        new Date(now.getTime() + 30 * 60000)
    ); // Current date and time plus 30 minutes

    const [newEventData, setNewEventData] = useState({
        title: "",
        start: initialStartDate,
        end: initialEndDate,
        attendees: [],
    });

    const [isModalOpen, setIsModalOpen] = useState(false); // State for the modal visibility

    // const openModal = () => {
    //     setNewEventData({
    //         ...newEventData,
    //         start: initialStartDate,
    //         end: initialEndDate,
    //     });
    //     setOpenModal(true); // This navigates back one step in the history
    // };

    const handleOpenModal = () => {
        setNewEventData({
            ...newEventData,
            start: initialStartDate,
            end: initialEndDate,
        });
        setIsModalOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
    };

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    return (
        <div className="w-full mt-20 items-center flex flex-col justify-center">
            <div className="mb-4">
                {/* Button to open the create meeting modal */}
                <button
                    onClick={handleOpenModal}
                    className="px-4 py-2 text-white rounded-full bg-black"
                >
                    + Ajouter rendez vous
                </button>
                {isModalOpen && (
                    <MeetingModal
                        initialEventData={newEventData}
                        closeModal={handleCloseModal}
                        locale="fr"
                        setSuccessMessage={setSuccessMessage}
                        setErrorMessage={setErrorMessage}
                        user_id={user.user_id}
                        // initialEventData={newEventData}
                        isEditing={false}
                        user={user}
                        is_client_meeting={false}
                    />
                )}
            </div>
            <table border="1" className="w-3/4">
                <thead className="text-xs">
                    <tr className="text-xs">
                        <th
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                        ></th>
                        <th
                            className="text-left text-sm"
                            style={{ paddingTop: "8px", paddingBottom: "8px" }}
                        >
                            {camelCaseMonth}
                        </th>
                        <th>
                            <button
                                onClick={() => changeMonth(-1)}
                                className="px-3 py-1 rounded"
                            >
                                <FiChevronLeft />
                            </button>
                        </th>
                        {dates.map((date, index) => {
                            const dayDate = new Date(date);
                            const isToday = date === today;

                            return (
                                <th
                                    key={index}
                                    style={{
                                        color: isToday
                                            ? "red"
                                            : isWeekend(dayDate)
                                            ? "#989898"
                                            : "black",
                                        borderLeft: isToday
                                            ? "1px solid #FF004F"
                                            : "none",
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                        paddingTop: "8px",
                                        paddingBottom: "8px",
                                    }}
                                >
                                    <div style={{ textAlign: "center" }}>
                                        <span
                                            className="text-xxs"
                                            style={{
                                                ...(isToday
                                                    ? { color: "#FF004F" }
                                                    : {}),
                                                display: "block",
                                            }}
                                        >
                                            {getFrenchDayAbbreviation(dayDate)}
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "1rem",
                                                marginTop: "6px",
                                                display: "block",
                                            }}
                                        >
                                            {dayDate.getDate()}
                                        </span>
                                    </div>
                                </th>
                            );
                        })}
                        <th>
                            <button
                                onClick={() => changeMonth(1)}
                                className="px-3 py-1 rounded"
                            >
                                <FiChevronRight />
                            </button>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {users.map((user) => (
                        <tr
                            key={user.id}
                            className="border-b border-gray-100"
                            style={{ minHeight: "50px", height: "50px" }}
                        >
                            <td>
                                <div
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                        backgroundColor: "#000000",
                                        color: "white",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "10px",
                                    }}
                                >
                                    {getInitials(
                                        user.first_name + " " + user.last_name
                                    )}
                                </div>
                            </td>

                            <td className="text-left">
                                {user.first_name} {user.last_name}
                            </td>
                            <td></td>
                            {dates.map((date, index) => {
                                const dayDate = new Date(date);
                                const isToday = date === today;

                                // Check if this user has a meeting on this date
                                const isMeetingDay = hasMeetingOnDate(
                                    user.user_id,
                                    date
                                );

                                // const checkDay = checkIfMeetingOnDate(
                                //     user.user_id,
                                //     date
                                // );

                                // console.log(checkDay);

                                return (
                                    <td
                                        key={index}
                                        style={{
                                            color: isWeekend(dayDate)
                                                ? "#D1D5DB"
                                                : "black",
                                            backgroundColor: isWeekend(dayDate)
                                                ? "#F8F8F8"
                                                : "white",
                                            borderLeft: isToday
                                                ? "1px solid #FF004F"
                                                : "none",
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        {isMeetingDay && (
                                            <div
                                                style={{
                                                    width: "10px", // Outer black circle size
                                                    height: "10px",
                                                    backgroundColor: "black", // Outer black circle color
                                                    borderRadius: "50%", // Makes it a circle
                                                    display: "inline-block", // Aligns it in the center of the cell
                                                    position: "relative", // Allows inner circle positioning
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "4px", // Inner white circle size (adjusted for centering)
                                                        height: "4px",
                                                        backgroundColor:
                                                            "white", // White inner circle
                                                        borderRadius: "50%", // Makes the inner circle
                                                        position: "absolute",
                                                        top: "50%", // Vertically centers it
                                                        left: "50%", // Horizontally centers it
                                                        transform:
                                                            "translate(-50%, -50%)", // Ensures perfect centering
                                                    }}
                                                ></div>
                                            </div>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SalesReps;
