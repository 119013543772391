import React from "react";

const KeyValueList = ({ field_id, handleInputChange, responses }) => {
    // Initialize the distributors and categories from responses, or use an empty list
    const distributors = responses[field_id] || [
        { distributor: "", category: "" },
    ];

    // Add a new distributor and category pair
    const handleAddDistributor = () => {
        const updatedDistributors = [
            ...distributors,
            { distributor: "", category: "" },
        ];
        handleInputChange(field_id, updatedDistributors); // Update responses with the new list
    };

    // Handle changes in distributor or category
    const handleDistributorChange = (index, field, value) => {
        const updatedDistributors = [...distributors];
        updatedDistributors[index][field] = value;
        handleInputChange(field_id, updatedDistributors); // Update responses with the new list
    };

    return (
        <div className="w-full flex flex-row">
            <div className="w-0 md:w-1/2"></div>
            <div className="w-full md:w-1/2">
                {distributors.map((item, index) => (
                    <div
                        key={index}
                        className="distributor-category-row flex flex-col md:flex-row mt-3 w-full gap-5"
                    >
                        <div className="flex flex-row">
                            <input
                                type="text"
                                placeholder="Distributor Name"
                                value={item.distributor}
                                onChange={(e) =>
                                    handleDistributorChange(
                                        index,
                                        "Distributeur",
                                        e.target.value
                                    )
                                }
                                className="flex-1 w-1/3 input border border-gray-200 rounded-md p-2"
                            />
                            <input
                                type="text"
                                placeholder="Product Category"
                                value={item.category}
                                onChange={(e) =>
                                    handleDistributorChange(
                                        index,
                                        "Categorie",
                                        e.target.value
                                    )
                                }
                                className="flex-1 w-1/3 input border border-gray-200 rounded-md p-2 ml-5"
                            />
                        </div>
                        {/* Remove button for each row */}
                        <button
                            type="button"
                            onClick={() => {
                                const updatedDistributors = distributors.filter(
                                    (_, i) => i !== index
                                );
                                handleInputChange(
                                    field_id,
                                    updatedDistributors
                                ); // Update responses with the new list
                            }}
                            className="flex-1 md:w-1/4 border border-black rounded-full p-2 ml-5"
                        >
                            - Supprimer
                        </button>
                    </div>
                ))}

                {/* Add New Distributor and Category Row */}
                <button
                    type="button"
                    onClick={handleAddDistributor}
                    className="add-btn border border-black rounded-full w-full p-2 mt-5"
                >
                    + Ajouter
                </button>
            </div>
        </div>
    );
};

export default KeyValueList;