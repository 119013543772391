import React, { useState, useMemo } from "react";
import ReportPopUp from "../../ModalComponents/ReportPopUp";
import { FiExternalLink } from "react-icons/fi";

const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
    return `${day}/${month}/${year}`;
};

const VisitHistory = ({ visithistory, reportLayout }) => {
    // Get today's date in the YYYY-MM-DD format
    const today = new Date();
    today.setHours(23, 59, 0, 0); // Set the time to 23:59 to compare only dates

    // const pastVisits = visithistory
    //     ? visithistory.filter((visit) => {
    //           const visitDate = new Date(visit.start_date);
    //           console.log(today);
    //           return visitDate <= today;
    //       })
    //     : [];

    // Use useMemo to make visithistory responsive：New meetings were added in response to being offline
    const pastVisits = useMemo(() => {
        if (!visithistory) return [];
        return visithistory
            .filter((visit) => {
                const visitDate = new Date(visit.start_date);
                return visitDate <= today && visit.is_visited === true;
            })
            .sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
    }, [visithistory]);

    const [selectedReport, setSelectedReport] = useState(null); // Track selected report data

    const togglePopup = (reportData) => {
        if (reportData) {
            setSelectedReport(reportData); // Set the selected report data
        } else {
            setSelectedReport(null); // Clear the selected report data
        }
    };
    console.log(pastVisits);

    return (
        <div className="pt-10 w-11/12">
            {pastVisits.map((visit, index) => (
                <div
                    key={visit.meeting_id || index}
                    className="list-wrapper mb-10 text-xs"
                >
                    <div className="flex flex-row justify-between items-center border-b border-gray-200 pt-2 pb-2 text-left">
                        <div className="w-1/5 text-sm font-semibold">
                            {formatDate(visit.start_date)}
                        </div>
                        <div className="w-2/5 text-sm font-semibold">
                            {visit.user?.first_name} {visit.user?.last_name}
                        </div>
                        <div className="w-2/5 text-sm font-semibold flex items-center">
                            {visit.type && visit.type.description && (
                                <>
                                    {visit.type.description}
                                    <button
                                        onClick={() =>
                                            togglePopup(visit.report)
                                        } // Pass report data
                                        className="p-1 text-black text-xs rounded"
                                    >
                                        <FiExternalLink />
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            ))}
            {selectedReport && (
                <ReportPopUp
                    togglePopup={() => togglePopup(null)} // Close popup when toggled
                    reportData={selectedReport} // Pass the selected report data
                    reportLayout={reportLayout}
                />
            )}
        </div>
    );
};

export default VisitHistory;
