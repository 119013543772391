// Modal.js
import React, { useState } from "react";
import {
    FiXCircle,
    FiShoppingBag,
    FiChevronUp,
    FiChevronDown,
} from "react-icons/fi";

const renderProductDetails = (product) => {
    if (!product) return null;

    const renderTwoColumns = (details) => (
        <div className="grid gap-4 text-sm grid-cols-2">
            {details.map((detail, index) => (
                <div key={index} className="flex flex-col">
                    <strong>{detail.label}</strong>
                    <span>{detail.value}</span>
                </div>
            ))}
        </div>
    );

    switch (product.product_level_1) {
        case "BIERES":
            return renderTwoColumns([
                { label: "Famille de bière :", value: product.beer_family },
                { label: "Couleur de bière :", value: product.beer_color },
                { label: "Style de bière :", value: product.beer_style },
                { label: "Région de bière :", value: product.beer_region },
                { label: "Type de mousse :", value: product.beer_head_type },
                { label: "Saveur :", value: product.beer_flavor },
                { label: "Amertume :", value: product.beer_bitterness },
                { label: "Sans gluten :", value: product.gluten_free_beer },
            ]);

        case "VINS":
            return renderTwoColumns([
                { label: "Couleur du vin :", value: product.wine_color },
                { label: "Vignoble :", value: product.wine_vineyard },
                { label: "Dénomination :", value: product.wine_denomination },
                { label: "Appellation :", value: product.wine_appellation },
                { label: "Millésime :", value: product.wine_millesime },
                {
                    label: "Teneur en sucre :",
                    value: product.wine_sugar_content,
                },
                { label: "Cépage :", value: product.wine_grape },
            ]);

        case "SPIRITUEUX":
            return renderTwoColumns([
                {
                    label: "Famille de spiritueux :",
                    value: product.spirit_family,
                },
                { label: "Type de spiritueux :", value: product.spirit_type },
                { label: "Millésime :", value: product.spirit_millesime },
                { label: "Âge :", value: product.spirit_age },
                { label: "Région :", value: product.spirit_region },
            ]);

        case "EAUX":
            return renderTwoColumns([
                { label: "Famille d'eau :", value: product.water_family },
            ]);

        case "SOFT DRINKS":
            return renderTwoColumns([
                { label: "Famille de boissons :", value: product.soft_family },
            ]);

        case "JUS DE FRUITS":
            return renderTwoColumns([
                { label: "Famille de jus :", value: product.juice_family },
            ]);

        case "THES ET INFUSIONS":
            return renderTwoColumns([
                { label: "Type de thé :", value: product.tea_type },
                { label: "Couleur du thé :", value: product.tea_color },
                { label: "Saveur de thé :", value: product.tea_flavor },
                { label: "Label :", value: product.tea_label },
            ]);

        case "CAFES":
            return renderTwoColumns([
                { label: "Type de café :", value: product.coffee_type },
                { label: "Décaféiné :", value: product.decaf_coffee },
                { label: "Type de dosette :", value: product.coffee_pod_type },
                {
                    label: "Région de torréfaction :",
                    value: product.coffee_roasting_region,
                },
                {
                    label: "Type de torréfaction :",
                    value: product.coffee_roast,
                },
                { label: "Variété de café :", value: product.coffee_variety },
                { label: "Ingrédients :", value: product.coffee_ingredients },
                { label: "Intensité :", value: product.coffee_intensity },
                { label: "Label :", value: product.coffee_label },
            ]);

        default:
            return <p>Aucune information disponible.</p>;
    }
};

const ProductModal = ({ showModal, setShowModal, product, setProduct }) => {
    if (!showModal) return null;

    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [isInfoOpen, setIsInfoOpen] = useState(false);

    const handleShowModal = () => {
        setShowModal(false);
        setProduct(null);
    };

    const stripHtmlTags = (text) => {
        if (!text) return "";
        return text.replace(/<\/?[^>]+(>|$)/g, ""); // Regex to remove HTML tags
    };

    const warehouseMap = {
        1: "Villenave",
        2: "Marsac",
        3: "Jarnac",
        5: "La Teste",
        2801: "Redon",
        8901: "Rennes",
    };

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            onClick={handleShowModal}
        >
            <div
                className="bg-white md:p-4 md:rounded-lg md:w-1/2 w-full h-full md:h-auto overflow-y-auto"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex flex-col md:flex-row w-full items-center">
                    <div className="w-full md:w-2/6 hidden md:block">
                        <img
                            className="w-full object-scale-down mb-4"
                            src={product?.image}
                            alt={product?.product_name}
                        />
                    </div>
                    <div className="w-5/6 flex flex-col">
                        <div className="flex justify-end mt-10">
                            {/* Close Button */}
                            <button
                                className="text-gray-500 hover:text-gray-700 text-xxl"
                                onClick={() => setShowModal(false)}
                            >
                                <FiXCircle />
                            </button>
                        </div>

                        {/* Image (Only visible on md and above) */}
                        <div className="flex block md:hidden w-full md:w-2/6 mb-4">
                            <img
                                className="w-full object-scale-down"
                                src={product?.image}
                                alt={product?.product_name}
                            />
                        </div>

                        <div className="flex flex-row items-center mb-2">
                            <p className="text-xl">{product?.product_name}</p>
                            {product?.promotion && (
                                <div className="bg-rose-500 rounded-full text-white px-2 py-1 text-xxs ml-3">
                                    Promotion %
                                </div>
                            )}
                        </div>

                        <p className="mb-2">
                            <strong>Réf:</strong> {product?.product_id}
                        </p>
                        <p className="mb-2">
                            <strong>Description:</strong>{" "}
                            {product?.product_desc
                                ? stripHtmlTags(product.product_desc)
                                : ""}
                        </p>
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={() => setIsDetailsOpen(!isDetailsOpen)}
                        >
                            <p className="">
                                <strong>Détails du produit</strong>
                            </p>
                            {isDetailsOpen ? (
                                <FiChevronUp />
                            ) : (
                                <FiChevronDown />
                            )}
                        </div>
                        {isDetailsOpen && renderProductDetails(product)}
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={() => setIsInfoOpen(!isInfoOpen)}
                        >
                            <p className="mb-2 mt-2">
                                <strong>Informations generales</strong>
                            </p>
                            {isInfoOpen ? <FiChevronUp /> : <FiChevronDown />}
                        </div>
                        {isInfoOpen && (
                            <div className="grid grid-cols-2 gap-4 text-sm">
                                <div>
                                    <p className="mb-2">
                                        <strong>Pays :</strong>{" "}
                                        {product?.country}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Format :</strong>{" "}
                                        {product?.container_format}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Consignation :</strong>{" "}
                                        {product?.deposit}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Contenance :</strong>{" "}
                                        {product?.capacity}{" "}
                                        {product?.capacity_unit}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Unité de vente :</strong>{" "}
                                        {product?.sales_unit_count}
                                    </p>
                                </div>
                                <div>
                                    <p className="mb-2">
                                        <strong>Teneur en alcool :</strong>{" "}
                                        {product?.degree}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Producteur :</strong>{" "}
                                        {product?.producer}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Marque :</strong>{" "}
                                        {product?.manufacturer_brand}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Biologique :</strong>{" "}
                                        {product?.organic}
                                    </p>
                                    <p className="mb-2">
                                        <strong>EAN :</strong> {product?.ean}
                                    </p>
                                </div>
                            </div>
                        )}

                        <div className="flex flex-row w-full mt-2">
                            <div className="w-1/4">
                                <p className="mb">
                                    <strong>Prix:</strong>
                                </p>
                            </div>
                            <div className="font-semibold">
                                {product?.prices.price_1
                                    ? product.prices.price_1.toFixed(2)
                                    : "0.00"}{" "}
                                € (HT)
                            </div>
                        </div>

                        <div className="flex flex-row w-full mt-2 mb-5">
                            <div className="w-1/4">
                                <p className="mb">
                                    <strong>Stock:</strong>
                                </p>
                            </div>
                            <div className="w-3/4">
                                <ul className="w-full text-xs">
                                    {/* Header Row */}
                                    <li className="w-full flex items-center text-sm font-bold border-b border-gray-300">
                                        <div className="w-1/2">Dépôt</div>
                                        <div className="w-1/2">Quantité</div>
                                    </li>

                                    {/* Data Rows */}
                                    {product?.stock.map((item, index) => (
                                        <li
                                            key={index}
                                            className="w-full flex items-center py-1"
                                        >
                                            <div className="w-1/2">
                                                {warehouseMap[
                                                    item.warehouse_id
                                                ] || item.warehouse_id}
                                            </div>
                                            <div className="w-1/2">
                                                {item.quantity}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="flex md:justify-end mb-5">
                            <button
                                className="flex flex-row items-center ml-10 bg-black text-white justify-center rounded-full px-2 py-1 text-base md:w-1/4 w-4/5"
                                onClick={() =>
                                    window.open(product?.shop_url, "_blank")
                                }
                            >
                                <FiShoppingBag />{" "}
                                <span className="ml-2 text-base">Acheter</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;
