/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid"; // Import UUID generator
import { useNetwork } from "../../NetworkProvider/index.jsx";

import FastAPIClient from "../../../client.js";
import config from "../../../config.js";
const client = new FastAPIClient(config);
import RecurringEventPopup from "./utils.jsx";

import { FormattedMessage, IntlProvider } from "react-intl";

import MeetingClientSearch from "../../Search/MeetingClientSearch.jsx";
import { FiCalendar } from "react-icons/fi";
import Throbber from "../../../style/Throbber";
import RedStar from "./styleElements";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import {
    CACHE_RESPONSE_API,
    CACHE_RESPONSE_DATA,
} from "../../../sw/utils/constants.js";
import { getDataFromStore } from "../../../sw/utils/db.js";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// Function to add minutes to a Date object
const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
};

const MeetingModal = ({
    closeModal,
    initialEventData = {},
    locale,
    onCreateMeeting,
    onUpdateMeeting,
    user_id,
    isEditing,
    client_id = null,
    user,
    meetings,
    setSuccessMessage,
    setErrorMessage,
    is_client_meeting,
}) => {
    console.log("Initial event data", initialEventData);

    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [eventData, setEventData] = useState({
        meeting_id: uuidv4(),
        start_date: initialEventData.start
            ? formatDate(initialEventData.start)
            : null,
        end_date: initialEventData.end
            ? isEditing
                ? formatDate(initialEventData.end)
                : formatDate(addMinutes(new Date(initialEventData.start), 30))
            : null,
        user: user,
        user_id: user.user_id, // Replace with the user's ID if applicable
        meeting_type_id: initialEventData.meeting_type_id
            ? initialEventData.meeting_type_id
            : "", // by default, s
        is_visited: false, // Set to `true` if needed for your logic
        client_id: null, // Client ID; replace as per your application's data
        attendees: initialEventData.attendees || [], // Ensure this defaults to an empty array
        created_at: initialEventData.created_at
            ? formatDate(new Date(initialEventData.created_at))
            : formatDate(new Date()), // Default to current system time
        updated_at: initialEventData.updated_at
            ? formatDate(new Date(initialEventData.updated_at))
            : formatDate(new Date()), // Default to current system time
        client_alert: initialEventData.client_alert
            ? initialEventData.client_alert
            : false,
        is_recurring: initialEventData.is_recurring, // New field to indicate if the meeting is recurrent
        recurrence_type: "WEEKLY", // Default recurrence type (could be DAILY, WEEKLY, MONTHLY)
        recurrence_interval: 1, // Default interval
        recurrence_end_date: null, // End date for recurrence, optional
        recurring_id: initialEventData.recurring_id,
    });

    const generateRecurrences = (meeting, recurrence) => {
        console.log("Generating recurrences...");
        console.log("Meeting:", meeting);
        console.log("Recurrence:", recurrence);

        const recurrences = [];
        const startDate = new Date(meeting.start_date);
        const endDate = new Date(meeting.end_date);
        const recurrenceEndDate = new Date(recurrence.recurrence_end_date);

        // Calculate the full duration of the meeting in milliseconds
        const duration = endDate - startDate;

        let nextStartDate = new Date(startDate);

        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
        console.log("Recurrence End Date:", recurrenceEndDate);
        console.log("Meeting Duration (ms):", duration);

        while (nextStartDate <= recurrenceEndDate) {
            // Set end date correctly by adding full duration
            const nextEndDate = new Date(nextStartDate.getTime() + duration);

            // Create a new meeting object without recurrence_interval and recurrence_type
            const {
                recurrence_interval,
                recurrence_type,
                recurrence_end_date,
                client_alert,
                client_name,
                ...cleanedMeeting
            } = meeting;

            recurrences.push({
                ...cleanedMeeting,
                client_name: meeting.client_name,
                meeting_id: uuidv4(), // Assign a unique UUID for each recurrence
                start_date: nextStartDate.toISOString(),
                end_date: nextEndDate.toISOString(),
                is_visited: false, // Reset visited status
                is_recurring: true, // Reset recurrence flag for generated meetings
                recurring_id: meeting.meeting_id, // Reference the original meeting
            });

            console.log(
                "Next Recurrence:",
                recurrences[recurrences.length - 1]
            );

            // Move to the next occurrence using a fresh Date object
            switch (recurrence.recurrence_type) {
                case "DAILY":
                    nextStartDate = new Date(nextStartDate);
                    nextStartDate.setDate(
                        nextStartDate.getDate() + recurrence.recurrence_interval
                    );
                    break;
                case "WEEKLY":
                    nextStartDate = new Date(nextStartDate);
                    nextStartDate.setDate(
                        nextStartDate.getDate() +
                            7 * recurrence.recurrence_interval
                    );
                    break;
                case "MONTHLY":
                    nextStartDate = new Date(nextStartDate);
                    nextStartDate.setMonth(
                        nextStartDate.getMonth() +
                            recurrence.recurrence_interval
                    );
                    break;
                case "YEARLY":
                    nextStartDate = new Date(nextStartDate);
                    nextStartDate.setFullYear(
                        nextStartDate.getFullYear() +
                            recurrence.recurrence_interval
                    );
                    break;
                default:
                    return recurrences;
            }
        }

        console.log("Final Recurrences:", recurrences);
        return recurrences;
    };

    const [clients, setClients] = useState([]);
    const [meetingTypes, setMeetingTypes] = useState([]);
    const [suggestions, setSuggestions] = useState([]); // Suggestions list
    const [selectedClient, setSelectedClient] = useState(
        initialEventData.title || ""
    ); // Initialize with title
    const [attendees, setAttendees] = useState([]);
    const { isOnline } = useNetwork();

    const handleChange = (e) => {
        const { name, value } = e.target;
        const stateKey =
            name === "start"
                ? "start_date"
                : name === "end"
                ? "end_date"
                : name;
        setEventData((prevData) => ({ ...prevData, [stateKey]: value }));
    };

    const changeIsLoading = () => {
        setIsLoading(false);
    };

    useEffect(() => {
        if (user_id) {
            fetchUserClientsOnline();
            fetchMeetingTypes();
            fetchAttendees();
        }
        changeIsLoading();
    }, [user_id]);

    const fetchUserClientsOnline = () => {
        if (user_id != null && isOnline) {
            client.getUserClientsOnline(user_id, null).then((data) => {
                const sortedResults = data.sort((a, b) => {
                    // Move objects with 'alert' as true to the beginning
                    return b.alert - a.alert; // b.alert - a.alert will move true values to the top
                });
                setClients(sortedResults);
            });
        } else if (!isOnline) {
            fetchUserClientsOffline();
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };

    const fetchUserClientsOffline = () => {
        if (user_id != null) {
            client.getUserClients(user_id, null).then((data) => {
                const sortedResults = data.sort((a, b) => {
                    // Move objects with 'alert' as true to the beginning
                    return b.alert - a.alert; // b.alert - a.alert will move true values to the top
                });
                setClients(sortedResults);
            });
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const getAllOfflineClients = () => {
        const storeName =
            CACHE_RESPONSE_DATA[CACHE_RESPONSE_API.CLIENTS].storeName;
        const url = `${config.apiBasePath}${CACHE_RESPONSE_API.CLIENTS}?user_id=${user_id}`;
        getDataFromStore(storeName, url)
            .then((result) => {
                if (result?.data?.length) {
                    handleAllClients(result?.data);
                } else {
                    console.log("No data found for the given URL.");
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    // const fetchUserClients = (user_id, client_id) => {
    //     if (isOnline) {
    //         client.getUserClientsonline(user_id, client_id).then((data) => {
    //             handleAllClients(data);
    //         });
    //     } else {
    //         getAllOfflineClients();
    //     }
    // };

    // const handleAllClients = (data) => {
    //     if (!data?.length) {
    //         setClients([]);
    //     } else {
    //         const sortedResults = data.sort((a, b) => {
    //             // Move objects with 'alert' as true to the beginning
    //             return b.alert - a.alert; // b.alert - a.alert will move true values to the top
    //         });
    //         setClients(sortedResults);
    //     }
    // };
    const fetchMeetingTypes = () => {
        client.getMeetingTypes().then((data) => {
            setMeetingTypes(data?.data.results || []);
        });
    };
    const fetchAttendees = () => {
        client.getUsers().then((data) => {
            if (isEditing) {
                // Don't filter out existing attendees, just filter out the current user
                const meetingAttendees = data?.results.filter(
                    (user) =>
                        user.user_id !== user_id && user.is_active === true
                );
                console.log(meetingAttendees);
                setAttendees(meetingAttendees || []);
            } else {
                // Filter out the current user before setting the attendees
                const filteredAttendees = data?.results.filter(
                    (user) =>
                        user.user_id !== user_id && user.is_active === true
                );
                console.log(filteredAttendees);
                setAttendees(filteredAttendees || []);
            }
        });
    };

    // Handle recurrence options change
    const handleRecurrenceChange = (e) => {
        const { name, value, checked } = e.target;

        if (name === "is_recurring") {
            // If the "is_recurring" checkbox is toggled off, reset recurrence-related fields
            if (!checked) {
                setEventData((prevData) => ({
                    ...prevData,
                    is_recurring: false, // Set recurrence to false
                    recurrence_type: "WEEKLY", // Reset to default type
                    recurrence_interval: 1, // Reset to default interval (1)
                    recurrence_end_date: null, // Reset to no end date
                }));
            } else {
                // If checked, set "is_recurring" to true but leave other recurrence fields intact
                setEventData((prevData) => ({
                    ...prevData,
                    is_recurring: true,
                }));
            }
        } else {
            // Handle regular field changes (not "is_recurring" checkbox)
            setEventData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const popupResolverRef = useRef(null); // Use ref to store the resolver functions

    // Function to open the popup and return a promise
    const openPopup = () => {
        return new Promise((resolve, reject) => {
            popupResolverRef.current = { resolve, reject }; // Store resolver functions in ref
            setIsPopupOpen(true); // Open the popup
        });
    };

    // Called when user clicks OK in the popup
    const handleConfirm = (selectedOption) => {
        if (popupResolverRef.current) {
            popupResolverRef.current.resolve(selectedOption); // Resolve the promise
            popupResolverRef.current = null; // Clear the resolver
            setIsPopupOpen(false); // Close the popup
        }
    };

    // Called when user clicks Cancel in the popup
    const handleCancel = () => {
        console.log("Cancelling...");
        if (popupResolverRef.current) {
            popupResolverRef.current.reject("Cancelled"); // Reject the promise
            popupResolverRef.current = null; // Clear the resolver
            setIsPopupOpen(false); // Close the popup
        }
    };

    useEffect(() => {
        console.log("Popup state changed:", isPopupOpen);
    }, [isPopupOpen]);

    const handleSave = async () => {
        setIsSubmitting(true);
        let offline_params = {};
        if (!isOnline) {
            const description = meetingTypes.find(
                (item) => item.meeting_type_id === eventData.meeting_type_id
            )?.description;
            offline_params = {
                user: {
                    user_id: user?.user_id,
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                },
                type: {
                    description: description,
                },
                client: {
                    alert: false,
                    client_id: isEditing
                        ? initialEventData.client_id
                        : eventData.client_id,
                    lat: 0,
                    long: 0,
                    client_name: isEditing
                        ? initialEventData.client_name
                        : eventData.client_name,
                },
            };
        }
        console.log("offline params", offline_params);
        let payloads = [];

        if (eventData.is_recurring && !isEditing) {
            // Generate multiple payloads for recurring meetings
            console.log("recurrent");
            payloads = generateRecurrences(eventData, eventData).map(
                (recurrence) => ({
                    ...recurrence,
                    attendees: [user.user_id, ...eventData.attendees],
                    ...offline_params,
                })
            );
        } else {
            console.log(eventData);
            payloads = [
                {
                    meeting_id: isEditing
                        ? initialEventData.meeting_id
                        : eventData.meeting_id,
                    user: eventData.user,
                    user_id: eventData.user_id,
                    meeting_type_id: eventData.meeting_type_id,
                    client_name: eventData.client_name,
                    // start_date: "2025-01-10T12:00",
                    // end_date: "2025-01-10T12:30",
                    start_date: eventData.start_date,
                    end_date: eventData.end_date,
                    is_visited: eventData.is_visited,
                    client_id: isEditing
                        ? initialEventData.client_id
                        : eventData.client_id,
                    created_at: isEditing
                        ? initialEventData.created_at
                        : eventData.created_at,
                    updated_at: eventData.updated_at,
                    attendees: [user.user_id, ...eventData.attendees],
                    // client_alert: eventData.client_alert,
                    is_recurring: eventData.is_recurring || false,
                    recurring_id: eventData.recurring_id || "", // here is a problem
                    description: eventData.description,
                    ...offline_params,
                },
            ];
        }
        try {
            if (isEditing) {
                if (
                    eventData.is_recurring === false ||
                    eventData.is_recurring === undefined
                ) {
                    console.log("payload", payloads[0]);
                    payloads[0].update_recurring = false;
                    const selectedKeys = [
                        "meeting_id",
                        "start_date",
                        "end_date",
                        "updated_at",
                        "update_recurring",
                        "meeting_type_id",
                        "attendees",
                        "client",
                    ];

                    // Filter only the properties from payloads[0] that are in selectedKeys
                    const filteredPayload = selectedKeys.reduce((acc, key) => {
                        if (key in payloads[0]) {
                            acc[key] = payloads[0][key];
                        }
                        return acc;
                    }, {});

                    console.log("filtered payload", filteredPayload);

                    await client.updateMeeting(
                        initialEventData.meeting_id,
                        filteredPayload
                    );
                    setIsSubmitting(false);

                    setSuccess("Réunion mise à jour avec succès !");
                    console.log(filteredPayload, "filteredPayload");
                    console.log(initialEventData.title);
                    console.log(initialEventData.client_id);
                    console.log(initialEventData.client_alert);
                    // console.log(initialEventData.title)
                    // console.log(initialEventData.title)
                    if (onUpdateMeeting) {
                        onUpdateMeeting({
                            ...filteredPayload,
                            client_name: initialEventData.title, // Add client name
                            title: initialEventData.title, // Add title
                            client: {
                                client_name: initialEventData.title,
                                client_id: initialEventData.client_id,
                                alert: initialEventData.client_alert,
                            },
                        });
                    }
                } else {
                    // If it is recurring, ask if they want to update this instance or the whole series
                    const selectedOption = await openPopup(); // Wait for user selection
                    // console.log("User selected:", selectedOption);
                    try {
                        if (selectedOption === "this-event") {
                            // console.log(
                            //     "Proceeding with single event update..."
                            // );
                            // Your logic for updating a single event
                            // Update only this single instance
                            // console.log("Updating this instance only");
                            const selectedKeys = [
                                "meeting_id",
                                "start_date",
                                "end_date",
                                "updated_at",
                                "update_recurring",
                                "attendees",
                                "client_id",
                                "user_id",
                                "meeting_type_id",
                                "is_visited",
                                "created_at",
                            ];
                            payloads[0].update_recurring = false;
                            const filteredPayload = selectedKeys.reduce(
                                (acc, key) => {
                                    if (key in payloads[0]) {
                                        acc[key] = payloads[0][key];
                                    }
                                    return acc;
                                },
                                {}
                            );

                            await client.updateMeeting(
                                initialEventData.meeting_id,
                                filteredPayload
                            );

                            setSuccess("Réunion mise à jour avec succès !");

                            if (onUpdateMeeting) {
                                onUpdateMeeting({
                                    ...filteredPayload,
                                    client_name: initialEventData.title, // Add client name
                                    title: initialEventData.title, // Add title
                                    client: {
                                        client_name: initialEventData.title,
                                        client_id: initialEventData.client_id,
                                        alert: initialEventData.client_alert,
                                    },
                                });
                            }
                        } else if (selectedOption === "this-and-following") {
                            console.log(
                                "Proceeding with recurring event update..."
                            );
                            // Your logic for updating recurring events

                            const selectedKeys = [
                                "meeting_id",
                                "start_date",
                                "end_date",
                                "updated_at",
                                "update_recurring",
                                "attendees",
                                "client_id",
                                "user_id",
                                "meeting_type_id",
                                "is_visited",
                                "created_at",
                            ];
                            // Update the whole series
                            console.log("Updating the whole series");
                            console.log("Meetings", meetings);
                            console.log("Payload recurring_id", payloads[0]);
                            // 1. Create the payloads
                            // 1. Create the payloads (with a different variable name to avoid conflict)
                            const newPayloads = meetings
                                .filter(
                                    (meeting) =>
                                        meeting.recurring_id ===
                                        payloads[0]?.recurring_id
                                ) // Filter by recurring_id from a safe initial payload
                                .map((meeting) => {
                                    const originalStartDate = new Date(
                                        meeting.start_date
                                    );
                                    const originalEndDate = new Date(
                                        meeting.end_date
                                    );
                                    const newStartTime = new Date(
                                        payloads[0]?.start_date
                                    ); // Assuming payloads[0] is available here
                                    const newEndTime = new Date(
                                        payloads[0]?.end_date
                                    );

                                    if (
                                        isNaN(originalStartDate.getTime()) ||
                                        isNaN(originalEndDate.getTime())
                                    ) {
                                        console.error(
                                            "Skipping meeting due to invalid date:",
                                            meeting
                                        );
                                        return null; // Skip invalid meeting
                                    }

                                    // Set the new time while preserving the date
                                    originalStartDate.setHours(
                                        newStartTime.getHours(),
                                        newStartTime.getMinutes(),
                                        newStartTime.getSeconds()
                                    );
                                    originalEndDate.setHours(
                                        newEndTime.getHours(),
                                        newEndTime.getMinutes(),
                                        newEndTime.getSeconds()
                                    );

                                    // Prepare the payload
                                    return {
                                        meeting_id: meeting.meeting_id,
                                        start_date:
                                            originalStartDate.toISOString(),
                                        end_date: originalEndDate.toISOString(),
                                        updated_at: new Date().toISOString(),
                                        update_recurring: true, // Indicate this update is for the recurring series
                                    };
                                })
                                .filter((payload) => payload !== null); // Remove any invalid payloads

                            console.log("Generated Payloads:", newPayloads);

                            // 2. Create meetings in the database (use the newPayloads)
                            for (const payload of newPayloads) {
                                try {
                                    console.log(
                                        "Creating meeting with payload:",
                                        payload
                                    );
                                    await client.createMeeting(payload); // Assuming there's a method to create the meeting
                                    console.log(
                                        "Meeting created:",
                                        payload.meeting_id
                                    );
                                } catch (error) {
                                    console.error(
                                        "Error creating meeting:",
                                        payload.meeting_id,
                                        error
                                    );
                                }
                            }

                            // 3. Update meetings and call `onUpdateMeeting` (use the newPayloads)
                            for (const payload of newPayloads) {
                                try {
                                    console.log(
                                        "Updating meeting with payload:",
                                        payload
                                    );
                                    await client.updateMeeting(
                                        payload.meeting_id,
                                        payload
                                    ); // Update the meeting in the database

                                    // Call onUpdateMeeting after updating the meeting in the database
                                    if (onUpdateMeeting) {
                                        console.log(
                                            "Calling onUpdateMeeting for meeting:",
                                            payload.meeting_id
                                        );
                                        onUpdateMeeting({
                                            ...payload,
                                            title: initialEventData.title,
                                            client: {
                                                client_name:
                                                    initialEventData.title,
                                                client_id:
                                                    initialEventData.client_id,
                                                alert: initialEventData.client_alert,
                                            },
                                        });
                                    } else {
                                        console.warn(
                                            "onUpdateMeeting is not defined for meeting:",
                                            payload.meeting_id
                                        );
                                    }

                                    // Sleep for 400ms before the next update (optional)
                                    await sleep(400);
                                } catch (error) {
                                    console.error(
                                        "Error updating meeting:",
                                        payload.meeting_id,
                                        error
                                    );
                                }
                            }

                            // Sleep function for the delay
                            const sleep = (ms) =>
                                new Promise((resolve) =>
                                    setTimeout(resolve, ms)
                                );

                            setSuccess("Réunion mise à jour avec succès !");
                        }
                    } catch (error) {
                        console.log("Save operation cancelled:", error);
                    }
                }
            } else {
                await Promise.all(
                    payloads.map((payload) => {
                        console.log("Payload before removing title:", payload); // Log the payload before modification
                        if (payload.hasOwnProperty("client_name")) {
                            delete payload.title; // Remove the 'title' key if it exists
                        }
                        console.log("Payload after removing title:", payload); // Log the payload after modification
                        return client.createMeeting(payload); // Proceed with the API call
                    })
                );

                // console.log("Event Data", eventData);
                // await client.createMeeting(payload);

                // setSuccess("Meeting created successfully!");
                // Call onCreateMeeting for each recurrence
                // console.log("payloads", payloads);
                if (onCreateMeeting) {
                    payloads.forEach((payload) => onCreateMeeting(payload));
                }
                setIsSubmitting(false);
            }

            closeModal();

            // console.log("This was a success");

            setSuccessMessage("Réunion mise à jour avec succès !");
            // alert(`Meeting successfully ${isEditing ? "updated" : "created"}!`);
        } catch (error) {
            console.log("This was the error", error);

            // Check if the error message is "Cancelled"
            if (error === "Cancelled") {
                setIsPopupOpen(false); // Close the popup
            } else {
                setErrorMessage(
                    "Un problème est survenu. Si l'erreur persite, contactez: support@myway.technology."
                ); // Set the error message
            }
        }
    };

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const addAttendee = (attendeeId) => {
        setEventData((prevData) => {
            if (!prevData.attendees.includes(attendeeId)) {
                return {
                    ...prevData,
                    attendees: [...prevData.attendees, attendeeId],
                };
            }
            return prevData;
        });
    };

    useEffect(() => {
        console.log("Initial attendees:", initialEventData.attendees);
        console.log("Event data attendees:", eventData.attendees);
    }, [eventData.attendees]);
    useEffect(() => {
        console.log("Event data", eventData);
    }, [eventData]);

    const removeAttendee = (attendeeId) => {
        setEventData((prevData) => ({
            ...prevData,
            attendees: prevData.attendees.filter((id) => id !== attendeeId),
        }));
    };

    // useEffect(() => {
    //     if (successMessage) {
    //         const timer = setTimeout(() => setSuccessMessage(""), 30000); // Hide message after 3 seconds
    //         return () => clearTimeout(timer); // Cleanup on unmount
    //     }
    // }, [successMessage]);

    // State to track the selected meeting type ID
    const [selectedMeetingType, setSelectedMeetingType] = useState(
        eventData.meeting_type_id || ""
    );

    useEffect(() => {
        console.log("selectedMeetingType", selectedMeetingType);
    }, [selectedMeetingType]);

    // Find the selected meeting type object
    const selectedMeeting = meetingTypes.find(
        (type) => type.meeting_type_id === selectedMeetingType
    );

    // Determine whether to show the extra input
    const showExtraInput = selectedMeeting?.is_client_meeting === true;

    // Combined change handler
    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedMeetingType(selectedValue); // Update local state

        // Find the meeting type with the selected value
        const selectedMeeting = meetingTypes.find(
            (meeting) => meeting.meeting_type_id === selectedValue
        );

        if (selectedMeeting) {
            handleChange({
                target: {
                    name: "description",
                    value: selectedMeeting.description,
                },
            });
        }

        handleChange(e); // Call the existing handleChange function
    };

    return (
        <IntlProvider
            locale={locale}
            defaultLocale="en"
            messages={messages[locale]}
        >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-md shadow-md md:w-1/2 max-w-[100%] relative">
                    <div className="flex flex-row w-full items-center mb-4">
                        <div className="flex w-10 h-10 bg-gray-100 ml-2 rounded-full items-center justify-center">
                            <FiCalendar />
                        </div>
                        <div>
                            <h2 className="text-xl ml-4">
                                <FormattedMessage
                                    id={
                                        initialEventData.user_id != user_id
                                            ? "meetingModal.details"
                                            : isEditing
                                            ? "editMeetingModal.title"
                                            : "createMeetingModal.title"
                                    }
                                />
                            </h2>
                        </div>
                        {isSubmitting && (
                            <div className="absolute top-1/2 left-1/2 z-10">
                                <Throbber />
                            </div>
                        )}
                    </div>
                    {error && (
                        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded">
                            {error}
                        </div>
                    )}

                    {success && (
                        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded">
                            {success}
                        </div>
                    )}

                    <form>
                        <div className="flex items-center mb-2">
                            <label className="w-1/3 pr-2 text-left relative">
                                <FormattedMessage id="createMeetingModal.typeLabel" />{" "}
                                <RedStar />
                            </label>
                            {isEditing ? (
                                <div className="w-full text-left">
                                    {meetingTypes
                                        .filter(
                                            (type) =>
                                                type.meeting_type_id ===
                                                selectedMeetingType
                                        )
                                        .map((type) => (
                                            <div className="rounded-full md">
                                                {type.description}
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                <select
                                    name="meeting_type_id"
                                    // value={selectedMeetingType}
                                    value={selectedMeetingType}
                                    onChange={handleSelectChange}
                                    className="border rounded-full w-full p-2"
                                    required
                                >
                                    <option value="" disabled>
                                        Sélectionnez un type de réunion.
                                    </option>
                                    {meetingTypes
                                        .filter(
                                            (type) =>
                                                is_client_meeting === true
                                                    ? type.is_client_meeting
                                                    : is_client_meeting ===
                                                      false
                                                    ? !type.is_client_meeting
                                                    : true // If null or undefined, show all meetings
                                        )
                                        .map((type) => (
                                            <option
                                                key={type.meeting_type_id}
                                                value={type.meeting_type_id}
                                            >
                                                {type.description}
                                            </option>
                                        ))}
                                </select>
                            )}
                            {/* )} */}
                        </div>
                        {showExtraInput && showExtraInput == true && (
                            <div className="flex items-center mb-2">
                                <label className="w-1/3 pr-2 text-left">
                                    <FormattedMessage id="createMeetingModal.clientLabel" />{" "}
                                    <RedStar />
                                </label>

                                {isEditing ? (
                                    <div className="flex flex-col text-left w-full p-1">
                                        {initialEventData.client_name}
                                    </div>
                                ) : (
                                    <div className="flex flex-col text-left w-full p-1">
                                        <MeetingClientSearch
                                            isLoading={isLoading}
                                            clients={clients}
                                            selectedClient={selectedClient}
                                            setSelectedClient={
                                                setSelectedClient
                                            }
                                            suggestions={suggestions}
                                            setSuggestions={setSuggestions}
                                            setEventData={setEventData}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {Array.isArray(initialEventData.attendees) &&
                        (initialEventData.attendees.length === 0 ||
                            initialEventData.attendees[0] === user_id) ? (
                            <>
                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        <FormattedMessage id="createMeetingModal.startLabel" />{" "}
                                        <RedStar />
                                    </label>
                                    <input
                                        type="datetime-local"
                                        name="start_date"
                                        value={eventData.start_date}
                                        onChange={handleChange}
                                        placeholder={
                                            <FormattedMessage id="createMeetingModal.selectStartTimePlaceholder" />
                                        }
                                        className="border rounded-full w-full p-2"
                                    />
                                </div>
                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        <FormattedMessage id="createMeetingModal.endLabel" />{" "}
                                        <RedStar />
                                    </label>
                                    <input
                                        type="datetime-local"
                                        name="end_date"
                                        value={eventData.end_date}
                                        onChange={handleChange}
                                        placeholder={
                                            <FormattedMessage id="createMeetingModal.selectEndTimePlaceholder" />
                                        }
                                        className="border rounded-full w-full p-2"
                                    />
                                </div>
                            </>
                        ) : initialEventData.attendees[0] !== user_id ? (
                            <>
                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        <FormattedMessage id="createMeetingModal.startLabel" />
                                    </label>
                                    {eventData.start_date}
                                </div>
                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        <FormattedMessage id="createMeetingModal.endLabel" />
                                    </label>
                                    {eventData.end_date}
                                </div>
                            </>
                        ) : null}

                        {/* {initialEventData.attendees[0] !== user_id && (
                            <>
                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        <FormattedMessage id="createMeetingModal.startLabel" />
                                    </label>

                                    {eventData.start_date}
                                </div>
                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        <FormattedMessage id="createMeetingModal.endLabel" />
                                    </label>
                                    {eventData.end_date}
                                </div>
                            </>
                        )} */}

                        {/* Recurrence options */}
                        {/* <div className="flex items-center mb-2">
                            <label className="w-1/3 pr-2 text-left">
                                Recurring Meeting
                            </label>
                            <input
                                type="checkbox"
                                name="is_recurring"
                                checked={eventData.is_recurring}
                                onChange={handleRecurrenceChange}
                                className="mr-2 left"
                            />
                            <span>Enable Recurrence</span>
                        </div> */}

                        {/* {eventData.is_recurring && (
                            <div>
                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        Recurrence Type
                                    </label>
                                    <select
                                        name="recurrence_type"
                                        value={eventData.recurrence_type}
                                        onChange={handleRecurrenceChange}
                                        className="border rounded-full w-full p-2"
                                    >
                                        <option value="DAILY">Quotidien</option>
                                        <option value="WEEKLY">
                                            Hebdomadaire
                                        </option>
                                        <option value="MONTHLY">Mensuel</option>
                                    </select>
                                </div>

                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        Recurrence End Date
                                    </label>
                                    <input
                                        type="date"
                                        name="recurrence_end_date"
                                        value={eventData.recurrence_end_date}
                                        onChange={handleRecurrenceChange}
                                        className="border rounded-full w-full p-2"
                                    />
                                </div>
                            </div>
                        )} */}

                        {Array.isArray(initialEventData.attendees) &&
                        (initialEventData.attendees.length === 0 ||
                            initialEventData.attendees[0] === user_id) ? (
                            <>
                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        <FormattedMessage id="createMeetingModal.attendeesLabel" />
                                    </label>
                                    <select
                                        name="attendees"
                                        value={eventData.attendees}
                                        onChange={(e) =>
                                            addAttendee(e.target.value)
                                        }
                                        className="border rounded-md w-full p-2"
                                        multiple
                                    >
                                        {attendees.map((attendee) => (
                                            <option
                                                key={attendee.user_id}
                                                value={attendee.user_id}
                                            >
                                                {attendee.first_name}{" "}
                                                {attendee.last_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        <FormattedMessage id="createMeetingModal.selectedAttendeesLabel" />
                                    </label>
                                    <ul className="border rounded-full w-full p-2">
                                        {eventData.attendees
                                            .filter(
                                                (attendeeId) =>
                                                    attendeeId !== user_id
                                            ) // Exclude current user_id
                                            .map((attendeeId) => {
                                                const attendee = attendees.find(
                                                    (a) =>
                                                        a.user_id === attendeeId
                                                );

                                                if (!attendee) return null;
                                                return (
                                                    <li
                                                        key={attendeeId}
                                                        className="mb-2 rounded-full bg-gray-100 flex justify-center items-center px-5"
                                                    >
                                                        {attendee.first_name}{" "}
                                                        {attendee.last_name}
                                                        <button
                                                            onClick={() =>
                                                                removeAttendee(
                                                                    attendeeId
                                                                )
                                                            }
                                                            className="ml-2 bg-red-400 text-white text-xxs w-4 h-4 rounded-full text-sm font-bold flex items-center justify-center leading-none"
                                                        >
                                                            X
                                                        </button>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            </>
                        ) : initialEventData.attendees[0] !== user_id ? (
                            <>
                                <div className="flex items-center mb-2">
                                    <label className="w-1/3 pr-2 text-left">
                                        <FormattedMessage id="createMeetingModal.selectedAttendeesLabel" />
                                    </label>
                                    <ul className="border rounded-full w-full p-2">
                                        {eventData.attendees
                                            .filter(
                                                (attendeeId) =>
                                                    attendeeId !== user_id
                                            ) // Exclude current user_id
                                            .map((attendeeId) => {
                                                const attendee = attendees.find(
                                                    (a) =>
                                                        a.user_id === attendeeId
                                                );

                                                if (!attendee) return null;
                                                return (
                                                    <li
                                                        key={attendeeId}
                                                        className="mb-2 rounded-full bg-gray-100 flex justify-center items-center px-5"
                                                    >
                                                        {attendee.first_name}{" "}
                                                        {attendee.last_name}
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            </>
                        ) : null}
                    </form>

                    <div className="flex justify-end mt-4">
                        {console.log(
                            "initialEventData.attendees",
                            initialEventData.attendees
                        )}
                        {(Array.isArray(initialEventData.attendees) &&
                            initialEventData.attendees.length === 0) ||
                        initialEventData.attendees[0] === user_id ? (
                            <button
                                onClick={handleSave}
                                className="text-white bg-black px-4 py-2 rounded-full mr-2 disabled:bg-gray-200"
                                disabled={
                                    !isEditing &&
                                    (!selectedMeetingType ||
                                        isSubmitting ||
                                        (!eventData.client_id &&
                                            ![
                                                "MT-10",
                                                "MT-11",
                                                "MT-03",
                                                "MT-12",
                                            ].includes(selectedMeetingType)))
                                }
                            >
                                {isPopupOpen && (
                                    <RecurringEventPopup
                                        onConfirm={handleConfirm}
                                        onCancel={handleCancel}
                                    />
                                )}

                                <FormattedMessage
                                    id={
                                        isEditing
                                            ? "editMeetingModal.saveButton"
                                            : "createMeetingModal.saveButton"
                                    }
                                />
                            </button>
                        ) : null}

                        <button
                            onClick={closeModal}
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-full"
                        >
                            <FormattedMessage id="createMeetingModal.cancelButton" />
                        </button>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};

export default MeetingModal;
