/*eslint-disable*/

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    FiLogOut,
    FiHome,
    FiTool,
    FiUsers,
    FiBook,
    FiFilter,
    FiCalendar,
    FiMessageSquare,
    FiUserPlus,
    FiFileText,
    FiBarChart2,
    FiShoppingBag,
} from "react-icons/fi";

import { PiGraduationCapBold, PiTrendUp } from "react-icons/pi";

import { FaGraduationCap } from "react-icons/fa";

import { VscBlank } from "react-icons/vsc";

import { useAuth0 } from "@auth0/auth0-react";

import { CgPerformance } from "react-icons/cg";
import {
    Sidebar,
    Menu,
    MenuItem,
    sidebarClasses,
    SubMenu,
    menuClasses,
} from "react-pro-sidebar";
import { useNavigate, Link } from "react-router-dom";
import FastAPIClient from "../../client";
import config from "../../config";

const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

import build_param from "../../build_params.json";

const frontendPath = build_param.frontendPath;

function Navigator() {
    const [layout, setLayout] = useState([]);
    const [color, setColor] = useState();

    const [loading, setLoading] = useState(false);

    const [locale, setLocale] = useState();
    const [tokenFlag, setToken] = useState(false);

    const navigate = useNavigate();

    // to determine where the user is currently at and highlight the corresponding icon on the nav
    const location = useLocation();
    const currentPage = location.pathname;
    // styling for menu buttons
    const menuButton = "relative py-3 hover:bg-white hover:text-lg";
    const activeMenuButton = "relative py-3 bg-white font-semibold text-lg";
    const labelStyle =
        "absolute bottom-0 pb-4 h-1 w-full text-center -translate-y-3/4 text-xs";

    // determine if on client page or homepage
    const [isHome, setIsHome] = useState(false);
    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        if (
            currentPage.split("/")[1] === "client" ||
            currentPage === "/clients"
        ) {
            setIsClient(true);
            setIsHome(false);
        } else {
            setIsClient(false);
            if (currentPage === "/home" || currentPage === "/") {
                setIsHome(true);
            } else {
                setIsHome(false);
            }
        }
    }, [location]);

    const { getAccessTokenSilently } = useAuth0();

    const [leadNotification, setLeadNotification] = useState(false);
    const [recommendationNotification, setRecommendationNotification] =
        useState(false);

    const handleLogout = () => {
        client.logout();

        navigate("/");
    };

    // color coding
    const bgColor = "#F5F5F5";
    const hoverColor = "white";

    const { logout } = useAuth0();

    const logout_auth0 = async () => {
        localStorage.removeItem("token");
        localStorage.removeItem("token_test");
        localStorage.removeItem("user");
        localStorage.removeItem("layoutData");
        localStorage.removeItem("language");
        logout({
            logoutParams: {
                returnTo: `${frontendPath}/`,
            },
        });
    };

    // Function to fetch user data and save it in localStorage
    const fetchUserData = async () => {
        try {
            const data = await client.fetchUser();

            // Set user state
            await setUser(data);
            // Save user data in localStorage
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("language", data.language);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    // Function to check if user data is present in localStorage
    const getUserDataFromLocalStorage = () => {
        const storedUserData = localStorage.getItem("user");

        if (storedUserData) {
            // If data is present, parse and return it
            return JSON.parse(storedUserData);
        }

        // If data is not present, return null or handle accordingly
        return null;
    };

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://www.api.myway.technology",
                        scope: "read:appointments",
                    },
                });

                localStorage.setItem("token", accessToken);
                // You can set the current time using the moment library here if needed
            } catch (error) {
                console.error("Failed to fetch access token:", error);
            }
        };
        // localStorage.setItem("language", "hase");
        const tokenData = localStorage.getItem("token");

        // Check if tokenData is empty or null
        if (tokenData == null || tokenData == undefined) {
            fetchAccessToken();
            setToken(true);
        } else {
            setToken(true);
        }
    }, []); // Empty dependency array ensures this effect runs only once on component mount

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Check if user data is present in localStorage
                const userDataFromLocalStorage = getUserDataFromLocalStorage();

                if (userDataFromLocalStorage) {
                    // If data is present, use it directly
                    setUser(userDataFromLocalStorage);

                    const storedLanguage = localStorage.getItem("language");
                    setLocale(storedLanguage);

                    // Simulate asynchronous behavior with a delay
                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    setLoading(false);
                } else {
                    // If data is not present, fetch it
                    await fetchUserData();

                    // Simulate asynchronous behavior with a delay
                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    // const storedLanguage =
                    await setLocale(localStorage.getItem("language"));

                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle errors here
            }
        };

        // Call the asynchronous function
        fetchData();

        // ... (other useEffect logic)
    }, [tokenFlag]); // Empty dependency array means it runs only on mount

    const [user, setUser] = useState(false);

    // Function to fetch layout and save it in localStorage
    const fetchLayout = async () => {
        try {
            const data = await client.getLayout();
            // Save layout data in localStorage
            localStorage.setItem("layoutData", JSON.stringify(data));
            // Set layout state
            setLayout(data);
        } catch (error) {
            console.error("Error fetching layout:", error);
        }
    };

    const [notifications, setNotifications] = useState([]);

    const getNotifications = (userId) => {
        client.getNotifications(userId).then((data) => {
            setNotifications(data?.notifications);
            console.log(data?.notifications);
        });
    };

    useEffect(() => {
        // Check if user is online
        if (user.user_id && navigator.onLine) {
            // Run immediately when the component mounts
            getNotifications(user.user_id);

            // Set an interval to call getNotifications every 30 seconds (30000 milliseconds)
            const interval = setInterval(() => {
                if (navigator.onLine) {
                    getNotifications(user.user_id);
                }
            }, 3000);

            // Cleanup the interval when the component unmounts or when the user_id or selectedClient changes
            return () => clearInterval(interval);
        }
    }, [user.user_id]);

    // Function to check if layout data is present in localStorage
    const getLayoutDataFromLocalStorage = () => {
        const storedLayoutData = localStorage.getItem("layoutData");

        if (storedLayoutData) {
            // If data is present, parse and return it
            return JSON.parse(storedLayoutData);
        }

        // If data is not present, return null or handle accordingly
        return null;
    };

    useEffect(() => {
        // Check if layout data is present in localStorage
        const layoutDataFromLocalStorage = getLayoutDataFromLocalStorage();

        if (layoutDataFromLocalStorage) {
            // If data is present, use it directly
            setLayout(layoutDataFromLocalStorage);
            // setColor(layoutDataFromLocalStorage[0]?.client);
            // setColor(layoutDataFromLocalStorage[0]?.client);
        } else {
            // If data is not present, fetch it
            fetchLayout();
        }

        // ... (other useEffect logic)
    }, [tokenFlag]); // Empty dependency array means it runs only on mount

    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            // You can adjust the breakpoint value as needed
            const breakpoint = 1366; // Example breakpoint value

            // Update the collapsed state based on the screen width
            setCollapsed(window.innerWidth <= breakpoint);
        };

        // Initial call to set the initial collapsed state
        handleResize();

        // Attach the event listener to handle window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Emp

    // Condition to check if there are any notifications

    const closed = true;

    return !(locale == "en" || locale == "fr") ? (
        <div></div>
    ) : (
        <div
            className="flex flex-col sticky top-0 z-50 h-screen text-left text-black rounded-r-lg bg-white text-base hidden md:flex font-semibold"
            style={{ background: bgColor }}
        >
            <IntlProvider
                locale={locale}
                defaultLocale="en"
                messages={messages[locale]}
            >
                <Sidebar
                    collapsed={closed}
                    rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            backgroundColor: bgColor,

                            borderTopRightRadius: "7px",
                        },
                    }}
                    style={{ borderRight: "#ffffff" }}
                >
                    {" "}
                    <div className="pl-2 pt-10 pr-2 flex flex-col items-center h-100 mt-10">
                        {!collapsed && (
                            <div>
                                <img src={layout.logo_url} width="200"></img>
                            </div>
                        )}
                        {collapsed && (
                            <div
                                className="flex flex-col items-center"
                                onClick={() => navigate("/profile")}
                            >
                                <div className="w-100 h- overflow-hidden">
                                    <img
                                        src={layout.logo_url}
                                        height="50"
                                        className=""
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <Menu
                        menuItemStyles={{
                            button: {
                                paddingBottom: "15px",
                                height: "100%",
                                "&:hover": {
                                    backgroundColor: hoverColor,
                                },
                                [`&.active`]: {
                                    backgroundColor: "#ffffff",
                                    color: "#666666",
                                    fontWeight: "bolder",
                                    height: "100%",
                                },
                            },
                        }}
                        className="pt-10"
                    >
                        <div
                            className={isHome ? activeMenuButton : menuButton}
                            onClick={() => navigate("/home")}
                        >
                            <MenuItem icon={<FiHome />}></MenuItem>
                            <div className={labelStyle}>
                                <FormattedMessage
                                    id="navigator.home"
                                    values={{ number: 1 }}
                                />
                            </div>
                        </div>

                        {user.is_route_module && (
                            <div
                                className={
                                    currentPage === "/routing"
                                        ? activeMenuButton
                                        : menuButton
                                }
                                onClick={() => navigate("/routing")}
                            >
                                <MenuItem
                                    icon={<FiCalendar />}
                                    component={<Link to="/routing" />}
                                ></MenuItem>
                                <div className={labelStyle}>
                                    <FormattedMessage
                                        id="navigator.myDay"
                                        values={{ number: 1 }}
                                    />
                                </div>
                            </div>
                        )}

                        <div
                            className={isClient ? activeMenuButton : menuButton}
                            onClick={() => navigate("/clients")}
                        >
                            <MenuItem
                                icon={<FiUsers />}
                                component={<Link to="/clients" />}
                            ></MenuItem>
                            <div className={labelStyle}>
                                <FormattedMessage
                                    id="navigator.Clients"
                                    values={{ number: 1 }}
                                />
                            </div>
                        </div>

                        {user.is_lead_module && (
                            <div
                                className={
                                    currentPage === "/leads"
                                        ? activeMenuButton
                                        : menuButton
                                }
                                onClick={() => navigate("/leads")}
                            >
                                <MenuItem
                                    icon={<FiUserPlus />}
                                    component={<Link to="/leads" />}
                                ></MenuItem>
                                <div className={labelStyle}>
                                    <FormattedMessage
                                        id="navigator.Leads"
                                        values={{ number: 1 }}
                                    />
                                    {leadNotification != 0 && (
                                        <div className="bg-notification rounded-full ml-5 font-semibold"></div>
                                    )}
                                </div>
                            </div>
                        )}

                        {(user.user_role_id === "R-01" ||
                            user.user_role_id === "R-02") && (
                            <div
                                className={
                                    currentPage === "/management/dashboard"
                                        ? activeMenuButton
                                        : menuButton
                                }
                                onClick={() =>
                                    navigate("/management/dashboard")
                                }
                            >
                                <MenuItem
                                    component={
                                        <Link to="/management/dashboard" />
                                    }
                                    icon={<FiBarChart2 />}
                                ></MenuItem>
                                <div className={labelStyle}>
                                    <FormattedMessage
                                        id="navigator.Dashboard"
                                        values={{ number: 1 }}
                                    />
                                </div>
                            </div>
                        )}
                        {user.user_role_id === "R-03" && (
                            <div
                                className={
                                    currentPage === "/profile"
                                        ? activeMenuButton
                                        : menuButton
                                }
                                onClick={() => navigate("/profile")}
                            >
                                <MenuItem
                                    component={<Link to="/profile" />}
                                    icon={<FiBarChart2 />}
                                />
                                <div className={labelStyle}>
                                    <FormattedMessage
                                        id="navigator.Dashboard"
                                        values={{ number: 1 }}
                                    />
                                </div>
                            </div>
                        )}

                        <div
                            className={
                                currentPage === "/products"
                                    ? activeMenuButton
                                    : menuButton
                            }
                            onClick={() => navigate("/products")}
                        >
                            <MenuItem
                                icon={<FiShoppingBag />}
                                component={<Link to="/products" />}
                            />
                            <div className={labelStyle}>
                                <FormattedMessage
                                    id="navigator.Product"
                                    values={{ number: 1 }}
                                />
                            </div>
                        </div>
                        <div
                            className={
                                currentPage === "/chat"
                                    ? activeMenuButton
                                    : menuButton
                            }
                            onClick={() => navigate("/chat")}
                        >
                            <MenuItem
                                icon={
                                    <FiMessageSquare
                                        style={{
                                            color:
                                                notifications.length > 0
                                                    ? "rose-500"
                                                    : "black", // Change color based on notification presence
                                        }}
                                    />
                                }
                                component={<Link to="/chat" />}
                            />
                            <div className={labelStyle}>
                                <FormattedMessage
                                    id="navigator.chat"
                                    values={{ number: 1 }}
                                />
                                {notifications.length > 0 && (
                                    <div
                                        style={{
                                            position: "relative",
                                            top: "-45px",
                                            right: "-42px",
                                            backgroundColor: "red",
                                            borderRadius: "50%",
                                            width: "15px",
                                            height: "15px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "white",
                                            fontSize: "10px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {notifications.length}
                                    </div>
                                )}
                            </div>
                        </div>

                        {user.is_training_module && (
                            <div
                                className={
                                    currentPage === "/training"
                                        ? activeMenuButton
                                        : menuButton
                                }
                                onClick={() => navigate("/training")}
                            >
                                {" "}
                                <MenuItem
                                    icon={<PiGraduationCapBold />}
                                    component={<Link to="/training" />}
                                    rootStyles={
                                        currentPage === "/training" && {
                                            backgroundColor: "#ffffff",
                                        }
                                    }
                                ></MenuItem>
                                <div className="absolute bottom-0 pt-6 h-1 w-full text-center -translate-y-3/4 text-xs">
                                    <FormattedMessage
                                        id="navigator.Training"
                                        values={{ number: 1 }}
                                    />
                                </div>
                            </div>
                        )}
                    </Menu>
                </Sidebar>
                <div className="flex-grow"></div>
                <Sidebar
                    collapsed={closed}
                    rootStyles={{
                        [`.${sidebarClasses.container}`]: {
                            backgroundColor: bgColor,
                            borderBottomRightRadius: "7px",
                            borderRight: "0px",
                        },
                    }}
                    style={{ borderRight: "#ffffff" }}
                >
                    {" "}
                    <Menu
                        menuItemStyles={{
                            button: {
                                paddingBottom: "15px",
                                height: "100%",
                                "&:hover": {
                                    backgroundColor: hoverColor,
                                },
                                [`&.active`]: {
                                    backgroundColor: "#ffffff",
                                    color: "#666666",
                                    fontWeight: "bolder",
                                    height: "100%",
                                },
                            },
                        }}
                        className="pt-10"
                    >
                        {/* <MenuItem
                            icon={<FiTool />}
                            component={<Link to="/settings" />}
                        >
                            {" "}
                            <FormattedMessage
                                id="navigator.Settings"
                                values={{ number: 1 }}
                            />
                        </MenuItem> */}
                        <div
                            className={
                                currentPage === "/logout"
                                    ? activeMenuButton
                                    : menuButton
                            }
                            onClick={() => logout_auth0()}
                        >
                            <MenuItem
                                icon={<FiLogOut />}
                                // component={<Link to="/chat" />}
                            />
                            <div className={labelStyle}>
                                <FormattedMessage
                                    id="navigator.Logout"
                                    values={{ number: 1 }}
                                />
                            </div>
                        </div>
                    </Menu>
                </Sidebar>
            </IntlProvider>
        </div>
    );
}

export default Navigator;
