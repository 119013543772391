import React from "react";

const SubmittedDocuments = ({ options, handleInputChange, responses }) => {
    const handleCheckboxChange = (optionValue, key) => {
        const currentResponse = responses.provided_documents || {};
        const updatedOptionResponse = {
            ...currentResponse[optionValue],
            [key]: !currentResponse[optionValue]?.[key],
        };

        const newResponse = {
            ...currentResponse,
            [optionValue]: updatedOptionResponse,
        };

        handleInputChange("provided_documents", newResponse);
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="text-left w-full font-semibold">
                Sélectionner les documents et informations transmises lors du
                RDV et celles à fournir aux prochaines étapes
            </div>

            {options.map((option, index) => (
                <div
                    key={`doc-${index}`}
                    className="flex flex-col md:flex-row gap-4 p-4 items-center"
                >
                    {/* Option Name */}
                    <div className="w-full md:w-1/6 text-left">
                        {option.label}
                    </div>

                    {/* Transmis (Checkbox for "Transmitted") */}
                    <label className="flex items-center gap-2 w-full md:w-1/6">
                        <input
                            type="checkbox"
                            checked={
                                responses.provided_documents?.[option.label]
                                    ?.transmitted || false
                            }
                            onChange={() =>
                                handleCheckboxChange(
                                    option.label,
                                    "transmitted"
                                )
                            }
                        />
                        Transmis
                    </label>

                    {/* A transmettre (Checkbox for "To be transmitted") */}
                    <label className="flex items-center gap-2 w-full md:w-1/6">
                        <input
                            type="checkbox"
                            checked={
                                responses.provided_documents?.[option.label]
                                    ?.to_transmit || false
                            }
                            onChange={() =>
                                handleCheckboxChange(
                                    option.label,
                                    "to_transmit"
                                )
                            }
                        />
                        A transmettre
                    </label>
                </div>
            ))}
        </div>
    );
};

export default SubmittedDocuments