import React, { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { parse, isValid } from "date-fns";
import esLocale from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate } from "react-router-dom";
import { useNetwork } from "../../components/NetworkProvider";

import SharedCalendarList from "./SharedCalendarList";
import { FiRefreshCcw, FiTrash2 } from "react-icons/fi";
import Throbber from "../../style/Throbber";

import ToggleButton from "../Buttons/ToggleButton";

import MeetingModal from "../ModalComponents/MeetingModal";

import RecurringEventPopup from "../ModalComponents/MeetingModal/utils.jsx";

import FastAPIClient from "../../client";
import fast_api_config from "../../config";
const client = new FastAPIClient(fast_api_config);

const parseDate = (dateString) => {
    // console.log("dateString", dateString);
    const formats = [
        "M/d/yyyy, h:mm:ss a",
        "d/MM/yyyy, HH:mm:ss",
        "yyyy-MM-dd'T'HH:mm:ss",
        "yyyy-MM-dd'T'HH:mm:ssX",
        "dd/MM/yyyy HH:mm:ss",
        "MM/d/yyyy h:mm:ss a",
        "MMMM yyyy",
        "EEEE, MMMM d, yyyy h:mm:ss a",
        "yyyy-MM-dd HH:mm:ss",
    ];
    for (const format of formats) {
        const parsedDate = parse(dateString, format, new Date());
        // console.log("Attempting to parse:", dateString, "with format:", format);
        // Check if the parsed date is valid
        if (isValid(parsedDate)) {
            console.log("Valid date:", parsedDate);
            return parsedDate;
        } else {
            console.log("Invalid date for format:", format);
        }
    }
    throw new Error("Unable to parse date");
};
const formatToTimestamp = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed, so add 1)
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const CalendarModule = ({
    meetings,
    height = "80vh",
    locale,
    isEditing,
    setIsEditing,
    originalMeetings,
    setOriginalMeetings,
    meetingTypes,
    activeCalendars,
    setActiveCalendars,
    calendarLoading,
    setCalendarLoading,
}) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { isOnline } = useNetwork();

    const [newEvents, setnewEvents] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [usersListOriginal, setUsersListOriginal] = useState([]);

    // console.log("Meetings", meetings);

    const [meetingUpdated, setMeetingUpdated] = useState({});
    const formatNewEvents = (list) => {
        return list.map((item) => ({
            title: item.client.client_name,
            client_id: item.client.client_id,
            meeting_id: item.meeting_id,
            start: item.start_date,
            end: item.end_date,
            is_visited: item.is_visited,
            type: item.meeting_type_id,
            client_alert: item.client.alert,
            attendees: item.attendees,
            is_recurring: item.is_recurring,
            recurring_id: item.recurring_id,
            is_organizer: item.is_organizer,
            description: item.type?.description || item.description,
            user: item.user,
        }));
    };
    // Modal related state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newEventData, setNewEventData] = useState({
        title: "",
        start: "",
        meeting_id: "",
        end: "",
        location: "",
    });
    useEffect(() => {
        console.log("meeting", meetings);
        setnewEvents(formatNewEvents(meetings));
    }, [meetings, isModalOpen]);

    useEffect(() => {
        updateAllMeetings(meetingUpdated);
    }, [meetingUpdated]);

    const updateAllMeetings = (meetingToUpdate) => {
        console.log("meetingToUpdate", meetingToUpdate);
        setOriginalMeetings((prevMeetings) =>
            prevMeetings.map(
                (meeting) =>
                    meeting.meeting_id === meetingToUpdate.meeting_id
                        ? {
                              ...meeting,
                              start_date: meetingToUpdate.start_date,
                              end_date: meetingToUpdate.end_date,
                              attendees: meetingToUpdate.attendees,
                              meeting_type_id: meetingToUpdate.meeting_type_id,
                          }
                        : meeting // Return unchanged meeting
            )
        );
    };

    const [viewMode, setViewMode] = useState("weekly"); // new state for switching views
    // const location = useLocation(); // Hook to get the current URL
    const navigate = useNavigate(); // Hook to update the URL

    const handleViewToggle = () => {
        const newViewMode = viewMode === "daily" ? "weekly" : "daily";
        setViewMode(newViewMode);
        navigate(`?view=${newViewMode}`); // Update URL with the new view mode
    };

    const validateDrop = (event) => {
        const newStart = event.start;
        const newEnd = event.end;

        // Create Date objects for working hours on the same day as the event
        const workingHoursStart = new Date(newStart);
        workingHoursStart.setHours(8, 0, 0, 0); // 08:00 AM

        const workingHoursEnd = new Date(newStart);
        workingHoursEnd.setHours(20, 0, 0, 0); // 08:00 PM

        // Check if the event falls within working hours
        return newStart >= workingHoursStart && newEnd <= workingHoursEnd;
    };

    const handleEventDrop = (info) => {
        const { event, revert } = info;
        if (
            !event.extendedProps.attendees.includes(user.user_id) ||
            !event.extendedProps.is_organizer
        ) {
            revert();
            return;
        }
        // console.log("Event", event);

        // Validate the drop
        if (!validateDrop(event)) {
            revert();
            // TODO: insert this message in the translations
            setErrorMessage("Les réunions doivent être entre 8h et 20h");

            return;
        }

        // console.log("Start", event.start);
        // console.log("End", event.end);

        console.log("EVENT DROP", event);

        const newStart = event.start.toLocaleString();
        const newEnd = event.end.toLocaleString();

        updateMeeting(
            event.extendedProps.meeting_id,
            event.extendedProps.user,
            parseDate(newStart),
            parseDate(newEnd),
            revert, // Pass the revert function to updateMeeting
            event.extendedProps.is_recurring,
            event.extendedProps.recurring_id,
            event.extendedProps.type,
            event.start,
            event.extendedProps.attendees,
            event.extendedProps.client_id,
            event.title,
            event.extendedProps.is_organizer
        );
    };

    const updateMeeting = async (
        meetingId,
        user,
        newStart,
        newEnd,
        revert,
        isRecurring,
        recurringId,
        meeting_type_id,
        orginial_start_date,
        attendees,
        client_id,
        client_name,
        is_organizer
    ) => {
        setIsUpdating(true);
        try {
            // If the meeting is recurring, ask the user whether to update one or all
            if (isRecurring == true) {
                const selectedOption = await openPopup();

                if (selectedOption === "this-event") {
                    // Update only this meeting
                    await updateSingleMeeting(
                        meetingId,
                        newStart,
                        newEnd,
                        meeting_type_id,
                        client_id,
                        client_name
                    );
                } else if (selectedOption === "this-and-following") {
                    // Update all future occurrences
                    await updateRecurringMeetings(
                        recurringId,
                        newStart,
                        newEnd,
                        meeting_type_id,
                        orginial_start_date
                    );
                } else {
                    console.log("cancelled");
                }
            } else {
                // If not recurring, update normally
                console.log("CLIENT NAME", client_name);
                await updateSingleMeeting(
                    meetingId,
                    user,
                    newStart,
                    newEnd,
                    meeting_type_id,
                    attendees,
                    client_id,
                    client_name,
                    is_organizer
                );
                setIsUpdating(false);
            }
        } catch (error) {
            console.error("Failed to update meeting:", error);
            if (error === "Cancelled") {
                setIsPopupOpen(false); // Close the popup
                revert();
            } else {
                setErrorMessage(
                    "Un problème est survenu. Si l'erreur persite, contactez: support@myway.technology."
                ); // Set the error message
                revert(); // Revert the drag if the API call fails
                // setErr("Échec de la reprogrammation de la réunion");
                // setMessageType("error");
            }
            setIsUpdating(false);
        }
    };

    // Function to update a single meeting
    const updateSingleMeeting = async (
        meetingId,
        user,
        newStart,
        newEnd,
        meeting_type_id,
        attendees,
        client_id,
        client_name,
        is_organizer
    ) => {
        const payload = {
            meeting_id: meetingId,
            user: user,
            start_date: formatToTimestamp(newStart),
            end_date: formatToTimestamp(newEnd),
            updated_at: new Date().toISOString(),
            update_recurring: false,
            meeting_type_id: meeting_type_id,
            attendees: attendees,
            client: { client_id: client_id, client_name: client_name },
            is_organizer: is_organizer,
        };
        // console.log("Update payload", payload);
        console.log("this is where we");

        await client.updateMeeting(meetingId, payload);
        // Add is_recurring: false only before setMeetingUpdated
        const updatedPayload = { ...payload, is_recurring: false };
        setMeetingUpdated(updatedPayload);
        setSuccessMessage(`La réunion a été reprogrammée`);
        // setMessageType("success");
    };

    const updateRecurringMeetings = async (
        recurringId,
        newStart,
        newEnd,
        meeting_type_id,
        orginial_start_date
    ) => {
        try {
            // Use stored meetings instead of fetching from API
            const allMeetings = meetings.filter(
                (meeting) => meeting.recurring_id === recurringId
            );
            // console.log("all meetings", allMeetings);

            // console.log("new Start", newStart);

            console.log("original start date", orginial_start_date);

            const threshold = new Date(newStart);

            // Set the hours to 1 AM, and reset minutes, seconds, and milliseconds
            threshold.setHours(1, 0, 0, 0);

            // Convert to ISO format
            const newformattedDate = threshold.toISOString();
            // console.log("newformattedDate", newformattedDate);

            // Get current timestamp
            // const now = new Date().toISOString();

            // const formattedDate = newStart.toISOString();
            // console.log(formattedDate);

            // console.log(now);

            // Filter only future meetings
            const futureMeetings = allMeetings.filter(
                (meeting) => meeting.start_date >= newformattedDate
            );

            if (futureMeetings.length === 0) {
                setPopupMessage("Aucune réunion future à mettre à jour.");
                setMessageType("info");

                return;
            }

            // Prepare update requests
            const updatePromises = futureMeetings.map((meeting) => {
                // Preserve original date but apply new time
                const originalDate = new Date(meeting.start_date);
                const newStartTime = new Date(newStart);
                const newEndTime = new Date(newEnd);

                const updatedStart = new Date(
                    originalDate.getFullYear(),
                    originalDate.getMonth(),
                    originalDate.getDate(),
                    newStartTime.getHours(),
                    newStartTime.getMinutes(),
                    newStartTime.getSeconds()
                );

                const updatedEnd = new Date(
                    originalDate.getFullYear(),
                    originalDate.getMonth(),
                    originalDate.getDate(),
                    newEndTime.getHours(),
                    newEndTime.getMinutes(),
                    newEndTime.getSeconds()
                );

                const payload = {
                    meeting_id: meeting.meeting_id,
                    start_date: updatedStart.toISOString(),
                    end_date: updatedEnd.toISOString(),
                    updated_at: new Date().toISOString(),
                    update_recurring: true,
                    meeting_type_id: meeting_type_id,
                };

                // console.log("recurring payload", payload);

                return client.updateMeeting(meeting.meeting_id, payload);
            });

            // Execute all update requests
            await Promise.all(updatePromises);

            // Update the local state after updating the meetings
            setOriginalMeetings((prevMeetings) =>
                prevMeetings.map((meeting) =>
                    meeting.recurring_id === recurringId &&
                    meeting.start_date >= newformattedDate
                        ? {
                              ...meeting,
                              start_date: new Date(
                                  new Date(meeting.start_date).setHours(
                                      new Date(newStart).getHours(),
                                      new Date(newStart).getMinutes(),
                                      new Date(newStart).getSeconds()
                                  )
                              ).toISOString(),
                              end_date: new Date(
                                  new Date(meeting.end_date).setHours(
                                      new Date(newEnd).getHours(),
                                      new Date(newEnd).getMinutes(),
                                      new Date(newEnd).getSeconds()
                                  )
                              ).toISOString(),
                          }
                        : meeting
                )
            );

            setMeetingUpdated({ recurring_id: recurringId, newStart, newEnd });
            setSuccessMessage(
                "Toutes les réunions récurrentes ont été mises à jour."
            );
            // setMessageType("success");
        } catch (error) {
            console.error(
                "Échec de la mise à jour des réunions récurrentes:",
                error
            );
            // setPopupMessage(
            //     "Échec de la mise à jour des réunions récurrentes."
            // );
            // setMessageType("error");
        }
    };

    const openModal = (arg) => {
        // Reset all fields for new meetings
        setNewEventData({
            title: "",
            client_name: "",
            client_id: "",
            start: arg.date,
            end: new Date(arg.date).setHours(arg.date.getHours() + 1),
            meeting_id: "",
            location: "",
            meeting_type_id: "",
            attendees: [],
            client_alert: null,
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        if (isEditing) {
            setIsEditing(false);
        }
    };

    // Function to handle adding an event when a time slot is clicked
    const handleDateClick = (arg) => {
        // console.log("arg", arg);
        openModal(arg);
    };

    // Function to handle event navigation
    const handleEventClick = (eventInfo) => {
        // console.log(eventInfo);
        setNewEventData({
            title: eventInfo.event.title,
            client_name: eventInfo.event.title,
            client_id: eventInfo.event.extendedProps.client_id,
            start: eventInfo.event.start,
            end: eventInfo.event.end,
            user: eventInfo.event.extendedProps.user,
            meeting_id: eventInfo.event.extendedProps.meeting_id,
            location: eventInfo.event.extendedProps.location || "",
            meeting_type_id: eventInfo.event.extendedProps.type,
            attendees: eventInfo.event.extendedProps.attendees || [],
            is_recurring: eventInfo.event.extendedProps.is_recurring,
            recurring_id: eventInfo.event.extendedProps.recurring_id,
        });
        if (!eventInfo.event.extendedProps.is_visited) {
            setIsModalOpen(true);
            setIsEditing(true);
        }
    };

    const handleDeleteEvent = async (
        eventId,
        is_recurring,
        recurring_id,
        meeting_start_date
    ) => {
        setIsUpdating(true);
        try {
            if (is_recurring) {
                // Show confirmation popup
                const selectedOption = await openPopup();

                if (selectedOption === "this-event") {
                    // Delete only this specific event
                    await client.deleteMeeting(eventId);
                    setOriginalMeetings((prevMeetings) =>
                        prevMeetings.filter(
                            (meeting) => meeting.meeting_id !== eventId
                        )
                    );
                    setSuccessMessage(
                        "La réunion a été supprimée avec succès."
                    );
                    setIsUpdating(false);
                } else if (selectedOption === "this-and-following") {
                    // Delete the entire series using recurring_id
                    await client.deleteMeetingsByRecurrence(
                        recurring_id,
                        meeting_start_date
                    );
                    setOriginalMeetings((prevMeetings) =>
                        prevMeetings.filter(
                            (meeting) => meeting.recurring_id !== recurring_id
                        )
                    );
                    setSuccessMessage(
                        "Les réunions ont été supprimée avec succès."
                    );
                    setIsUpdating(false);
                } else {
                    log("console", cancelling);
                }
            } else {
                // Delete a non-recurring meeting
                await client.deleteMeeting(eventId);
                setOriginalMeetings((prevMeetings) =>
                    prevMeetings.filter(
                        (meeting) => meeting.meeting_id !== eventId
                    )
                );
                setSuccessMessage("La réunion a été supprimée avec succès.");
                setIsUpdating(false);
            }

            // setMessageType("success"); // Set message type to success
            // setShowPopup(true); // Show the popup
            // setTimeout(() => setShowPopup(false), 3000); // Hide the popup after 3 seconds
        } catch (error) {
            console.error("Failed to delete meeting:", error);
            setIsUpdating(false);
            // setPopupMessage("Failed to delete the meeting.");
            // Check if the error message is "Cancelled"
            // if (error === "Cancelled") {
            //     setIsPopupOpen(false); // Close the popup
            // } else {
            //     setErrorMessage("There was an error"); // Set the error message
            // }
            // setMessageType("error"); // Set message type to error
            // setShowPopup(true); // Show the popup
            // setTimeout(() => setShowPopup(false), 3000); // Hide the popup after 3 seconds
        }
    };

    // Popup related logic
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const popupResolverRef = useRef(null); // Use ref to store the resolver functions

    // Function to open the popup and return a promise
    const openPopup = () => {
        return new Promise((resolve, reject) => {
            popupResolverRef.current = { resolve, reject }; // Store resolver functions in ref
            setIsPopupOpen(true); // Open the popup
        });
    };

    // Called when user clicks OK in the popup
    const handleConfirm = (selectedOption) => {
        if (popupResolverRef.current) {
            popupResolverRef.current.resolve(selectedOption); // Resolve the promise with selected option
            popupResolverRef.current = null; // Clear the resolver
            setIsPopupOpen(false); // Close the popup
        }
    };

    // Called when user clicks Cancel in the popup
    const handleCancel = () => {
        // console.log("Cancelling...");
        if (popupResolverRef.current) {
            popupResolverRef.current.reject("Cancelled"); // Reject the promise
            popupResolverRef.current = null; // Clear the resolver
            setIsPopupOpen(false); // Close the popup
        }
    };

    const deleteEvent = (meetingId) => {
        const eventsAfterDelete = [...newEvents].filter(
            (event) => event.meeting_id != meetingId
        );
        setnewEvents(eventsAfterDelete);
        setOriginalMeetings(eventsAfterDelete);
    };

    const addNewEvent = (newMeeting) => {
        // console.log("new Meeting", newMeeting);
        setnewEvents((prevEvents) => [
            ...prevEvents,
            {
                title: newMeeting.client_name,
                client_id: newMeeting.client_id,
                meeting_id: newMeeting.meeting_id,
                start: newMeeting.start_date,
                end: newMeeting.end_date,
                type: newMeeting.meeting_type_id,
                user: newMeeting.user,
                attendees: newMeeting.attendees, // Add this
                is_visited: false,
                client_alert: newMeeting.client_alert,
                is_recurring: newMeeting.is_recurring,
                description: newMeeting.description,
                client: {
                    // Add client object structure
                    client_name: newMeeting.client_name,
                    client_id: newMeeting.client_id,
                },
            },
        ]);
        setOriginalMeetings((prevMeetings) => [
            ...prevMeetings,
            {
                title: newMeeting.title,
                meeting_id: newMeeting.meeting_id,
                start_date: newMeeting.start_date,
                end_date: newMeeting.end_date,
                user: newMeeting.user,
                meeting_type_id: newMeeting.meeting_type_id,
                attendees: newMeeting.attendees, // Add this
                is_visited: false,
                is_recurring: newMeeting.is_recurring,
                recurring_id: newMeeting.recurring_id,
                is_organizer: true,
                description: newMeeting.description,
                client: {
                    // Add client object structure
                    client_name: newMeeting.client_name,
                    client_id: newMeeting.client_id,
                    alert: newMeeting.client_alert,
                },
            },
        ]);
    };

    // update existing event : drag and drop
    const updateExistingEvent = (updatedMeeting) => {
        console.log("updated meeting", updatedMeeting);
        setnewEvents((prevEvents) =>
            prevEvents.map((event) =>
                event.meeting_id === updatedMeeting.meeting_id
                    ? {
                          title: updatedMeeting.client_name,
                          client_id: updatedMeeting.client_id,
                          meeting_id: updatedMeeting.meeting_id,
                          start: updatedMeeting.start_date,
                          end: updatedMeeting.end_date,
                          user: updatedMeeting.user,
                          type: updatedMeeting.meeting_type_id,
                          attendees: updatedMeeting.attendees, // Add this
                          is_visited: event.is_visited, // Preserve visited status
                          is_organizer: true,
                          client: {
                              // Add client object structure
                              client_name: updatedMeeting.client.client_name,
                              client_id: updatedMeeting.client.client_id,
                              alert: updatedMeeting.client.client_alert,
                          },
                      }
                    : event
            )
        );
        setMeetingUpdated(updatedMeeting);
    };

    // const getEventTitle = (eventInfo) => {
    //     const title = eventInfo.event.title;
    //     const description = eventInfo.event.extendedProps.description;

    //     // Use description if title is null or "null"
    //     const validTitle =
    //         title && title !== "null" ? title : description ?? "";

    //     // Truncate if longer than 20 characters
    //     return validTitle.length > 20
    //         ? `${validTitle.substring(0, 15)}...`
    //         : validTitle;
    // };

    const getEventTitle = (eventInfo) => {
        if (
            eventInfo.event.extendedProps.client_name ===
            "Duo Responsable: RDV Négo"
        ) {
            console.log("Event info", eventInfo);
        }

        const title = eventInfo.event.title;
        const description = eventInfo.event.extendedProps.description;

        // Consider "null", undefined, and empty title as invalid
        const isValidTitle = title && title !== "null" && title !== "undefined";

        // Use the title if valid, otherwise fall back to the description
        const validTitle = isValidTitle ? title : description ?? "";

        // Truncate if the length is greater than 20
        return validTitle.length > 20
            ? `${validTitle.substring(0, 15)}...`
            : validTitle;
    };

    const renderEventContent = (eventInfo) => {
        let borderStyle = {};
        // Check if user is not an attendee
        if (!eventInfo.event.extendedProps.attendees?.includes(user.user_id)) {
            console.log("User not in attendees, checking for matching user");

            console.log(eventInfo.event.extendedProps.user.user_id);
            console.log(usersList);
            const matchingUser = usersListOriginal.find(
                (el) =>
                    el.user.user_id ===
                    eventInfo.event.extendedProps.user.user_id
            );

            console.log("matchingUser", matchingUser?.randomColor);

            if (matchingUser?.randomColor) {
                borderStyle = {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: `1px solid ${matchingUser.randomColor}`, // Apply border and color together
                };
            }
        }

        // Base styles if no border color is set
        const baseStyle = {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ...borderStyle, // Spread in any border styles
        };
        return (
            <div
                style={baseStyle}
                className={`h-full rounded-sm ${
                    eventInfo.event.extendedProps.is_organizer
                        ? "text-black"
                        : "border border-[#C3FF36] text-black"
                }`}
            >
                <span className="pl-2">
                    {/* {(
                        (eventInfo.event.title &&
                        eventInfo.event.title !== "null"
                            ? eventInfo.event.title
                            : eventInfo.event.extendedProps.description) ?? ""
                    ).length > 20
                        ? `${(eventInfo.event.title &&
                          eventInfo.event.title !== "null"
                              ? eventInfo.event.title
                              : eventInfo.event.extendedProps.description
                          ).substring(0, 15)}...`
                        : eventInfo.event.title &&
                          eventInfo.event.title !== "null"
                        ? eventInfo.event.title
                        : eventInfo.event.extendedProps.description} */}
                    {getEventTitle(eventInfo)}

                    {eventInfo.event.extendedProps.is_recurring && (
                        <FiRefreshCcw />
                    )}
                </span>
                {!eventInfo.event.extendedProps.is_visited &&
                    eventInfo.event.extendedProps.is_organizer &&
                    eventInfo.event.extendedProps.user.user_id ==
                        user.user_id && (
                        <FiTrash2
                            style={{ cursor: "pointer", color: "black" }}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering the event click
                                !isUpdating &&
                                    handleDeleteEvent(
                                        eventInfo.event.extendedProps
                                            .meeting_id,
                                        eventInfo.event.extendedProps
                                            .is_recurring,
                                        eventInfo.event.extendedProps
                                            .recurring_id,
                                        eventInfo.event.start
                                    ); // Call delete handler
                            }}
                        />
                    )}

                {eventInfo.event.extendedProps.client_alert &&
                    eventInfo.event.extendedProps.attendees?.includes(
                        user.user_id
                    ) && (
                        <div className="h-full w-1 bg-orange-400 absolute left-0 rounded-l-lg" />
                    )}
            </div>
        );
    };

    // based on meeting type, return the color of the event
    // const renderEventClassNames = (eventInfo) => {
    //     // Check if the mode is 'attendee' (you can modify this condition based on how you store the mode)
    //     const isAttendee = eventInfo.event.extendedProps.is_organizer === false; // Assuming 'attendee' mode is passed in extendedProps

    //     console.log("Event content", eventInfo);

    //     // if meeting has been done, return gray background color
    //     if (eventInfo.event.extendedProps.is_visited) {
    //         return isAttendee ? "bg-gray-200 text-black" : "bg-gray-200";
    //     }

    //     // Conditional styles for different event types
    //     let baseClass = "";
    //     switch (eventInfo.event.extendedProps.type) {
    //         case "1":
    //             baseClass = "bg-gold";
    //             break;
    //         case "2":
    //             baseClass = "bg-green-200";
    //             break;
    //         case "3":
    //             baseClass = "bg-red-200";
    //             break;
    //         default:
    //             baseClass = "bg-gold";
    //     }

    //     // If it's 'attendee' mode, invert the background and text colors
    //     if (isAttendee) {
    //         console.log("IM AND ATTENDEEEEE");
    //         return `bg-gray-100`; // Inverse the text color to white on dark background
    //     }

    //     return baseClass; // Normal background color when not in 'attendee' mode
    // };

    const renderEventClassNames = (eventInfo) => {
        const isAttendee = eventInfo.event.extendedProps.is_organizer === false;

        // If the meeting has been visited, return gray background color
        if (eventInfo.event.extendedProps.is_visited) {
            return isAttendee ? "bg-gray-200 text-black" : "bg-gray-200";
        }
        if (
            eventInfo.event.extendedProps.user &&
            eventInfo.event.extendedProps.user.user_id != user.user_id
        ) {
            return ` bg-gray-200`;
        }

        // Get the meeting type ID from extendedProps
        const meetingTypeId = eventInfo.event.extendedProps.type;

        // console.log("meetingTypes", meetingTypes);
        // console.log("meetingTypeId", meetingTypeId);

        // Check if meetingTypes is ready
        if (!Array.isArray(meetingTypes) || meetingTypes.length === 0) {
            // console.log("meetingTypes not ready");
            return "bg-rose-500"; // Default color when meetingTypes is not available
        }

        // Find the corresponding meeting type in meetingTypes array
        const meetingType = meetingTypes.find(
            (type) => String(type.meeting_type_id) === String(meetingTypeId)
        );

        // console.log("meetingType", meetingType);

        // Get the color associated with the meeting type, or use a default
        const baseClass = meetingType?.color || "bg-rose-500";

        // If it's 'attendee' mode, return a different background color
        if (isAttendee) {
            // console.log("I AM AN ATTENDEE");
            return `bg-gray-100`;
        }

        return baseClass; // Return the dynamic background color
    };

    const [successMessage, setSuccessMessage] = useState(""); // Track success message
    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => setSuccessMessage(""), 3000); // Hide message after 3 seconds
            return () => clearTimeout(timer); // Cleanup on unmount
        }
    }, [successMessage]);

    const [errorMessage, setErrorMessage] = useState(""); // Track success message
    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => setErrorMessage(""), 3000); // Hide message after 3 seconds
            return () => clearTimeout(timer); // Cleanup on unmount
        }
    }, [errorMessage]);

    return (
        meetingTypes && (
            <div className="flex flex-col w-full mt-10 relative">
                <div className="flex w-full justify-end items-center mb-5">
                    {" "}
                    <ToggleButton
                        isWeekly={viewMode === "weekly"}
                        onToggle={handleViewToggle}
                    />
                </div>
                {isUpdating && (
                    <div className="absolute top-1/2 left-1/2 z-10">
                        <Throbber />
                    </div>
                )}

                <div className="flex flex-row w-full relative">
                    {calendarLoading && (
                        <div className="absolute top-1/2 left-1/2 z-10">
                            <Throbber />
                        </div>
                    )}
                    {(user.user_role_id === "R-01" ||
                        user.user_role_id === "R-02") && (
                        <SharedCalendarList
                            currentUser={user}
                            activeCalendars={activeCalendars}
                            setActiveCalendars={setActiveCalendars}
                            originalMeetings={originalMeetings}
                            setOriginalMeetings={setOriginalMeetings}
                            setCalendarLoading={setCalendarLoading}
                            usersList={usersList}
                            setUsersList={setUsersList}
                            setUsersListOriginal={setUsersListOriginal}
                            calendarLoading={calendarLoading}
                            isOnline={isOnline}
                        />
                    )}
                    <div className="w-full">
                        <FullCalendar
                            locale={esLocale}
                            slotMinTime={"06:00:00"}
                            slotMaxTime={"23:00:00"}
                            plugins={[timeGridPlugin, interactionPlugin]}
                            initialView="timeGridWeek"
                            events={newEvents.map((event) => ({
                                ...event,
                                startEditable:
                                    event.attendees?.includes(user.user_id) &&
                                    event.is_organizer,
                                durationEditable: false,
                                resourceEditable: false,
                            }))}
                            editable={false}
                            eventStartEditable={(event) => {
                                return (
                                    event.extendedProps.attendees?.includes(
                                        user.user_id
                                    ) && event.extendedProps.is_organizer
                                );
                            }}
                            eventDurationEditable={false} // Disables event resizing
                            eventResizableFromStart={false}
                            eventDrop={handleEventDrop}
                            height={height}
                            dateClick={handleDateClick}
                            eventClick={handleEventClick}
                            eventContent={renderEventContent}
                            eventClassNames={(eventInfo) =>
                                `${renderEventClassNames(
                                    eventInfo
                                )} border border-gray-200 text-xxxs font-bold text-black uppercase`
                            }
                            allDaySlot={false} // Removes the "All Day" column
                            dayHeaderFormat={{
                                weekday: "short",
                                day: "numeric",
                            }} // Removes the month, showing only day name and date
                            dayHeaderContent={(arg) => {
                                const isToday =
                                    arg.date.toDateString() ===
                                    new Date().toDateString(); // Check if it's today
                                return (
                                    <div className="custom-day-header mb-3 font-light">
                                        {isToday ? (
                                            <>
                                                <div className="day text-sm mb-2">
                                                    {arg.text.split(" ")[0]}
                                                </div>
                                                <div className="bg-black rounded-full text-gold text-xl">
                                                    <span>
                                                        {arg.text.split(" ")[1]}{" "}
                                                    </span>
                                                    {/* Date inside the circle */}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="day text-sm mb-2">
                                                    {arg.text.split(" ")[0]}
                                                </div>
                                                <div className="date text-xl">
                                                    {arg.text.split(" ")[1]}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }}
                            slotDuration="00:30:00" // Display grid lines only every hour
                            titleFormat={(date) => {
                                // Extract the month and year from the date string (date.start.marker)
                                const dateStr = date.start.marker; // e.g., "Mon Jan 06 2025 01:00:00 GMT+0100"
                                // Create a new Date object from the string
                                const jsDate = new Date(dateStr);
                                // French month names
                                const frenchMonths = [
                                    "Janvier",
                                    "Février",
                                    "Mars",
                                    "Avril",
                                    "Mai",
                                    "Juin",
                                    "Juillet",
                                    "Août",
                                    "Septembre",
                                    "Octobre",
                                    "Novembre",
                                    "Décembre",
                                ];
                                const monthIndex = jsDate.getMonth(); // Get the month index (0-11)
                                const monthName = frenchMonths[monthIndex]; // Get the French month name
                                const year = jsDate.getFullYear(); // Get the year
                                return `${monthName} ${year}`; // Return formatted string: "Janvier 2025"
                            }}
                            headerToolbar={{
                                left: "today prev,next", // Move "Today", "Prev", and "Next" buttons to the left
                                center: "title", // Keep the title centered
                                right: "", // The view switch buttons on the right
                            }}
                        />
                    </div>
                </div>
                {isModalOpen && (
                    <MeetingModal
                        closeModal={closeModal}
                        initialEventData={newEventData}
                        locale={locale}
                        onCreateMeeting={addNewEvent}
                        onUpdateMeeting={updateExistingEvent}
                        onDeleteMeeting={deleteEvent}
                        user_id={user.user_id}
                        isEditing={isEditing}
                        meetings={meetings}
                        user={user}
                        setSuccessMessage={setSuccessMessage}
                        setErrorMessage={setErrorMessage}
                    />
                )}
                {isPopupOpen && (
                    <RecurringEventPopup
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                )}
                {successMessage && (
                    <div className="fixed bottom-0 left-0 right-0 bg-green-500 text-white text-center py-2 z-50 mx-auto w-1/5 rounded-t-lg">
                        {successMessage}
                    </div>
                )}
                {errorMessage && (
                    <div className="fixed bottom-0 left-0 right-0 bg-rose-500 text-white text-center py-2 z-50 mx-auto w-1/5 rounded-t-lg">
                        {errorMessage}
                    </div>
                )}
            </div>
        )
    );
};

export default CalendarModule;
