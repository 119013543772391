/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { UserType } from "../../types/user";
import { FiUsers } from "react-icons/fi";
// import { FormattedMessage } from "react-intl";
// import messages_en from "./translations/en.json";
// import messages_fr from "./translations/fr.json";

type UserListType = {
	user: UserType;
	randomColor: string;
};

interface UserSearchProps {
	usersList: UserListType[];
    setShownList: React.Dispatch<React.SetStateAction<UserListType[]>>;
    setUsersList: React.Dispatch<React.SetStateAction<UserListType[]>>;
    handleCalChange: (id: string) => void
}

const UserSearch: React.FC<UserSearchProps> = ({ usersList, setShownList, setUsersList, handleCalChange }) => {
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [filteredUsers, setFilteredUsers] = useState<UserListType[]>([]);
	const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

	useEffect(() => {
		if (searchTerm === "") {
			setFilteredUsers([]);
			setShowSuggestions(false);
		} else {
			setShowSuggestions(true);
		}
	}, [searchTerm]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
		setShowSuggestions(true);
	};

    const handleSelect = (user: UserListType) => {
			setShownList((prevList) => [...prevList, user]);
			setUsersList((prevList) =>
				prevList.filter((el) => el.user.user_id !== user.user.user_id)
			);
			handleCalChange(user.user.user_id);
			setShowSuggestions(false);
			setSearchTerm("");
		};

	useEffect(() => {
		if (searchTerm === "") {
			setFilteredUsers([]);
		} else {
			const filtered = usersList.filter(
				(el) =>
					el.user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
					el.user.user_id.toString().includes(searchTerm) // Convert product_id to string before searching
			);
			setFilteredUsers(filtered);
		}
	}, [searchTerm, usersList]);

	return (
        <div className="w-full relative">
        <input
            type="text"
            placeholder="Rechercher collègue..."
            value={searchTerm}
            onChange={(e) => handleChange(e)}
            className="w-full border border-gray-900 rounded-full py-2 pl-10 text-sm text-xs placeholder-black"
        />
        <div className="absolute left-3 top-1/2 -translate-y-1/2 text-sm black pl-3">
            <FiUsers/>
        </div>

			{showSuggestions && (
				<div className="absolute top-full w-full bg-white border border-slate-300 rounded-lg shadow-lg max-h-60 overflow-y-auto mt-2 z-10 text-left">
					{filteredUsers.length > 0 ? (
						filteredUsers.map((el) => (
							<div
								key={el.user.user_id}
								onMouseDown={(e) => {
									e.preventDefault();
									handleSelect(el);
								}}
								className="cursor-pointer px-3 py-2 hover:bg-slate-100 border-b border-gray-200"
							>
								<div className="flex flex-row items-end">
									<div className="text-base text-black w-2/3">
										{el.user.first_name}{" "}
									</div>
								</div>
							</div>
						))
					) : (
						<div className="px-3 py-2">Pas de résultat</div>
					)}
				</div>
			)}
		</div>
	);
};

export default UserSearch;
