import React, { useState } from "react";
import { FiChevronUp, FiChevronDown, FiTrash2 } from "react-icons/fi";

const DiscussedPromotions = ({
    field_name,
    field_id,
    handleInputChange,
    responses,
    options,
}) => {
    const [expandedOptions, setExpandedOptions] = useState({});
    const [selectedPromotions, setSelectedPromotions] = useState([]);

    const handleCheckboxChange = (optionValue, key) => {
        const newResponse = {
            ...responses[field_id],
            [optionValue]: {
                ...responses[field_id]?.[optionValue],
                [key]: !responses[field_id]?.[optionValue]?.[key],
            },
        };
        handleInputChange(field_id, newResponse);
    };

    const toggleExpandedOption = (optionLabel) => {
        setExpandedOptions((prevState) => ({
            ...prevState,
            [optionLabel]: !prevState[optionLabel],
        }));
    };

    const handlePromotionSelect = (e) => {
        const selectedPromotion = e.target.value;
        if (
            selectedPromotion &&
            !selectedPromotions.includes(selectedPromotion)
        ) {
            setSelectedPromotions([...selectedPromotions, selectedPromotion]);
        }
    };

    const handleRemovePromotion = (promotion) => {
        setSelectedPromotions(
            selectedPromotions.filter((item) => item !== promotion)
        );

        // Remove promotion from responses
        // Clear associated response data
        const currentResponse = responses[field_id] || {};
        const remainingResponse = { ...currentResponse };
        delete remainingResponse[promotion];
        handleInputChange(field_id, remainingResponse);
    };

    return (
        <div className="flex flex-col w-full">
            <div className="text-left w-full font-semibold mb-5">
                {field_name}
            </div>

            {/* Render Selected Promotions */}
            {selectedPromotions.map((promotion, index) => (
                <div
                    key={`${field_id}-${index}`}
                    className="flex flex-col md:flex-row gap-2 items-center w-full"
                >
                    <div className="flex justify-between items-center text-left w-full md:w-1/6">
                        <div>{promotion}</div>
                        <button
                            className="md:hidden flex items-center"
                            onClick={() => toggleExpandedOption(promotion)}
                        >
                            {expandedOptions[promotion] ? (
                                <FiChevronUp size={24} />
                            ) : (
                                <FiChevronDown size={24} />
                            )}
                        </button>
                        <button
                            onClick={() => handleRemovePromotion(promotion)}
                            className="text-red-500 ml-4 block md:hidden"
                        >
                            <FiTrash2 size={20} />
                        </button>
                    </div>

                    {(expandedOptions[promotion] ||
                        window.innerWidth >= 768) && (
                        <div className="flex flex-col md:flex-row gap-4 w-full">
                            <label className="flex items-center gap-2 w-full md:w-1/6">
                                <input
                                    type="checkbox"
                                    checked={
                                        responses[field_id]?.[promotion]
                                            ?.discussed || false
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(
                                            promotion,
                                            "discussed"
                                        )
                                    }
                                />
                                Discutée
                            </label>

                            <label className="flex items-center gap-2 w-full md:w-1/6">
                                <input
                                    type="checkbox"
                                    checked={
                                        responses[field_id]?.[promotion]
                                            ?.interest_expressed || false
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(
                                            promotion,
                                            "interest_expressed"
                                        )
                                    }
                                />
                                Intérêt manifesté
                            </label>
                            <button
                                onClick={() => handleRemovePromotion(promotion)}
                                className="text-red-500 ml-4 hidden md:block"
                            >
                                <FiTrash2 size={20} />
                            </button>
                        </div>
                    )}
                </div>
            ))}

            {/* Promotion Selection */}
            <div className="flex flex-col gap-4 items-center w-full md:w-1/2 max-w-full mt-5 ">
                <select
                    value=""
                    onChange={handlePromotionSelect}
                    className="p-2 border border-gray-300 rounded-md w-full max-w-full"
                >
                    <option value="">Sélectionnez une promotion</option>
                    {options
                        .filter(
                            (option) =>
                                !selectedPromotions.includes(option.label)
                        )
                        .map((option, index) => (
                            <option key={index} value={option.label}>
                                {option.label}
                            </option>
                        ))}
                </select>
            </div>
        </div>
    );
};

export default DiscussedPromotions;
