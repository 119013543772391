import React, { useState, useEffect } from "react";
import FastAPIClient from "../../client";
import fast_api_config from "../../config";
import UserSearch from "../Search/UserSearch";
import { MeetingType } from "../../types/meeting";
import { UserType } from "../../types/user";
import { FiWifiOff } from "react-icons/fi";

// Instantiate the FastAPI client
const client = new FastAPIClient(fast_api_config);

type UserListType = {
	user: UserType;
	randomColor: string;
};

interface SharedCalendarProps {
	currentUser: UserType;
	activeCalendars: string[];
	setActiveCalendars: React.Dispatch<React.SetStateAction<string[]>>;
	originalMeetings: MeetingType[];
	setOriginalMeetings: React.Dispatch<React.SetStateAction<MeetingType[]>>;
	setCalendarLoading: React.Dispatch<React.SetStateAction<boolean>>;
	usersList: UserListType[];
	setUsersList: React.Dispatch<React.SetStateAction<UserListType[]>>;
	setUsersListOriginal: React.Dispatch<React.SetStateAction<UserListType[]>>;
	calendarLoading: boolean,
	isOnline: boolean
}



const SharedCalendarList: React.FC<SharedCalendarProps> = ({
	currentUser,
	activeCalendars,
	setActiveCalendars,
	setOriginalMeetings,
	setCalendarLoading,
	usersList,
	setUsersList,
	setUsersListOriginal,
	calendarLoading,
	isOnline
}) => {
	const [shownList, setShownList] = useState<UserListType[]>([])
	const [sharedMeetings, setSharedMeetings] = useState([]);

	useEffect(() => {
		if (activeCalendars.length === 0) {
			setActiveCalendars([currentUser.user_id]); // Only initialize if empty
		}
	}, []);


	const handleChange = (id: string) => {
		if (!activeCalendars.includes(id)) {
			setActiveCalendars((prevData) => [...prevData, id]);
		} else {
			setActiveCalendars((prevData) => prevData.filter((cal) => cal !== id));
		}
	};


	const reinitialize = () => {
		setActiveCalendars([currentUser.user_id])
		setShownList([])
	}
	const getMeetings = async (id: string) => {
		const data = await client.getMeetings(id);
		setSharedMeetings((prevMeetings) => [...prevMeetings, data.data?.results]);
	};



	const handleRemoveUser = (user: UserListType) => {
		setUsersList(prevList => [...prevList, user])
		const filterShownList = [...shownList].filter((el) => el.user.user_id != user.user.user_id)
		setShownList(filterShownList)
		handleChange(user.user.user_id)
	}

	const getUsers = async () => {
		const array = [];
		client.getUsers().then((data) => {
			if (currentUser.user_role_id === "R-02") {
				const allUsers = data?.results.filter(
					(user) =>
						user.user_id !== currentUser.user_id &&
						user.is_active === true &&
						user.warehouse_id === currentUser.warehouse_id
				);
				allUsers.forEach((user) => {
					const randomColor = `hsl(${Math.random() * 360}, 70%, 50%)`;
					array.push({ user, randomColor });
				});
				setUsersList(array);
				setUsersListOriginal(array);

			} else if (currentUser.user_role_id === "R-01") {
				const allUsers = data?.results.filter(
					(user) =>
						user.user_id !== currentUser.user_id && user.is_active === true
				);
				allUsers.forEach((user) => {
					const randomColor =
						"#" + Math.floor(Math.random() * 16777215).toString(16);
					array.push({ user, randomColor });
				});
				setUsersList(array);
				setUsersListOriginal(array);
			}
		});
	};

	useEffect(() => {
		getUsers();
	}, []);

	useEffect(()=> {
		console.log("USERS", usersList);
	},[usersList])

	useEffect(() => {
		setSharedMeetings([]);
		setCalendarLoading(true);
		activeCalendars.forEach((cal) => {
			getMeetings(cal);
		});
	}, [activeCalendars]);

	useEffect(() => {
		console.log("shared meetings", sharedMeetings);
		const copy = [];

		sharedMeetings.forEach((arr) => {
			arr.forEach((el) => {
				copy.push(el);
			});
		});
		console.log("copy", copy);
		setOriginalMeetings(copy);
		setCalendarLoading(false);
	}, [sharedMeetings]);
	return (
		<div className="w-1/6 pt-10 px-1">
			<h3 className="font-bold text-left pl-2 pb-1">Calendriers</h3>

			{!isOnline && (
				<div className="flex flex-col gap-2 h-full items-center mt-20 px-5 text-center">
					<div className="text-xxl text-gray-400">
						<FiWifiOff />
					</div>
					<div className="text-sm text-gray-500">Cette fonctionnalité n&apos;est pas disponible hors-ligne.</div>
					<button
				className={`flex text-center items-center justify-center w-full italic underline text-gray-700 text-sm pb-10 ${
					activeCalendars.length === 1 &&
					activeCalendars.includes(currentUser.user_id) &&
					"hidden"
				}`}
				onClick={reinitialize}
			>
<span className="w-11/12">Effacer tous les calendriers</span></button>
				</div>
			)}

			{isOnline && (
				<>
				<div className="w-full pr-2">
					<UserSearch usersList={usersList} setUsersList={setUsersList} setShownList={setShownList} handleCalChange={handleChange}/>
				</div>
				<ul className="text-left flex flex-col gap-2 text-sm pt-5 overflow-hidden overflow-y-auto overflow-y-scroll h-5/6 w-full pb-10 pr-2">
					<div
						className={`border border-2 rounded-full py-1 px-2 disabled:text-gray-200 disabled:border-gray-200 flex justify-between items-center ${
							activeCalendars.includes(currentUser.user_id) &&
							"border-gold font-semibold"
						}`}
						id={currentUser.user_id}
						style={{
							borderColor:
								activeCalendars.includes(currentUser.user_id) && "#C3FF35",
						}}
					>
						<span>Mon calendrier</span>
						<input type="checkbox" checked={activeCalendars.includes(currentUser.user_id) } className={`px-2 py-1 hover:bg-gray-100 rounded-full text-xs`} onChange={() => handleChange(currentUser.user_id)} disabled={calendarLoading}/>
					</div>
					{shownList?.map((el) => {
						return (
							<div
								key={el.user.user_id}
								className={`border border-2 rounded-full py-1 px-2 disabled:text-gray-200 disabled:border-gray-200 flex justify-between items-center`}
								style={{
									borderColor:
										activeCalendars.includes(el.user.user_id) && el.randomColor,
								}}
								id={el.user.user_id}

							>
								<span>{el.user.first_name}</span>
								<button className=" px-2 py-1 hover:bg-gray-100 rounded-full text-xs" onClick={()=>handleRemoveUser(el)} disabled={calendarLoading}>x</button>
							</div>
						);
					})}
				</ul>
				</>
			)}
			<button
				className={`flex text-center italic underline text-gray-700 text-sm pr-10 pb-10 ${
					activeCalendars.length === 1 &&
					activeCalendars.includes(currentUser.user_id) &&
					"hidden"
				}`}
				onClick={reinitialize}
			>
Effacer tous les calendriers			</button>
		</div>
	);
};

export default SharedCalendarList;
