import React from "react";

const Negociation = ({
    field_id, // Field id
    handleInputChange,
    responses,
    options, // Options to render rows
}) => {
    const handleCheckboxChange = (optionValue, key) => {
        const currentResponse = responses[field_id] || {};
        const updatedOptionResponse = {
            ...currentResponse[optionValue],
            [key]: !currentResponse[optionValue]?.[key],
        };

        const newResponse = {
            ...currentResponse,
            [optionValue]: updatedOptionResponse,
        };

        handleInputChange(field_id, newResponse);
    };

    const handleTextChange = (optionValue, key, value) => {
        const currentResponse = responses[field_id] || {};
        const updatedOptionResponse = {
            ...currentResponse[optionValue],
            [key]: value,
        };

        const newResponse = {
            ...currentResponse,
            [optionValue]: updatedOptionResponse,
        };

        handleInputChange(field_id, newResponse);
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="text-left w-full font-semibold">
                Catégories de produits discutées
            </div>

            {options.map((option, index) => (
                <div
                    key={`${field_id}-${index}`} // Unique key for each row
                    className="flex flex-col md:flex-row gap-4 p-4 items-center"
                >
                    {/* Option Name */}
                    <div className="w-full md:w-1/6 text-left">
                        {option.label} {/* Render the label for the option */}
                    </div>

                    {/* Discutée Checkbox */}
                    <label className="flex items-center gap-2 w-full md:w-1/6">
                        <input
                            type="checkbox"
                            checked={
                                responses[field_id]?.[option.label]
                                    ?.discussed || false
                            }
                            onChange={() =>
                                handleCheckboxChange(option.label, "discussed")
                            }
                        />
                        Négociation
                    </label>

                    {/* Commentaires Text Input */}
                    <input
                        type="text"
                        value={
                            responses[field_id]?.[option.label]?.comments || ""
                        }
                        onChange={(e) =>
                            handleTextChange(
                                option.label,
                                "comments",
                                e.target.value
                            )
                        }
                        placeholder="Commentaires"
                        className="border border-gray-400 rounded-md p-2 w-full md:w-1/6"
                    />
                </div>
            ))}
        </div>
    );
};

export default Negociation