import React, { useEffect, useState } from "react";
import FastAPIClient from "../../client";
import config from "../../config";
import ProductDetails from "../../components/LayoutComponents/ProductDetails";
import ProductModal from "../../components/ModalComponents/ProductModal";
import Loader from "../../components/Loader";
import "./style.css";

import { filterProducts } from "./functions/filters";
import filterMappings from "./translations/filterMappings.json"

const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

import ProductSearch from "../../components/Search/ProductSearch";
import {
    FiChevronDown,
    FiFilter,
    FiPlus,
    FiXCircle,
    FiMinus,
} from "react-icons/fi";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const Products = () => {
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false); // Track modal visibility
    const [selectedProduct, setSelectedProduct] = useState(null); // Track the selected product
    const [locale, setLocale] = useState();
    const [refreshing, setRefreshing] = useState(true);


	const handleSearchClick = (client) => {
		// Set the selected product and show the modal
		setSelectedProduct(client);
		setShowModal(true);
	};

    const [selectedFilters, setSelectedFilters] = useState({
        beer_family: [],
        beer_color: [],
        beer_style: [],
        beer_flavor: [],
        beer_region: [],
        beer_head_type: [],
        beer_bitterness: [],
        gluten_free_beer: [],
        wine_color: [],
        wine_vineyard: [],
        wine_denomination: [],
        wine_appellation: [],
        wine_millesime: [],
        wine_sugar_content: [],
        wine_grape: [],
        spirit_family: [],
        spirit_type: [],
        spirit_millesime: [],
        spirit_age: [],
        spirit_region: [],
        water_family: [],
        soft_family: [],
        juice_family: [],
        sirop_family: [],
        flavour: [],
        coffee_type: [],
        decaf_coffee: [],
        coffee_pod_type: [],
        coffee_roasting_region: [],
        coffee_roast: [],
        coffee_variety: [],
        coffee_ingredients: [],
        coffee_intensity: [],
        coffee_label: [],
        tea_type: [],
        tea_color: [],
        tea_flavor: [],
        tea_label: [],
        hot_beverage_type: [],
        grocery_type: [],
        material_type: [],
        hygiene_type: [],
        cider_type: [],
        cider_head_type: [],
        cider_flavor: [],
        cider_region: [],
        container_format: [],
        manufacturer_brand: [],
        deposit: [],
        country: [],
        product_level_1: [], // <-- Add this line
    });
    // const [expandedLevels, setExpandedLevels] = useState({});

    useEffect(() => {
        const getProducts = async () => {
            client.getAllProducts().then((data) => {
                setProducts(data?.results);
                setLocale(localStorage.getItem("language"));
            });
        };
        getProducts();
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            setRefreshing(false);
        }
    }, [products]);

    const productLevels = Array.from(
        new Set(products.map((prod) => prod.product_level_1))
    );

    // Extract unique values for beer_color, beer_family, etc.
    const extractUniqueValuesWithCount = (property) => {
        // Step 1: Get all unique values from the full product list
        const allUniqueValues = Array.from(
            new Set(products.map((prod) => prod[property]))
        ).filter(Boolean);

        // Step 2: Calculate counts from the filtered product list
        const valueCounts = {};
        filteredProducts.forEach((prod) => {
            const value = prod[property];
            if (value) {
                valueCounts[value] = (valueCounts[value] || 0) + 1;
            }
        });

        // Step 3: Return all options with counts, defaulting to 0 for options not in filteredProducts
        return allUniqueValues.map((value) => [value, valueCounts[value] || 0]);
    };

    // Handle the filter change
    const handleFilterChange = (filterName, value) => {
        setSelectedFilters((prevFilters) => {
            const newFilters = { ...prevFilters };
            const currentFilterValues = newFilters[filterName] || [];
            if (currentFilterValues.includes(value)) {
                newFilters[filterName] = currentFilterValues.filter(
                    (val) => val !== value
                );
            } else {
                newFilters[filterName] = [...currentFilterValues, value];
            }
            return newFilters;
        });
    };

    // Filter products based on the selected filters
    const filteredProducts = filterProducts(products, selectedFilters)



    const [expandedLevels, setExpandedLevels] = useState({
        beer: false,
        wine: false,
        wine_color: false,
        wine_vineyard: false,
        wine_denomination: false,
        wine_appellation: false,
        wine_millesime: false,
        wine_sugar_content: false,
        wine_grape: false,
        spirit_family: false,
        spirit_type: false,
        spirit_millesime: false,
        spirit_age: false,
        spirit_region: false,
        water_family: false,
        soft_family: false,
        juice_family: false,
        sirop_family: false,
        flavour: false,
        coffee_type: false,
        decaf_coffee: false,
        coffee_pod_type: false,
        coffee_roasting_region: false,
        coffee_roast: false,
        coffee_variety: false,
        coffee_ingredients: false,
        coffee_intensity: false,
        coffee_label: false,
        tea_type: false,
        tea_color: false,
        tea_flavor: false,
        tea_label: false,
        hot_beverage_type: false,
        grocery_type: false,
        material_type: false,
        hygiene_type: false,
        container_format: false,
        manufacturer_brand: false,
        deposit: false,
        country: false,
    });

    const toggleLevel = (level) => {
        setExpandedLevels((prev) => ({
            ...prev,
            [level]: !prev[level],
        }));
    };

    // Helper function to get the filter name in the selected language
    const getFilterName = (filterKey) => {
        return filterMappings[filterKey]
            ? filterMappings[filterKey][locale]
            : filterKey;
    };

    // State to control filter visibility
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const renderGeneralFilters = () => {
        const generalFilters = [
            "container_format",
            "manufacturer_brand",
            "deposit",
            "country",
        ];

        return generalFilters.map((filterKey) => (
            <div key={filterKey} className="mb-4">
                <button
                    className="font-semibold mb-2 flex items-center"
                    onClick={() => toggleLevel(filterKey)}
                >
                    {getFilterName(filterKey)}{" "}
                    {expandedLevels[filterKey] ? <FiMinus /> : <FiPlus />}
                </button>
                {expandedLevels[filterKey] && (
                    <div className="ml-4">
                        {extractUniqueValuesWithCount(filterKey)
                            .sort((a, b) => b[1] - a[1])
                            .map(([value, count]) => (
                                <div
                                    key={value}
                                    className="flex items-center mb-1"
                                >
                                    <input
                                        type="checkbox"
                                        id={`${filterKey}-${value}`}
                                        checked={selectedFilters[
                                            filterKey
                                        ]?.includes(value)}
                                        onChange={() =>
                                            handleFilterChange(filterKey, value)
                                        }
                                    />
                                    <label
                                        htmlFor={`${filterKey}-${value}`}
                                        className="ml-2 text-sm"
                                    >
                                        {value} ({count})
                                    </label>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        ));
    };

    if (refreshing) {
        return <Loader />;
    }

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className="flex flex-row h-screen overflow-y-auto">
                <div className="w-full md:ml-20 xl:w-2/3 md:w-2/3 sm:w-2/3 px-15 text-left">
                    <div className="flex flex-col">
                        <div className="flex flex-col md:flex-row ml-5 md:mt-20">
                            <div>
                                <h1 className="md:mb-12 text-xl text-black mt-5 ">
                                    <FormattedMessage
                                        id="product.product_catalogue"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                            </div>
                            <ProductSearch clients={products} handleClick={handleSearchClick}  selectedProduct={selectedProduct} />
                        </div>

                        <div className="mb-5 relative mt-5">
                            <div className="container px-5 text-left gray-50 items-top justify-center grid xl:grid-cols-3 grid-cols-2 gap-4">
                                {filteredProducts.length &&
                                    filteredProducts.map((prod) => (
                                        <ProductDetails
                                            product={prod}
                                            key={prod.product_id}
                                            locale={locale}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                    {/* Modal */}
            {showModal && selectedProduct && (
                <ProductModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    product={selectedProduct}
                    setProduct={setSelectedProduct}
                />
            )}

                <div className="md:hidden">
                    <button
                        className="fixed bottom-[100px] right-10 transform  bg-black text-white p-4 rounded-full shadow-lg z-50"
                        onClick={() => setIsFilterOpen(!isFilterOpen)}
                    >
                        {isFilterOpen ? <FiXCircle /> : <FiFilter />}
                    </button>
                </div>

                <div
                    className={`w-full bg-white md:w-1/5 sm:w-full pl-0 md:pl-20 ${
                        isFilterOpen ? "block" : "hidden"
                    } md:block fixed md:static top-0 left-0 z-10 pl-10 `}
                >
                    <div className="flex flex-col float-left mt-40 text-left max-h-[calc(100vh-200px)] overflow-y-auto pb-20 w-full">
                        <span className="font-bold mt-30">
                            {filteredProducts.length}{" "}
                            <FormattedMessage
                                id="product.products"
                                values={{ number: 1 }}
                            />
                        </span>
                        <div className="text-left mt-2">
                            <button
                                className="text-left flex items-center font-semibold mb-4" // Use flexbox for row display
                                onClick={() => toggleLevel("products")}
                            >
                                Catégories produits
                                {expandedLevels["products"] ? (
                                    <FiMinus />
                                ) : (
                                    <FiPlus />
                                )}
                            </button>

                            {expandedLevels["products"] &&
                                productLevels.map((level1) => {
                                    // Calculate the number of products for this category (level1)
                                    const totalProductsInLevel =
                                        products.filter(
                                            (prod) =>
                                                prod.product_level_1 === level1
                                        ).length;
                                    return (
                                        <div
                                            key={level1}
                                            className="text-sm mb-3 text-left"
                                        >
                                            {/* <button
                                            className="text-sm text-left flex items-center" // Use flexbox for row display
                                            onClick={() => toggleLevel(level1)}
                                        >
                                            {level1}{" "}
                                            <span className="ml-1">
                                                ({totalProductsInLevel})
                                            </span>{" "}
                                            <FiChevronDown />
                                        </button> */}
                                            <div
                                                key={level1}
                                                className="text-sm mb-3 text-left flex flex-row"
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={`product_level_1-${level1}`}
                                                    checked={selectedFilters.product_level_1.includes(
                                                        level1
                                                    )}
                                                    onChange={() =>
                                                        handleFilterChange(
                                                            "product_level_1",
                                                            level1
                                                        )
                                                    }
                                                />
                                                <label
                                                    htmlFor={`product_level_1-${level1}`}
                                                    className="ml-2"
                                                >
                                                    {level1} (
                                                    {totalProductsInLevel})
                                                </label>
                                                <button
                                                    className="text-sm text-left flex items-center ml-5" // Use flexbox for row display
                                                    onClick={() =>
                                                        toggleLevel(level1)
                                                    }
                                                >
                                                    {expandedLevels[level1] ? (
                                                        <FiMinus />
                                                    ) : (
                                                        <FiPlus />
                                                    )}
                                                </button>
                                            </div>
                                            {/* Display sublevels for each product level */}
                                            {expandedLevels[level1] && (
                                                <div className="ml-4">
                                                    {level1 === "BIERES" && (
                                                        <>
                                                            {/* Display Beer-related filters */}
                                                            {[
                                                                "beer_color",
                                                                "beer_family",
                                                                "beer_style",
                                                                "beer_flavor",
                                                                "beer_region",
                                                                "beer_head_type",
                                                                "beer_bitterness",
                                                                "gluten_free_beer",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}{" "}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "VINS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "wine_color",
                                                                "wine_vineyard",
                                                                "wine_denomination",
                                                                "wine_appellation",
                                                                "wine_millesime",
                                                                "wine_sugar_content",
                                                                "wine_grape",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "CHOCOLATS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "hot_beverage_type",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "CIDRES" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "cider_type",
                                                                "cider_head_type",
                                                                "cider_flavor",
                                                                "cider_region",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "CIDERS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "cider_type",
                                                                "cider_head_type",
                                                                "cider_flavor",
                                                                "cider_region",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "SOFT DRINKS" && (
                                                        <>
                                                            {/* Display Beer-related filters */}
                                                            {[
                                                                "soft_family",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}{" "}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}

                                                    {level1 ===
                                                        "SIROP ET CONCENTRES" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "sirop_family",
                                                                "flavour",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "LAITS ET SAUCES" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "grocery_type",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "SUCRES ET ACCOMPAGNEMENTS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "grocery_type",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "SPIRITUEUX" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "spirit_family",
                                                                "spirit_type",
                                                                "spirit_millesime",
                                                                "spirit_age",
                                                                "spirit_region",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "HYGIENES" && (
                                                        <>
                                                            {/* Display Beer-related filters */}
                                                            {[
                                                                "hygiene_type",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}{" "}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}

                                                    {level1 === "SNACKING" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "wine_color",
                                                                "wine_vineyard",
                                                                "wine_denomination",
                                                                "wine_appellation",
                                                                "wine_millesime",
                                                                "wine_sugar_content",
                                                                "wine_grape",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "JUS DE FRUITS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "juice_family",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "ASSAISONNEMENTS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "wine_color",
                                                                "wine_vineyard",
                                                                "wine_denomination",
                                                                "wine_appellation",
                                                                "wine_millesime",
                                                                "wine_sugar_content",
                                                                "wine_grape",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "MATERIELS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "material_type",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "THES ET INFUSIONS" && (
                                                        <>
                                                            {/* Display Beer-related filters */}
                                                            {[
                                                                "tea_type",
                                                                "tea_color",
                                                                "tea_flavor",
                                                                "tea_label",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}{" "}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}

                                                    {level1 === "EAUX" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "water_family",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "CAFES" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "coffee_type",
                                                                "decaf_coffee",
                                                                "coffee_pod_type",
                                                                "coffee_roasting_region",
                                                                "coffee_roast",
                                                                "coffee_variety",
                                                                "coffee_ingredients",
                                                                "coffee_intensity",
                                                                "coffee_label",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                        <div>{renderGeneralFilters()}</div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};

export default Products;
