import React, { useState, useEffect } from "react";

import FastAPIClient from "../../../client";
import config from "../../../config";

const client = new FastAPIClient(config);

import { FiDownload, FiTrash2 } from "react-icons/fi";

import * as XLSX from "xlsx";

/*eslint-disable*/

const CoverageTable = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [columns, setColumns] = useState([]); // All available columns to be displayed
    const [filterValues, setFilterValues] = useState({});
    const [sortColumn, setSortColumn] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");
    // Get the current month and year in the required format
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // No padStart for two digits
    const currentYearMonth = `${currentYear}_${currentMonth}`;

    const [selectedColumns, setSelectedColumns] = useState([currentYearMonth]); // Set the current month and year as default

    const [isDropdownVisible, setIsDropdownVisible] = useState(false); // For dropdown visibility
    const [visibleRows, setVisibleRows] = useState(25); // For controlling the number of rows shown

    const [user, setUser] = useState(false);

    const getUserName = () => {
        client.fetchUser().then((data) => {
            setUser(data);
        });
    };

    useEffect(() => {
        getUserName();
    }, []);

    const handleExport = () => {
        const tableData = filteredData.map((row) => {
            const newRow = {
                client_id: row.client_id,
                nom_client: row.client_name,
                nom_representant: row.sales_rep_name,
                depot: row.warehouse_name,
                ville: row.city,
                frequence_visite: row.visit_frequency,
            };

            selectedColumns.forEach((col) => {
                newRow[col] = row[col];
            });

            return newRow;
        });

        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Couverture");

        // Get today's date and time in the format YYYY-MM-DD_HH-MM-SS
        const now = new Date();
        const formattedDate = now
            .toISOString()
            .slice(0, 19)
            .replace(/[-T:]/g, "_");

        // Append the formatted date/time to the file name
        const fileName = `couverture_client_${formattedDate}.xlsx`;

        XLSX.writeFile(wb, fileName);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!user?.user_id) return; // Prevent fetching if user_id is null or undefined

            const data = await client.getClientCoverage(
                user.warehouse_id,
                user.user_role_id === "R-03" ? user.user_id : null
            );
            setData(data.results);
            setFilteredData(data.results);

            if (data.results && data.results.length > 0) {
                const initialColumns = Object.keys(data.results[0]).filter(
                    (key) =>
                        key !== "client_id" &&
                        key !== "client_name" &&
                        key !== "city" &&
                        key !== "sales_rep_name" &&
                        key !== "visit_frequency"
                );
                setColumns(initialColumns); // All available columns
            }
        };
        fetchData();
    }, [user]);

    const handleFilterChange = (column, value) => {
        const updatedFilterValues = {
            ...filterValues,
            [column]: value,
        };

        setFilterValues(updatedFilterValues);

        // Filter the data after setting filter values
        const filtered = data.filter((row) => {
            return Object.entries(updatedFilterValues).every(([col, val]) => {
                if (
                    col === "client_name" ||
                    col === "city" ||
                    col === "client_id" ||
                    col === "warehouse_name" ||
                    col === "sales_rep_name" ||
                    col === "visit_frequency"
                ) {
                    // String-based filtering for non-numeric columns
                    return row[col]
                        ?.toString()
                        .toLowerCase()
                        .includes(val.toLowerCase());
                }

                if (val === "showAll") {
                    return true; // Show all (no filtering on visit_frequency)
                }
                if (val === "showZero") {
                    return row[col] === 0; // Show rows where visit_frequency is 0
                }
                // if (val === "showNonZero") {
                //     return row[col] !== 0; // Show rows where visit_frequency is non-zero
                // }
                if (val === "showOne") {
                    return row[col] == 1; // Show rows where visit_frequency is 1
                }
                if (val === "showTwo") {
                    return row[col] === 2; // Show rows where visit_frequency is 2
                }
                if (val === "showThree") {
                    return row[col] === 3; // Show rows where visit_frequency is 3
                }
                if (val === "showFour") {
                    return row[col] === 4; // Show rows where visit_frequency is 4
                }

                return true; // Default to showing all if no specific case matches
            });
        });

        console.log("filtered", filtered);
        // Update the filteredData state
        setFilteredData(filtered);
    };

    useEffect(() => {
        console.log(filterValues);
        const filtered = data.filter((row) => {
            return Object.entries(filterValues).every(([col, val]) => {
                if (
                    col === "client_name" ||
                    col === "city" ||
                    col === "client_id" ||
                    col === "warehouse_name" ||
                    col === "sales_rep_name" ||
                    col === "visit_frequency"
                ) {
                    return row[col]
                        ?.toString()
                        .toLowerCase()
                        .includes(val.toLowerCase());
                }

                if (val === "showAll") {
                    return true; // Show all (no filtering on visit_frequency)
                }
                if (val === "showZero") {
                    return row[col] === 0; // Show rows where visit_frequency is 0
                }
                // if (val === "showNonZero") {
                //     return row[col] !== 0; // Show rows where visit_frequency is non-zero
                // }
                if (val === "showOne") {
                    return row[col] == 1; // Show rows where visit_frequency is 1
                }
                if (val === "showTwo") {
                    return row[col] === 2; // Show rows where visit_frequency is 2
                }
                if (val === "showThree") {
                    return row[col] === 3; // Show rows where visit_frequency is 3
                }
                if (val === "showFour") {
                    return row[col] === 4; // Show rows where visit_frequency is 4
                }

                return true;
            });
        });

        setFilteredData(filtered);
    }, [filterValues]);

    const handleSort = (column) => {
        const newDirection =
            sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
        setSortColumn(column);
        setSortDirection(newDirection);

        const sortedData = [...filteredData].sort((a, b) => {
            if (newDirection === "asc") {
                return a[column] > b[column] ? 1 : -1;
            } else {
                return a[column] < b[column] ? 1 : -1;
            }
        });
        setFilteredData(sortedData);
    };

    // Handle column addition from dropdown
    const handleAddColumn = (column) => {
        setSelectedColumns((prevSelectedColumns) => [
            ...prevSelectedColumns,
            column,
        ]);
        setIsDropdownVisible(false); // Close dropdown after selection
    };

    // Handle column removal
    const handleRemoveColumn = (column) => {
        setSelectedColumns((prevSelectedColumns) =>
            prevSelectedColumns.filter((col) => col !== column)
        );
        // Remove the associated filter value if it exists
        setFilterValues((prevFilterValues) => {
            const { [column]: _, ...remainingFilters } = prevFilterValues;
            return remainingFilters;
        });
    };

    // Function to load more rows
    const loadMoreRows = () => {
        setVisibleRows((prevVisibleRows) => prevVisibleRows + 50);
    };

    // Inside your component
    useEffect(() => {
        console.log("Filtered Data: ", filteredData);
    }, [filteredData]);

    const getThresholdValue = (visitFrequency) => {
        switch (visitFrequency) {
            case "1":
                return 1;
            case "2":
                return 2;
            case "3":
                return 3;
            case "4":
                return 4;
            default:
                return 0; // Fallback if frequency is unexpected
        }
    };

    return (
        <div>
            <div className="flex justify-end mb-4">
                <button
                    onClick={handleExport}
                    className="bg-black text-white py-2 px-4 rounded"
                >
                    <FiDownload />
                </button>
            </div>

            <div className="overflow-x-auto">
                <table className="text-left min-w-full text-xs">
                    <thead>
                        <tr className="h-32">
                            <th className="border-b px-2 py-1 w-24 sticky left-0 bg-white z-10">
                                <div className="flex flex-col items-center space-x-2 text-left">
                                    <span
                                        onClick={() =>
                                            handleSort("client_name")
                                        }
                                        className="cursor-pointer w-full"
                                    >
                                        Nom client{" "}
                                        {sortColumn === "client_name" &&
                                            (sortDirection === "asc"
                                                ? "↑"
                                                : "↓")}
                                    </span>

                                    <div className="mt-2 w-full">
                                        <input
                                            className="text-xs border px-2 py-1 rounded-md"
                                            type="text"
                                            placeholder="Recherche client"
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "client_name",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </th>
                            <th className="border-b px-2 py-1 w-24 sticky left-48 bg-white z-10">
                                <div className="flex flex-col text-left items-center space-x-2">
                                    <span
                                        onClick={() => handleSort("client_id")}
                                        className="cursor-pointer w-full"
                                    >
                                        ID client{" "}
                                        {sortColumn === "client_id" &&
                                            (sortDirection === "asc"
                                                ? "↑"
                                                : "↓")}
                                    </span>

                                    <div className="mt-2 w-full">
                                        <input
                                            className="text-xs border px-2 py-1 rounded-md"
                                            type="text"
                                            placeholder="Recherche ID"
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "client_id",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </th>
                            <th className="border-b px-2 py-1 w-24 sticky left-24 bg-white z-10">
                                <div className="flex flex-col text-left items-center space-x-2">
                                    <span
                                        onClick={() => handleSort("city")}
                                        className="cursor-pointer w-full"
                                    >
                                        Ville{" "}
                                        {sortColumn === "city" &&
                                            (sortDirection === "asc"
                                                ? "↑"
                                                : "↓")}
                                    </span>

                                    <div className="mt-2 w-full">
                                        <input
                                            className="text-xs border px-2 py-1 rounded-md"
                                            type="text"
                                            placeholder="Recherche ville"
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "city",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </th>
                            <th className="border-b px-2 py-1 w-24 sticky left-24 bg-white z-10">
                                <div className="flex flex-col text-left items-center space-x-2">
                                    <span
                                        onClick={() =>
                                            handleSort("warehouse_name")
                                        }
                                        className="cursor-pointer w-full"
                                    >
                                        Dépôt{" "}
                                        {sortColumn === "warehouse_name" &&
                                            (sortDirection === "asc"
                                                ? "↑"
                                                : "↓")}
                                    </span>

                                    <div className="mt-2 w-full">
                                        <input
                                            className="text-xs border px-2 py-1 rounded-md"
                                            type="text"
                                            placeholder="Recherche dépôt"
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "warehouse_name",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </th>

                            <th className="border-b px-2 py-1 w-24 sticky left-24 bg-white z-10">
                                <div className="flex flex-col text-left items-center space-x-2">
                                    <span
                                        onClick={() =>
                                            handleSort("sales_rep_name")
                                        }
                                        className="cursor-pointer w-full"
                                    >
                                        Commercial{" "}
                                        {sortColumn === "sales_rep_name" &&
                                            (sortDirection === "asc"
                                                ? "↑"
                                                : "↓")}
                                    </span>

                                    <div className="mt-2 w-full">
                                        <input
                                            className="text-xs border px-2 py-1 rounded-md"
                                            type="text"
                                            placeholder="Recherche commercial"
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "sales_rep_name",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </th>

                            <th className="border-b px-2 py-1 w-24 sticky left-24 bg-white z-10">
                                <div className="flex flex-col text-left items-center space-x-2">
                                    <span
                                        onClick={() =>
                                            handleSort("visit_frequency")
                                        }
                                        className="cursor-pointer w-full"
                                    >
                                        Fréquence de visite mensuelle cible{" "}
                                        {sortColumn === "visit_frequency" &&
                                            (sortDirection === "asc"
                                                ? "↑"
                                                : "↓")}
                                    </span>

                                    <div className="mt-2 w-full">
                                        <input
                                            className="text-xs border px-2 py-1 rounded-md"
                                            type="text"
                                            placeholder="Recherche fréquence"
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "visit_frequency",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </th>

                            {/* Monthly Visits Header */}
                            {/* <th
                                colSpan={selectedColumns.length}
                                className="border-b px-2 py-1 text-center"
                            >
                                <div className="flex flex-col items-center space-x-2 text-left">
                                    <span className="cursor-pointer w-full">
                                        Visites Mensuelles
                                    </span>
                                </div>
                            </th> */}

                            {/* Dynamically created Month-Year Headers */}
                            {selectedColumns.map((col) => {
                                const [year, month] = col.split("_");

                                // Array of month names in French
                                const monthsInFrench = [
                                    "Janvier",
                                    "Février",
                                    "Mars",
                                    "Avril",
                                    "Mai",
                                    "Juin",
                                    "Juillet",
                                    "Août",
                                    "Septembre",
                                    "Octobre",
                                    "Novembre",
                                    "Décembre",
                                ];

                                const monthNameInFrench =
                                    monthsInFrench[parseInt(month, 10) - 1];

                                return (
                                    <th
                                        key={col}
                                        className="border-b px-2 py-1 text-center transform origin-top-left min-w-[100px] max-w-[150px] whitespace-normal"
                                    >
                                        <div className="flex items-center space-x-2">
                                            <span
                                                onClick={() => handleSort(col)}
                                                className="cursor-pointer"
                                            >
                                                # Visites{" "}
                                                <span className="text-xs block">{`(${monthNameInFrench} ${year})`}</span>
                                                {sortColumn === col &&
                                                    (sortDirection === "asc"
                                                        ? "↑"
                                                        : "↓")}
                                            </span>

                                            <button
                                                className="text-red-500 ml-2"
                                                onClick={() =>
                                                    handleRemoveColumn(col)
                                                }
                                            >
                                                <FiTrash2 />
                                            </button>
                                        </div>

                                        <div className="mt-2 pl-0 align-left">
                                            <select
                                                className="text-xs border px-2 py-1 rounded-md"
                                                onChange={(e) =>
                                                    handleFilterChange(
                                                        col,
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="showAll">
                                                    Tous
                                                </option>
                                                <option value="showZero">
                                                    0
                                                </option>
                                                <option value="showOne">
                                                    1
                                                </option>
                                                <option value="showTwo">
                                                    2
                                                </option>
                                                <option value="showThree">
                                                    3
                                                </option>
                                                <option value="showFour">
                                                    4
                                                </option>
                                            </select>
                                        </div>
                                    </th>
                                );
                            })}

                            {/* Add a button to show dropdown for column selection */}
                            <th className="border-b px-2 py-1 w-24 sticky left-72 bg-white z-10">
                                <div className="flex items-center space-x-2">
                                    <button
                                        className="cursor-pointer bg-black text-white py-1 px-2 rounded"
                                        onClick={() =>
                                            setIsDropdownVisible(
                                                !isDropdownVisible
                                            )
                                        }
                                    >
                                        + Ajouter mois
                                    </button>
                                </div>

                                {/* Dropdown for column selection */}
                                {isDropdownVisible && (
                                    <div className="absolute bg-white border mt-2 rounded shadow-lg max-h-60 overflow-y-auto z-20">
                                        {columns
                                            .filter(
                                                (col) =>
                                                    col !== "warehouse_name" && // Exclude warehouse_name
                                                    col !== null && // Exclude null values
                                                    !selectedColumns.includes(
                                                        col
                                                    ) // Exclude already selected columns
                                            )
                                            .sort((a, b) => {
                                                // Extract year and month for both columns a and b
                                                const [yearA, monthA] =
                                                    a.split("_");
                                                const [yearB, monthB] =
                                                    b.split("_");

                                                // Compare by year first (descending)
                                                if (yearA !== yearB) {
                                                    return (
                                                        parseInt(yearB, 10) -
                                                        parseInt(yearA, 10)
                                                    );
                                                }

                                                // If years are equal, compare by month (descending)
                                                return (
                                                    parseInt(monthB, 10) -
                                                    parseInt(monthA, 10)
                                                );
                                            })
                                            .map((column) => {
                                                // Extract year and month from the column (e.g., "2024_1")
                                                const [year, month] =
                                                    column.split("_");

                                                // Array of month names in French
                                                const monthsInFrench = [
                                                    "Janvier",
                                                    "Février",
                                                    "Mars",
                                                    "Avril",
                                                    "Mai",
                                                    "Juin",
                                                    "Juillet",
                                                    "Août",
                                                    "Septembre",
                                                    "Octobre",
                                                    "Novembre",
                                                    "Décembre",
                                                ];

                                                // Get the month name in French based on the month number
                                                const monthNameInFrench =
                                                    monthsInFrench[
                                                        parseInt(month, 10) - 1
                                                    ];

                                                return (
                                                    <div
                                                        key={column}
                                                        className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                                                        onClick={() =>
                                                            handleAddColumn(
                                                                column
                                                            )
                                                        }
                                                    >
                                                        {`${monthNameInFrench} ${year}`}{" "}
                                                        {/* Display the month and year in French */}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </th>
                        </tr>
                    </thead>

                    <tbody
                        key={filteredData.length}
                        className="h-48 overflow-y-scroll"
                    >
                        {filteredData.slice(0, visibleRows).map((row) => (
                            <tr key={row.client_id}>
                                <td className="border-b border-dashed border-gray-300 px-2 py-1 sticky left-0 bg-white font-semibold">
                                    {row.client_name}
                                </td>
                                <td className="border-b border-r border-dashed border-gray-300 px-2 py-1 sticky left-48 bg-white font-semibold">
                                    {row.client_id}
                                </td>
                                <td className="border-b border-dashed border-gray-300 px-2 py-1 sticky left-24 bg-white font-semibold">
                                    {row.city}
                                </td>
                                <td className="border-b border-dashed border-gray-300 px-2 py-1 sticky left-24 bg-white font-semibold">
                                    {row.warehouse_name}
                                </td>
                                <td className="border-b border-dashed border-gray-300 px-2 py-1 sticky left-24 bg-white font-semibold">
                                    {row.sales_rep_name}
                                </td>
                                <td className="border-b border-dashed border-gray-300 px-2 py-1 sticky left-24 bg-white font-semibold">
                                    {(() => {
                                        switch (row.visit_frequency) {
                                            case "1":
                                                return "1x par mois";
                                            case "2":
                                                return "2x par mois";
                                            case "3":
                                                return "3x par mois";
                                            case "4":
                                                return "4x par mois";
                                            default:
                                                return "-"; // Fallback for unexpected values
                                        }
                                    })()}
                                </td>

                                {selectedColumns.map((productLevel) => (
                                    <td
                                        key={productLevel}
                                        className={`border-b border-l border-dashed border-gray-300 px-2 py-1 text-center ${
                                            getThresholdValue(
                                                row.visit_frequency
                                            ) > row[productLevel]
                                                ? "bg-red-100 text-black"
                                                : "bg-green-100 text-black"
                                        }`}
                                    >
                                        {row[productLevel] || "-"}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {filteredData.length > visibleRows && (
                    <div className="text-center mt-4">
                        <button
                            onClick={loadMoreRows}
                            className="bg-black text-white py-2 px-4 rounded"
                        >
                            Afficher Plus
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CoverageTable;
