import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
    FiHome,
    FiUsers,
    // FiBook,
    FiCalendar,
    FiShoppingBag,
    FiMessageCircle,
} from "react-icons/fi";

import { Link } from "react-router-dom";

import FastAPIClient from "../../client";
import config from "../../config";

const client = new FastAPIClient(config);

function Footer() {
    const location = useLocation();
    const currentPage = location.pathname;
    const [isClientPage, setIsClientPage] = useState(false);
    const selectedPageStyle =
        "bg-gray-400 p-3 border rounded-full text-gray-200";

    const [user, setUser] = useState(false);

    // Function to fetch user data and save it in localStorage
    const fetchUserData = async () => {
        try {
            const data = await client.fetchUser();

            // Set user state
            await setUser(data);
            // Save user data in localStorage
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("language", data.language);

            console.log(data);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const [notifications, setNotifications] = useState([]);

    const getNotifications = (userId) => {
        client.getNotifications(userId).then((data) => {
            setNotifications(data?.notifications);
            console.log(data?.notifications);
        });
    };

    useEffect(() => {
        // Check if user is online
        if (user.user_id && navigator.onLine) {
            // Run immediately when the component mounts
            getNotifications(user.user_id);

            // Set an interval to call getNotifications every 30 seconds (30000 milliseconds)
            const interval = setInterval(() => {
                if (navigator.onLine) {
                    getNotifications(user.user_id);
                }
            }, 5000);

            // Cleanup the interval when the component unmounts or when the user_id or selectedClient changes
            return () => clearInterval(interval);
        }
    }, [user.user_id]);

    useEffect(() => {
        fetchUserData();
    }, []);

    // Check if the page path is the "client" page or if the client_id is in the query string
    useEffect(() => {
        const currentPage = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        const clientId = searchParams.get("client_id");

        // If the path is "client" or if client_id exists in the query string, hide the footer
        if (currentPage.split("/")[1] === "client" || clientId) {
            setIsClientPage(true);
        } else {
            setIsClientPage(false);
        }
    }, [location]);
    return (
        <footer
            style={{ flexShrink: 0, width: "95%" }}
            className={`text-center text-black z-40 bg-gray-200 sticky border-r-1 rounded-full border-white md:hidden m-3 ${
                isClientPage && "hidden"
            }`}
        >
            <div className="flex flex-row w-full items-center pb-2 pt-2 text-xl">
                <div className="flex flex-col items-center w-1/4">
                    <Link to="/home">
                        <div
                            className={
                                currentPage === "/home"
                                    ? selectedPageStyle
                                    : "p-3 border rounded-full "
                            }
                        >
                            <FiHome />
                        </div>
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/4">
                    <Link to="/routing">
                        <div
                            className={
                                currentPage === "/routing"
                                    ? selectedPageStyle
                                    : ""
                            }
                        >
                            <FiCalendar />
                        </div>
                    </Link>
                </div>
                {/* <div className="flex flex-col items-center w-1/5">
                    <Link to="/my-leads">
                        <FiFilter />
                    </Link>
                </div> */}
                <div className="flex flex-col items-center w-1/4">
                    <Link to="/clients">
                        <div
                            className={
                                currentPage === "/clients"
                                    ? selectedPageStyle
                                    : ""
                            }
                        >
                            <FiUsers />
                        </div>
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/4">
                    <Link to="/products">
                        <div
                            className={
                                currentPage === "/products"
                                    ? selectedPageStyle
                                    : ""
                            }
                        >
                            <FiShoppingBag />
                        </div>
                    </Link>
                </div>
                <div className="flex flex-col items-center w-1/4">
                    <Link to="/chat">
                        <div
                            className={
                                currentPage === "/chat" ? selectedPageStyle : ""
                            }
                        >
                            <FiMessageCircle />
                            {notifications.length > 0 && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "15px",
                                        right: "20px",
                                        backgroundColor: "red",
                                        borderRadius: "50%",
                                        width: "15px",
                                        height: "15px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "white",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {notifications.length}
                                </div>
                            )}
                        </div>
                    </Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
