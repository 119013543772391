import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Throbber from "../../style/Throbber";

const MeetingClientSearch = ({
    clients,
    selectedClient,
    setSelectedClient,
    suggestions,
    setSuggestions,
    setEventData,
    isLoading,
}) => {
    const [filteredClients, setFilteredClients] = useState([]);
    useEffect(() => {
        if (selectedClient === "") {
            setFilteredClients([]);
        } else {
            const filtered = clients
                .filter((client) => client.is_active) // Filter active clients
                .filter(
                    (client) =>
                        client.client_name
                            .toLowerCase()
                            .includes(selectedClient.toLowerCase()) ||
                        client.client_id.toString().includes(selectedClient) // Convert product_id to string before searching
                );
            setFilteredClients(filtered);
        }
    }, [selectedClient, clients, isLoading]);

    // Function to handle selecting a suggestion
    const handleSelectSuggestion = (client) => {
        setEventData((prevData) => ({
            ...prevData,
            client_id: client.client_id,
            client_name: client.client_name,
        }));
        setSelectedClient(client.client_name);
        setSuggestions([]); // Hide suggestions after selection
    };

    return (
        <div className="relative flex flex-col md:flex-row items-center w-full">
            <FormattedMessage
                id="home.SearchClients"
                defaultMessage="Rechercher un client ..."
            >
                {(placeholder) => (
                    <input
                        type="text"
                        className={`mt-1 w-full block px-3 py-2 bg-white border border-slate-300 rounded-full placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-black`}
                        placeholder={placeholder}
                        value={selectedClient}
                        onChange={(e) => {
                            setEventData((prevData) => ({
                                ...prevData,
                                client_id: null,
                                client_name: null,
                            }));
                            setSelectedClient(e.target.value);
                            setSuggestions(true);
                        }}
                        onBlur={() => setSuggestions(false)} // Hide suggestions on blur
                        onFocus={() => setSuggestions(true)} // Show suggestions on focus
                    />
                )}
            </FormattedMessage>

            {/* Show suggestions if any clients match the search term */}
            {suggestions && filteredClients.length > 0 && (
                <div className="absolute top-full w-full bg-white border border-slate-300 rounded-lg shadow-lg max-h-60 overflow-y-auto mt-2 z-10 text-left">
                    {filteredClients.map((client) => (
                        <div
                            key={client.client_id}
                            onMouseDown={(e) => {
                                e.preventDefault(); // Prevent any unwanted behavior
                                handleSelectSuggestion(client);
                            }}
                            className="cursor-pointer px-3 py-2 hover:bg-slate-100 border-b border-gray-200"
                        >
                            <div className="flex flex-row items-end">
                                <div className="text-base text-black w-2/3">
                                    {client.client_name},{" "}
                                    <span className="italic text-sm text-gray-700">
                                        {client.city}
                                    </span>
                                </div>
                                <div className="ml-5 text-base text-gray-700 w-1/3">
                                    ID:{client.client_id}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {suggestions &&
                selectedClient.length > 0 &&
                filteredClients.length === 0 && (
                    <div className="absolute top-full w-full bg-white border border-slate-300 rounded-lg shadow-lg min-h-10 max-h-60 overflow-y-auto mt-2 z-10 text-center text-gray-200">
                        <Throbber />
                    </div>
                )}
        </div>
    );
};

export default MeetingClientSearch;
