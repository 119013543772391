import React from 'react'
import { FiBell } from 'react-icons/fi'

const Reminders = ({reminders}) => {

    // add a delete function here 
  return (
    <div className="flex flex-col items-center w-full pl-5 pr-5 text-base">
                                            {reminders.map((reminder) => (
                                                <li
                                                    key={reminder.id}
                                                    className="flex flex-row rounded-md border border-rose-500 items-center w-full px-2 mb-1"
                                                >
                                                    <div className="flex text-sm text-rose-500 w-1/12 ">
                                                        <FiBell />
                                                    </div>
                                                    <div className="flex flex-col text-rose-500 text-xxs md:text-sm w-5/6 text-left">
                                                        <div>
                                                            <strong className="mr-2 text-xxs font-semibold">
                                                                {
                                                                    reminder
                                                                        .client
                                                                        .client_name
                                                                }
                                                            </strong>
                                                        </div>
                                                        <div className="mr-2 text-xs">
                                                            {reminder.title}:{" "}
                                                            {reminder.note}
                                                        </div>
                                                    </div>
                                                    <div>x</div>
                                                </li>
                                            ))}
                                        </div>
  )
}

export default Reminders
