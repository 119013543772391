// Filter products based on the selected filters
type selectedFiltersType = {
    beer_family: string[],
    beer_color: string[],
    beer_style: string[],
    beer_flavor: string[],
    beer_region: string[],
    beer_head_type: string[],
    beer_bitterness: string[],
    gluten_free_beer: string[],
    wine_color: string[],
    wine_vineyard: string[],
    wine_denomination: string[],
    wine_appellation: string[],
    wine_millesime: string[],
    wine_sugar_content: string[],
    wine_grape: string[],
    spirit_family: string[],
    spirit_type: string[],
    spirit_millesime: string[],
    spirit_age: string[],
    spirit_region: string[],
    water_family: string[],
    soft_family: string[],
    juice_family: string[],
    sirop_family: string[],
    flavour: string[],
    coffee_type: string[],
    decaf_coffee: string[],
    coffee_pod_type: string[],
    coffee_roasting_region: string[],
    coffee_roast: string[],
    coffee_variety: string[],
    coffee_ingredients: string[],
    coffee_intensity: string[],
    coffee_label: string[],
    tea_type: string[],
    tea_color: string[],
    tea_flavor: string[],
    tea_label: string[],
    hot_beverage_type: string[],
    grocery_type: string[],
    material_type: string[],
    hygiene_type: string[],
    cider_type: string[],
    cider_head_type: string[],
    cider_flavor: string[],
    cider_region: string[],
    container_format: string[],
    manufacturer_brand: string[],
    deposit: string[],
    country: string[],
    product_level_1: string[]
}

export const filterProducts = (products, selectedFilters: selectedFiltersType )=> {
    return products.filter((prod) => {
        let isMatch = true;
        if (selectedFilters.product_level_1.length > 0) {
            isMatch =
                selectedFilters.product_level_1.includes(
                    prod.product_level_1
                ) && isMatch;
        }

        // Beer filters
        if (selectedFilters.beer_family.length > 0) {
            isMatch =
                selectedFilters.beer_family.includes(prod.beer_family) &&
                isMatch;
        }
        if (selectedFilters.beer_color.length > 0) {
            isMatch =
                selectedFilters.beer_color.includes(prod.beer_color) && isMatch;
        }
        if (selectedFilters.beer_style.length > 0) {
            isMatch =
                selectedFilters.beer_style.includes(prod.beer_style) && isMatch;
        }
        if (selectedFilters.beer_region.length > 0) {
            isMatch =
                selectedFilters.beer_region.includes(prod.beer_region) &&
                isMatch;
        }
        if (selectedFilters.beer_head_type.length > 0) {
            isMatch =
                selectedFilters.beer_head_type.includes(prod.beer_head_type) &&
                isMatch;
        }
        if (selectedFilters.beer_flavor.length > 0) {
            isMatch =
                selectedFilters.beer_flavor.includes(prod.beer_flavor) &&
                isMatch;
        }
        if (selectedFilters.beer_bitterness.length > 0) {
            isMatch =
                selectedFilters.beer_bitterness.includes(
                    prod.beer_bitterness
                ) && isMatch;
        }
        if (selectedFilters.gluten_free_beer.length > 0) {
            isMatch =
                selectedFilters.gluten_free_beer.includes(
                    prod.gluten_free_beer
                ) && isMatch;
        }

        // Wine filters - apply only for 'VINS' products

        if (selectedFilters.wine_color.length > 0) {
            isMatch =
                selectedFilters.wine_color.includes(prod.wine_color) && isMatch;
        }
        if (selectedFilters.wine_vineyard.length > 0) {
            isMatch =
                selectedFilters.wine_vineyard.includes(prod.wine_vineyard) &&
                isMatch;
        }
        if (selectedFilters.wine_denomination.length > 0) {
            isMatch =
                selectedFilters.wine_denomination.includes(
                    prod.wine_denomination
                ) && isMatch;
        }
        if (selectedFilters.wine_appellation.length > 0) {
            isMatch =
                selectedFilters.wine_appellation.includes(
                    prod.wine_appellation
                ) && isMatch;
        }
        if (selectedFilters.wine_millesime.length > 0) {
            isMatch =
                selectedFilters.wine_millesime.includes(prod.wine_millesime) &&
                isMatch;
        }
        if (selectedFilters.wine_sugar_content.length > 0) {
            isMatch =
                selectedFilters.wine_sugar_content.includes(
                    prod.wine_sugar_content
                ) && isMatch;
        }
        if (selectedFilters.wine_grape.length > 0) {
            isMatch =
                selectedFilters.wine_grape.includes(prod.wine_grape) && isMatch;
        }
        if (selectedFilters.spirit_family.length > 0) {
            isMatch =
                selectedFilters.spirit_family.includes(prod.spirit_family) &&
                isMatch;
        }
        if (selectedFilters.spirit_type.length > 0) {
            isMatch =
                selectedFilters.spirit_type.includes(prod.spirit_type) &&
                isMatch;
        }
        if (selectedFilters.spirit_millesime.length > 0) {
            isMatch =
                selectedFilters.spirit_millesime.includes(
                    prod.spirit_millesime
                ) && isMatch;
        }
        if (selectedFilters.spirit_age.length > 0) {
            isMatch =
                selectedFilters.spirit_age.includes(prod.spirit_age) && isMatch;
        }
        if (selectedFilters.spirit_region.length > 0) {
            isMatch =
                selectedFilters.spirit_region.includes(prod.spirit_region) &&
                isMatch;
        }
        if (selectedFilters.water_family.length > 0) {
            isMatch =
                selectedFilters.water_family.includes(prod.water_family) &&
                isMatch;
        }
        if (selectedFilters.soft_family.length > 0) {
            isMatch =
                selectedFilters.soft_family.includes(prod.soft_family) &&
                isMatch;
        }
        if (selectedFilters.juice_family.length > 0) {
            isMatch =
                selectedFilters.juice_family.includes(prod.juice_family) &&
                isMatch;
        }
        if (selectedFilters.sirop_family.length > 0) {
            isMatch =
                selectedFilters.sirop_family.includes(prod.sirop_family) &&
                isMatch;
        }
        if (selectedFilters.flavour.length > 0) {
            isMatch = selectedFilters.flavour.includes(prod.flavour) && isMatch;
        }
        if (selectedFilters.coffee_type.length > 0) {
            isMatch =
                selectedFilters.coffee_type.includes(prod.coffee_type) &&
                isMatch;
        }
        if (selectedFilters.decaf_coffee.length > 0) {
            isMatch =
                selectedFilters.decaf_coffee.includes(prod.decaf_coffee) &&
                isMatch;
        }
        if (selectedFilters.coffee_pod_type.length > 0) {
            isMatch =
                selectedFilters.coffee_pod_type.includes(
                    prod.coffee_pod_type
                ) && isMatch;
        }
        if (selectedFilters.coffee_roasting_region.length > 0) {
            isMatch =
                selectedFilters.coffee_roasting_region.includes(
                    prod.coffee_roasting_region
                ) && isMatch;
        }
        if (selectedFilters.coffee_roast.length > 0) {
            isMatch =
                selectedFilters.coffee_roast.includes(prod.coffee_roast) &&
                isMatch;
        }
        if (selectedFilters.coffee_variety.length > 0) {
            isMatch =
                selectedFilters.coffee_variety.includes(prod.coffee_variety) &&
                isMatch;
        }
        if (selectedFilters.coffee_ingredients.length > 0) {
            isMatch =
                selectedFilters.coffee_ingredients.includes(
                    prod.coffee_ingredients
                ) && isMatch;
        }
        if (selectedFilters.coffee_intensity.length > 0) {
            isMatch =
                selectedFilters.coffee_intensity.includes(
                    prod.coffee_intensity
                ) && isMatch;
        }
        if (selectedFilters.coffee_label.length > 0) {
            isMatch =
                selectedFilters.coffee_label.includes(prod.coffee_label) &&
                isMatch;
        }
        if (selectedFilters.tea_type.length > 0) {
            isMatch =
                selectedFilters.tea_type.includes(prod.tea_type) && isMatch;
        }
        if (selectedFilters.tea_color.length > 0) {
            isMatch =
                selectedFilters.tea_color.includes(prod.tea_color) && isMatch;
        }
        if (selectedFilters.tea_flavor.length > 0) {
            isMatch =
                selectedFilters.tea_flavor.includes(prod.tea_flavor) && isMatch;
        }
        if (selectedFilters.tea_label.length > 0) {
            isMatch =
                selectedFilters.tea_label.includes(prod.tea_label) && isMatch;
        }
        if (selectedFilters.hot_beverage_type.length > 0) {
            isMatch =
                selectedFilters.hot_beverage_type.includes(
                    prod.hot_beverage_type
                ) && isMatch;
        }
        if (selectedFilters.grocery_type.length > 0) {
            isMatch =
                selectedFilters.grocery_type.includes(prod.grocery_type) &&
                isMatch;
        }
        if (selectedFilters.material_type.length > 0) {
            isMatch =
                selectedFilters.material_type.includes(prod.material_type) &&
                isMatch;
        }
        if (selectedFilters.hygiene_type.length > 0) {
            isMatch =
                selectedFilters.hygiene_type.includes(prod.hygiene_type) &&
                isMatch;
        }
        if (selectedFilters.cider_type.length > 0) {
            isMatch =
                selectedFilters.cider_type.includes(prod.cider_type) && isMatch;
        }
        if (selectedFilters.cider_head_type.length > 0) {
            isMatch =
                selectedFilters.cider_head_type.includes(
                    prod.cider_head_type
                ) && isMatch;
        }
        if (selectedFilters.cider_flavor.length > 0) {
            isMatch =
                selectedFilters.cider_flavor.includes(prod.cider_flavor) &&
                isMatch;
        }
        if (selectedFilters.cider_region.length > 0) {
            isMatch =
                selectedFilters.cider_region.includes(prod.cider_region) &&
                isMatch;
        }
        if (selectedFilters.deposit.length > 0) {
            isMatch = selectedFilters.deposit.includes(prod.deposit) && isMatch;
        }
        if (selectedFilters.country.length > 0) {
            isMatch = selectedFilters.country.includes(prod.country) && isMatch;
        }
        if (selectedFilters.manufacturer_brand.length > 0) {
            isMatch =
                selectedFilters.manufacturer_brand.includes(
                    prod.manufacturer_brand
                ) && isMatch;
        }
        if (selectedFilters.container_format.length > 0) {
            isMatch =
                selectedFilters.container_format.includes(
                    prod.container_format
                ) && isMatch;
        }
        return isMatch
    })

}
