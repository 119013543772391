import React from 'react'
import OrderHistory from "../../LayoutComponents/OrderHistory"
import { ClientReceiptType } from '../../../types/client';
import { FiX } from 'react-icons/fi';

interface ModalProps {
    setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
    receipts: ClientReceiptType[];
}


const FacturePopUp : React.FC<ModalProps> = ({
    setShowPopUp,
    receipts,
}) => {
    const handleCloseModal = () => {
        setShowPopUp(false);
    };

    return (
        <div className="flex flex-col w-5/6 justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className=" rounded-lg w-3/4 p-6 relative bg-white h-5/6 flex flex-col">
                    {/* Close button */}
                    <button
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        onClick={handleCloseModal}
                    >
                        <FiX size={24} />
                    </button>

                    <div className="flex-1 overflow-y-auto pt-8 items-center no-scrollbar min-h-0">
                        <OrderHistory orderhistory={receipts} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FacturePopUp
