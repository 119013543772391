import React, { useState } from "react";
import {
    FiArrowUp,
    FiArrowDown,
    FiBarChart2,
    FiX,
    FiChevronUp,
    FiChevronDown,
} from "react-icons/fi"; // Make sure to install react-icons

import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import BarIndicator from "../../Charts/BarIndicator";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ClientStatisticsPopUp = ({ statistics, togglePopup }) => {
    const clientstatistics = statistics;

    const volumeChartStat = statistics.find(
        (stat) => stat.statistic_type === "volume_chart"
    );

    const revenueChartStat = statistics.find(
        (stat) => stat.statistic_type === "revenue_chart"
    );

    const indicators = statistics.filter(
        (stat) => stat.statistic_type === "indicator_chart"
    );

    const stacked_options = {
        plugins: {
            legend: {
                position: "right",
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: true, // Show horizontal grid lines
                    color: "rgba(0, 0, 0, 0.1)", // Light gray color for subtle lines
                    lineWidth: 1, // Customize line thickness
                    borderDash: [5, 5], // Optional: dashed lines for a lighter look
                },
            },
        },
        maintainAspectRatio: false, // Allow control over width and height
    };

    const [openGroups, setOpenGroups] = useState({});

    // Function to toggle a group
    const toggleGroup = (category) => {
        setOpenGroups((prev) => ({
            ...prev,
            [category]: !prev[category],
        }));
    };

    // Group stats by product_category
    const groupedStats = statistics.reduce((acc, stat) => {
        if (stat.statistic_type === "product_level_comparison") {
            const category = stat.data.product_category;
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(stat);
        }
        return acc;
    }, {});

    const iconCircleStyle = `flex w-8 h-8 bg-gray-100 ml-2 mt-2 rounded-full items-center justify-center`;
    return (
        <div className="flex flex-col w-5/6 justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg w-3/4 p-6 relative">
                    {/* Close button */}
                    <button
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        onClick={() => togglePopup(false)}
                    >
                        <FiX size={24} />
                    </button>

                    {/* Popup content */}
                    <div className="flex flex-row items-center">
                        <div className={iconCircleStyle}>
                            <FiBarChart2 />
                        </div>
                        <h2 className="text-xl text-left  mt-2 ml-4">
                            Statistiques
                        </h2>
                    </div>
                    <div className="text-left justify-center mt-5 mb-5">
                        <div className="flex flex-row w-5/6">
                            {/* Render statistics data passed to component */}
                            <div className="w-1/2 ml-20">
                                <div className="text-">Aperçu</div>
                                <div className="w-full flex flex-row justify-between items-center mb-2 ">
                                    <div className="flex text-left text-sm font-semibold w-2/3 "></div>
                                    <div
                                        className={`text-sm w-12 h-5 flex item-center justify-center`}
                                    >
                                        {new Date().getFullYear()}{" "}
                                        {/* Current year */}
                                    </div>
                                    <div
                                        className={`flex items-center text-sm font-semibold w-1/6 ml-2`}
                                    ></div>
                                    <div
                                        className={`text-sm w-12 h-5 flex justify-center w-1/6`}
                                    >
                                        {new Date().getFullYear() - 1}{" "}
                                        {/* Last year */}
                                    </div>
                                </div>
                                {clientstatistics.map((stat, index) => (
                                    <div key={index} className=" text-xs  mb-5">
                                        {stat.statistic_type ==
                                            "comparison" && (
                                            <div className="w-full flex flex-row justify-between items-center mb-5">
                                                <div className="text-left  w-2/3">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: stat.data
                                                                .name,
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className={`rounded-full ${
                                                        isNaN(
                                                            parseFloat(
                                                                stat.data
                                                                    .value_previous_year
                                                            )
                                                        ) &&
                                                        !isNaN(
                                                            parseFloat(
                                                                stat.data
                                                                    .value_current_year
                                                            )
                                                        )
                                                            ? "bg-gold text-black" // If previous year is NaN and current year is valid
                                                            : isNaN(
                                                                  parseFloat(
                                                                      stat.data
                                                                          .value_current_year
                                                                  )
                                                              )
                                                            ? "bg-rose-500 text-white" // If current year is NaN
                                                            : stat.data
                                                                  .change >= 0
                                                            ? "bg-gold text-black" // If change is positive or 0
                                                            : "bg-rose-500 text-white" // If change is negative
                                                    } text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                                >
                                                    {isNaN(
                                                        parseFloat(
                                                            stat.data
                                                                .value_current_year
                                                        )
                                                    )
                                                        ? "0" // If value_current_year is NaN, display 0
                                                        : parseFloat(
                                                              stat.data
                                                                  .value_current_year
                                                          ).toFixed(0)}{" "}
                                                </div>

                                                <div
                                                    className={`flex items-center ${
                                                        stat.data.change >= 0
                                                            ? "text-black"
                                                            : "text-rose-500"
                                                    } text-xs font-extrabold w-1/5 ml-2 ${
                                                        !isNaN(
                                                            parseFloat(
                                                                stat.data
                                                                    .value_current_year
                                                            )
                                                        ) &&
                                                        isNaN(
                                                            parseFloat(
                                                                stat.data
                                                                    .value_previous_year
                                                            )
                                                        )
                                                            ? "text-white" // If current year is not NaN and previous year is NaN, apply black background
                                                            : ""
                                                    }`}
                                                >
                                                    {stat.data.change !== 0 && // Only display if change is not 0
                                                        (isNaN(
                                                            stat.data.change
                                                        ) ||
                                                        stat.data.change ===
                                                            null ? (
                                                            <>
                                                                <span className="ml-1">
                                                                    100%
                                                                </span>
                                                            </>
                                                        ) : isNaN(
                                                              parseFloat(
                                                                  stat.data
                                                                      .value_previous_year
                                                              )
                                                          ) &&
                                                          !isNaN(
                                                              parseFloat(
                                                                  stat.data
                                                                      .value_current_year
                                                              )
                                                          ) ? (
                                                            <>
                                                                <span className="ml-1">
                                                                    100%
                                                                </span>{" "}
                                                                {/* Display 100% when previous year is NaN and current year is valid */}
                                                                <FiArrowUp className="text-gold" />{" "}
                                                                {/* Show up arrow and gold color */}
                                                            </>
                                                        ) : stat.data.change >
                                                          0 ? (
                                                            <>
                                                                <span className="ml-1">
                                                                    {parseFloat(
                                                                        stat
                                                                            .data
                                                                            .change
                                                                    ).toFixed(
                                                                        0
                                                                    )}
                                                                    %
                                                                </span>
                                                                <FiArrowUp />
                                                            </>
                                                        ) : stat.data.change ==
                                                          0 ? (
                                                            <></>
                                                        ) : (
                                                            <>
                                                                <span className="ml-1">
                                                                    {parseFloat(
                                                                        stat
                                                                            .data
                                                                            .change
                                                                    ).toFixed(
                                                                        0
                                                                    )}
                                                                    %
                                                                </span>
                                                                <FiArrowDown />
                                                            </>
                                                        ))}
                                                </div>

                                                <div
                                                    className={`rounded-full bg-gray-200 text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                                >
                                                    {isNaN(
                                                        parseFloat(
                                                            stat.data
                                                                .value_previous_year
                                                        )
                                                    )
                                                        ? "0" // If value_previous_year is NaN, display 0
                                                        : parseFloat(
                                                              stat.data
                                                                  .value_previous_year
                                                          ).toFixed(0)}{" "}
                                                </div>
                                            </div>
                                        )}

                                        {stat.statistic_type == "indicator" && (
                                            <div className="w-full flex flex-row justify-between items-center mb-5 ">
                                                <div className="text-left text-xs  w-2/3">
                                                    {stat.data.name}
                                                </div>
                                                <div
                                                    className={`rounded-full ${
                                                        stat.data
                                                            .value_current_year ==
                                                        "Faible"
                                                            ? "bg-gold"
                                                            : "bg-rose-500"
                                                    } text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                                >
                                                    {
                                                        stat.data
                                                            .value_current_year
                                                    }
                                                </div>
                                                <div
                                                    className={`flex items-center ${
                                                        stat.data.change >= 0
                                                            ? "text-black"
                                                            : "text-rose-500"
                                                    } text-xs font-bold w-1/5 ml-2`}
                                                ></div>
                                                <div
                                                    className={`rounded-full
                                                        text-xxs font-bold w-12 h-5 flex items-center justify-center`}
                                                ></div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                Statistiques par categories produits
                                <div>
                                    {Object.entries(groupedStats).map(
                                        ([category, stats]) => (
                                            <div
                                                key={category}
                                                className="text-xs mb-3 mt-3 "
                                            >
                                                {/* Group Header */}
                                                <div
                                                    className="cursor-pointer flex flex-row justify-between items-center rounded"
                                                    onClick={() =>
                                                        toggleGroup(category)
                                                    }
                                                >
                                                    <div className="w-1/2">
                                                        {category}
                                                    </div>
                                                    <div className="flex items-center">
                                                        {/* Check if Volume (hL) change is positive */}
                                                        {stats.some(
                                                            (stat) =>
                                                                stat.data
                                                                    .name ===
                                                                    "Volume (L)" &&
                                                                stat.data
                                                                    .change > 0
                                                        ) && (
                                                            <span className="inline-block w-2 h-2 rounded-full bg-gold ml-2"></span>
                                                        )}
                                                        {/* Check if Volume (hL) change is NaN but current year is not NaN */}
                                                        {stats.some(
                                                            (stat) =>
                                                                stat.data
                                                                    .name ===
                                                                    "Volume (L)" &&
                                                                isNaN(
                                                                    stat.data
                                                                        .change
                                                                ) &&
                                                                !isNaN(
                                                                    stat.data
                                                                        .value_current_year
                                                                )
                                                        ) && (
                                                            <span className="inline-block w-2 h-2 rounded-full bg-gold ml-2"></span>
                                                        )}
                                                        {/* Check if Volume (hL) change is negative */}
                                                        {stats.some(
                                                            (stat) =>
                                                                stat.data
                                                                    .name ===
                                                                    "Volume (L)" &&
                                                                stat.data
                                                                    .change < 0
                                                        ) && (
                                                            <span className="inline-block w-2 h-2 rounded-full bg-red-500 ml-2"></span>
                                                        )}
                                                        {/* Check if Volume (hL) change is NaN and both current year and previous year are NaN */}
                                                        {stats.some(
                                                            (stat) =>
                                                                stat.data
                                                                    .name ===
                                                                    "Volume (L)" &&
                                                                isNaN(
                                                                    stat.data
                                                                        .change
                                                                ) &&
                                                                isNaN(
                                                                    stat.data
                                                                        .value_current_year
                                                                )
                                                        ) && (
                                                            <span className="inline-block w-2 h-2 rounded-full bg-red-500 ml-2"></span>
                                                        )}
                                                        {/* Check if Volume (hL) current year and previous year are equal */}
                                                        {/* {stats.some(
                                                            (stat) =>
                                                                stat.data
                                                                    .name ===
                                                                    "Volume (L)" &&
                                                                stat.data
                                                                    .value_current_year ===
                                                                    stat.data
                                                                        .value_previous_year
                                                        ) && (
                                                            <span className="inline-block w-2 h-2 rounded-full bg-green-500 ml-2"></span>
                                                        )} */}
                                                    </div>
                                                    <div>
                                                        {openGroups[
                                                            category
                                                        ] ? (
                                                            <FiChevronUp />
                                                        ) : (
                                                            <FiChevronDown />
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Group Content */}
                                                {openGroups[category] && (
                                                    <div className="ml-10 mt-2">
                                                        {stats.map((stat) => (
                                                            <div
                                                                key={
                                                                    stat.data
                                                                        .name
                                                                }
                                                                className="w-full flex h-5 flex-row justify-between items-center text-xxs"
                                                            >
                                                                <div className="text-left w-2/3">
                                                                    {
                                                                        stat
                                                                            .data
                                                                            .name
                                                                    }{" "}
                                                                </div>
                                                                <div
                                                                    className={`rounded-full ${
                                                                        isNaN(
                                                                            stat
                                                                                .data
                                                                                .value_current_year
                                                                        )
                                                                            ? "bg-rose-500 text-white"
                                                                            : !isNaN(
                                                                                  parseFloat(
                                                                                      stat
                                                                                          .data
                                                                                          .change
                                                                                  )
                                                                              ) &&
                                                                              isFinite(
                                                                                  stat
                                                                                      .data
                                                                                      .change
                                                                              )
                                                                            ? stat
                                                                                  .data
                                                                                  .change >=
                                                                              0
                                                                                ? "bg-gold text-black"
                                                                                : "bg-rose-500 text-white"
                                                                            : "bg-gold text-black" // Apply bg-gold if change is not a valid number
                                                                    } text-xxs font-bold w-12 flex items-center justify-center`}
                                                                >
                                                                    {!isNaN(
                                                                        stat
                                                                            .data
                                                                            .value_current_year
                                                                    )
                                                                        ? parseFloat(
                                                                              stat
                                                                                  .data
                                                                                  .value_current_year
                                                                          ).toFixed(
                                                                              0
                                                                          )
                                                                        : 0}
                                                                    {""}
                                                                </div>
                                                                <div
                                                                    className={`flex items-center ${
                                                                        !isNaN(
                                                                            parseFloat(
                                                                                stat
                                                                                    .data
                                                                                    .change
                                                                            )
                                                                        ) &&
                                                                        isFinite(
                                                                            stat
                                                                                .data
                                                                                .change
                                                                        )
                                                                            ? stat
                                                                                  .data
                                                                                  .change >=
                                                                              0
                                                                                ? "text-black" // If change > 0, use green text
                                                                                : "text-rose-500" // If change < 0, use rose red text
                                                                            : "" // If no valid number, don't apply any color
                                                                    } text-xxs font-extrabold w-1/5 ml-2`}
                                                                >
                                                                    {stat.data
                                                                        .change !=
                                                                        0 &&
                                                                        !isNaN(
                                                                            stat
                                                                                .data
                                                                                .change
                                                                        ) &&
                                                                        (stat
                                                                            .data
                                                                            .change >
                                                                        0 ? (
                                                                            <>
                                                                                <span className="ml-1">
                                                                                    {parseFloat(
                                                                                        stat
                                                                                            .data
                                                                                            .change
                                                                                    ).toFixed(
                                                                                        0
                                                                                    )}

                                                                                    %
                                                                                </span>
                                                                                <FiArrowUp />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span className="ml-1">
                                                                                    {parseFloat(
                                                                                        stat
                                                                                            .data
                                                                                            .change
                                                                                    ).toFixed(
                                                                                        0
                                                                                    )}

                                                                                    %
                                                                                </span>
                                                                                <FiArrowDown />
                                                                            </>
                                                                        ))}
                                                                    {/* Display nothing if not a valid number */}
                                                                </div>
                                                                <div
                                                                    className={`rounded-full bg-gray-200 text-xxs font-bold w-12 flex items-center justify-center`}
                                                                >
                                                                    {isNaN(
                                                                        stat
                                                                            .data
                                                                            .value_previous_year
                                                                    )
                                                                        ? "-"
                                                                        : parseFloat(
                                                                              stat
                                                                                  .data
                                                                                  .value_previous_year
                                                                          ).toFixed(
                                                                              0
                                                                          )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col w-1/2 ml-20">
                                {/* Revenue Bar Chart */}
                                <div className="flex flex-col mb-6 w-1/2">
                                    <div className=" text mb-2">
                                        Chiffre d&apos;affaires (€)
                                    </div>
                                    <div
                                        style={{
                                            minHeight: 150,
                                            minWidth: 450,
                                        }}
                                    >
                                        <Bar
                                            data={revenueChartStat.data}
                                            options={stacked_options}
                                        />
                                    </div>
                                </div>

                                {/* Margin Percentage Bar Chart */}
                                <div className="flex flex-col mb-6 w-1/2">
                                    <div className=" text mb-2">
                                        Volume (hL)
                                    </div>
                                    <div
                                        style={{
                                            minHeight: 150,
                                            minWidth: 450,
                                        }}
                                    >
                                        <Bar
                                            data={volumeChartStat.data}
                                            options={stacked_options}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="text mb-5">
                                        Potentiel vs clients similaires
                                    </div>

                                    {indicators.map((indicator, index) => (
                                        <div
                                            key={index}
                                            className="flex flex-row items-center w-full mb-4"
                                        >
                                            <div className="w-1/2 text-sm">
                                                {indicator.data.name}{" "}
                                            </div>
                                            <BarIndicator
                                                percentage={
                                                    indicator.data.value
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientStatisticsPopUp;
