import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

/*eslint-disable*/

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";

import { FiInfo, FiCheckSquare } from "react-icons/fi";
import { FiFilter } from "react-icons/fi";

import OfflineMessage from "../../../components/offline_message";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    ArcElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
};

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";
import SmallStatisticsBox from "../../../components/Charts/SmallStatisticsBox";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

// Utilities and configurations
import FastAPIClient from "../../../client";
import config from "../../../config";
import DisplayReport from "../../../components/LayoutComponents/DisplayReport";

const client = new FastAPIClient(config);

const useNetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isSlowConnection, setIsSlowConnection] = useState(false);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        const handleConnectionChange = () => {
            const connection =
                navigator.connection ||
                navigator.mozConnection ||
                navigator.webkitConnection;
            if (connection) {
                setIsSlowConnection(
                    connection.effectiveType === "2g" ||
                        connection.effectiveType === "3g" ||
                        connection.downlink < 1.5
                );
            }
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        if (navigator.connection) {
            handleConnectionChange();
            navigator.connection.addEventListener(
                "change",
                handleConnectionChange
            );
        }

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);

            if (navigator.connection) {
                navigator.connection.removeEventListener(
                    "change",
                    handleConnectionChange
                );
            }
        };
    }, []);

    return { isOnline, isSlowConnection };
};

const Reports = () => {
    const { isOnline, isSlowConnection } = useNetworkStatus();
    const [locale, setLocale] = useState();
    const [reports, setReports] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [sortColumn, setSortColumn] = useState(null); // Track current sort column
    const [sortDirection, setSortDirection] = useState("desc"); // "asc" or "desc"
    const [expandedReports, setExpandedReports] = useState({}); // For toggling expanded report rows

    const [userFilter, setUserFilter] = useState([]);
    const [clientFilter, setClientFilter] = useState(""); // Store client filter text
    const [userSearchText, setUserSearchText] = useState(""); // Store search text for users
    const [clientSearchText, setClientSearchText] = useState(""); // Store search text for clients

    const [reportLayout, setReportLayout] = useState([]);

    const [showUserFilterDropdown, setShowUserFilterDropdown] = useState(false);
    const [showClientFilterDropdown, setShowClientFilterDropdown] =
        useState(false);

    const userFilterRef = useRef(null);
    const clientFilterRef = useRef(null);

    const [selectedLayout, setSelectedLayout] = useState("MT-01");
    const language = "fr";

    const getReportLayout = () => {
        client.getReportLayout().then((data) => {
            setReportLayout(data);
            console.log("Report Layouts", reportLayout);
        });
    };

    const nav = useNavigate();

    const [user, setUser] = useState(false);

    const getUserName = () => {
        client.fetchUser().then((data) => {
            setUser(data);
        });
    };

    useEffect(() => {
        getUserName();
    }, []);

    // Define columns array
    const columns = [
        { key: "user.first_name", label: "Nom", filterable: true },
        { key: "created_at", label: "Date", filterable: false },
        { key: "client.client_name", label: "Client", filterable: true },
    ];

    const fetchReportsData = (user) => {
        if (!user?.user_id) return; // Prevent fetching if user_id is null or undefined

        client.getReports(null, user.warehouse_id).then((data) => {
            const sortedReports = data.results.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );
            setReports(sortedReports);
            console.log(sortedReports);
            setFilteredReports(sortedReports);
        });
    };

    useEffect(() => {
        if (isOnline && !isSlowConnection) {
            setLocale(localStorage.getItem("language"));
            fetchReportsData(user);
            getReportLayout();
        }
    }, [isOnline, isSlowConnection, user]);

    // Sorting Function
    const handleSort = (columnKey) => {
        const newDirection =
            sortColumn === columnKey && sortDirection === "asc"
                ? "desc"
                : "asc";
        setSortColumn(columnKey);
        setSortDirection(newDirection);

        setFilteredReports((prevReports) =>
            [...prevReports].sort((a, b) => {
                let aValue =
                    columnKey.split(".").reduce((obj, key) => obj?.[key], a) ||
                    "";
                let bValue =
                    columnKey.split(".").reduce((obj, key) => obj?.[key], b) ||
                    "";

                if (columnKey === "created_at") {
                    aValue = new Date(aValue).getTime();
                    bValue = new Date(bValue).getTime();
                }

                // Ensure that the value is a valid string or number for comparison
                aValue = aValue.toString().toLowerCase();
                bValue = bValue.toString().toLowerCase();

                return newDirection === "asc"
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            })
        );
    };

    // Filtering Function
    useEffect(() => {
        const filtered = reports.filter((report) => {
            const userName = report.user?.first_name || "";
            const clientName = report.client?.client_name || "";

            return (
                (userFilter.length === 0 || userFilter.includes(userName)) &&
                (clientFilter === "" ||
                    clientName
                        .toLowerCase()
                        .includes(clientFilter.toLowerCase()))
            );
        });

        setFilteredReports(filtered);
    }, [userFilter, clientFilter, reports]);

    // Handle filter selection for user
    const handleUserFilterChange = (userName) => {
        setUserFilter((prevFilter) =>
            prevFilter.includes(userName)
                ? prevFilter.filter((name) => name !== userName)
                : [...prevFilter, userName]
        );
    };

    // Handle client name input filter change
    const handleClientFilterChange = (e) => {
        setClientFilter(e.target.value); // Set client name filter text
    };

    // Get unique users for dropdown
    const uniqueUsers = [
        ...new Set(reports.map((report) => report.user?.first_name || "N/A")),
    ];

    // Get unique clients for the dropdown filter
    const uniqueClients = [
        ...new Set(
            reports.map((report) => report.client?.client_name || "N/A")
        ),
    ];

    // Filter users based on the search input
    const filteredUsers = uniqueUsers.filter((user) =>
        user.toLowerCase().includes(userSearchText.toLowerCase())
    );

    // Filter clients based on the search input
    const filteredClients = uniqueClients.filter((client) =>
        client.toLowerCase().includes(clientSearchText.toLowerCase())
    );

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                userFilterRef.current &&
                !userFilterRef.current.contains(event.target) &&
                clientFilterRef.current &&
                !clientFilterRef.current.contains(event.target)
            ) {
                setShowUserFilterDropdown(false);
                setShowClientFilterDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    // Toggle Display Report for specific report row
    const toggleReportRow = (reportId) => {
        setExpandedReports((prevState) => ({
            ...prevState,
            [reportId]: !prevState[reportId], // Toggle the report visibility for this row
        }));
    };

    function parseFieldValue(fieldValue) {
        let parsedValue = fieldValue;

        try {
            // Try parsing the fieldValue as JSON
            parsedValue = JSON.parse(fieldValue);
        } catch (error) {
            // If it's not a valid JSON string, leave it as is
            console.warn("Invalid JSON, using raw value:", fieldValue);
        }

        return parsedValue;
    }

    function parseFieldValue(fieldValue, language) {
        // If the value is a boolean (true/false), translate it
        if (typeof fieldValue === "boolean") {
            return fieldValue
                ? language === "fr"
                    ? "Oui"
                    : "Yes"
                : language === "fr"
                ? "Non"
                : "No";
        }

        // If the fieldValue is a string, we can directly check for predefined values like "oui", "non"
        if (typeof fieldValue === "string") {
            // If the string is one of our predefined "yes/no" responses, handle translation
            if (fieldValue === "oui") {
                return language === "fr" ? "Oui" : "Yes";
            } else if (fieldValue === "non") {
                return language === "fr" ? "Non" : "No";
            }
            return fieldValue; // For any other string value, just return it as it is
        }

        // If it's neither a boolean nor a string, just return it (for other types, such as objects)
        return fieldValue;
    }

    if (!isOnline) {
        return <OfflineMessage />;
    }

    return (
        <IntlProvider locale="fr" messages={messages["fr"]}>
            <div className="mt-10 ml-5 md:mt-5 text-left md:ml-20 md:mr-20">
                {/* Search and Filter Section */}

                {/* Table Section */}
                <div className="overflow-x-auto bg-white rounded-lg">
                    <table className="min-w-full border-collapse">
                        <thead>
                            <tr className="text-black">
                                {columns.map((col) => (
                                    <th
                                        key={col.key}
                                        className="relative px-6 py-3 text-left text-base font-semibold cursor-pointer"
                                    >
                                        <div className="flex items-center space-x-2">
                                            <span
                                                onClick={() =>
                                                    handleSort(col.key)
                                                }
                                            >
                                                {col.label}{" "}
                                                {sortColumn === col.key &&
                                                    (sortDirection === "asc"
                                                        ? "↑"
                                                        : "↓")}
                                            </span>

                                            {col.filterable && (
                                                <FiFilter
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        col.key ===
                                                        "user.first_name"
                                                            ? setShowUserFilterDropdown(
                                                                  !showUserFilterDropdown
                                                              )
                                                            : setShowClientFilterDropdown(
                                                                  !showClientFilterDropdown
                                                              )
                                                    }
                                                />
                                            )}
                                        </div>

                                        {/* User Filter Dropdown */}
                                        {col.key === "user.first_name" &&
                                            showUserFilterDropdown && (
                                                <div
                                                    ref={userFilterRef}
                                                    className="absolute left-0 mt-2 bg-white border shadow-md rounded-md p-2 w-48 z-10"
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="Recherche commercial"
                                                        value={userSearchText}
                                                        onChange={(e) =>
                                                            setUserSearchText(
                                                                e.target.value
                                                            )
                                                        }
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    {filteredUsers.map(
                                                        (user) => (
                                                            <label
                                                                key={user}
                                                                className="block px-2 py-1 text-sm cursor-pointer"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={userFilter.includes(
                                                                        user
                                                                    )}
                                                                    onChange={() =>
                                                                        handleUserFilterChange(
                                                                            user
                                                                        )
                                                                    }
                                                                    className="mr-2"
                                                                />
                                                                {user}
                                                            </label>
                                                        )
                                                    )}
                                                </div>
                                            )}

                                        {/* Client Filter Dropdown */}
                                        {col.key === "client.client_name" &&
                                            showClientFilterDropdown && (
                                                <div
                                                    ref={clientFilterRef}
                                                    className="absolute left-0 mt-2 bg-white border shadow-md rounded-md p-2 w-48 z-10"
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder="Recherche client"
                                                        value={clientSearchText}
                                                        onChange={(e) =>
                                                            setClientSearchText(
                                                                e.target.value
                                                            )
                                                        }
                                                        className="w-full p-2 mb-2 border border-gray-300 rounded"
                                                    />
                                                    {filteredClients.map(
                                                        (client) => (
                                                            <label
                                                                key={client}
                                                                className="block px-2 py-1 text-sm cursor-pointer"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={clientFilter.includes(
                                                                        client
                                                                    )}
                                                                    onChange={() =>
                                                                        setClientFilter(
                                                                            client
                                                                        )
                                                                    }
                                                                    className="mr-2"
                                                                />
                                                                {client}
                                                            </label>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                    </th>
                                ))}
                                <th className="px-6 py-3 text-left font-semibold"></th>{" "}
                                {/* Added actions column */}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredReports.length > 0 ? (
                                filteredReports.map((report) => (
                                    <React.Fragment key={report.report_id}>
                                        {/* Normal Data Row */}
                                        <tr className="border-t hover:bg-gray-100 text-xs">
                                            <td className="px-6 py-4  text-gray-900">
                                                {report.user?.first_name ||
                                                    "N/A"}
                                            </td>
                                            <td className="px-6 py-4  text-gray-900">
                                                {new Date(
                                                    report.created_at
                                                ).toLocaleString("fr-FR")}
                                            </td>
                                            <td
                                                className="px-6 py-4 text-gray-900 underline cursor-pointer"
                                                onClick={() =>
                                                    nav(
                                                        `/client/${report.client?.client_id}`
                                                    )
                                                }
                                            >
                                                {report.client?.client_name ? (
                                                    <>
                                                        {
                                                            report.client
                                                                .client_name
                                                        }
                                                        ,{" "}
                                                        {report.client?.city ||
                                                            "N/A"}
                                                    </>
                                                ) : (
                                                    "N/A"
                                                )}
                                            </td>
                                            <td className="px-6 py-4  text-gray-900">
                                                {/* Display the button to toggle report visibility */}
                                                <button
                                                    onClick={() =>
                                                        toggleReportRow(
                                                            report.report_id
                                                        )
                                                    }
                                                    className="bg-black text-white px-4 py-2 rounded-full"
                                                >
                                                    {expandedReports[
                                                        report.report_id
                                                    ]
                                                        ? "Fermer le rapport"
                                                        : "Afficher le rapport"}
                                                </button>
                                            </td>
                                        </tr>
                                        {/* Expanded Report Row (Visible when button is clicked) */}

                                        {expandedReports[report.report_id] && (
                                            <tr className="border-t hover:bg-gray-100 text-base">
                                                <td
                                                    className="px-6 py-4  textblack"
                                                    colspan="3"
                                                >
                                                    {reportLayout && (
                                                        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                                                            <DisplayReport
                                                                reportLayout={
                                                                    reportLayout
                                                                }
                                                                report={report}
                                                                language={
                                                                    language
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={columns.length + 1}
                                        className="px-6 py-4 text-center text-sm text-gray-500"
                                    >
                                        Aucun rapport trouvé.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </IntlProvider>
    );
};

export default Reports;
