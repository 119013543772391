/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid"; // Import UUID generator

import FastAPIClient from "../../../client";
import config from "../../../config";
const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

const messages = {
	en: messages_en,
	fr: messages_fr,
};

import { FiCalendar } from "react-icons/fi";
import Throbber from "../../../style/Throbber";

const ReminderModal = ({ userId, setReminderToggle, clientId, locale }) => {
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [reminderData, setReminderData] = useState({
		reminder_id: uuidv4(),
		start_date: null,
		user_id: userId, // Replace with the user's ID if applicable
		title: "",
		comment: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;

		setReminderData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleSaveReminder = async (e) => {
		setIsSubmitting(true);
		e.preventDefault();
		const reminder = {
			reminder_id: reminderData.reminder_id,
			date: reminderData.start_date,
			title: reminderData.title,
			user_id: userId,
			note: reminderData.comment,
			client_id: clientId,
			created_at: new Date().toISOString(),
		};
		if (reminderData.start_date && reminderData.title && reminderData.comment) {
			console.log("Reminder data", reminder);
			await client.createReminder(reminder);
			setError(null); // Clear error if reminder is saved successfully
			setSuccess("report.success"); // Show error message // Show error message
			setTimeout(() => {
				setSuccess(null); // Clear error after 3 seconds
				setReminderToggle(false);
			}, 1000);
			setIsSubmitting(false);
		} else {
			console.log("Reminder fields incomplete, not saving reminder.");
			setError("report.error"); // Show error message
			setTimeout(() => {
				setError(null); // Clear error after 3 seconds
			}, 3000);
		}
		console.log("success", success);
		setIsSubmitting(false);
	};

	const closeModal = () => {
		setReminderToggle(false);
	};
	return (
		<div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
			<IntlProvider locale={locale} messages={messages[locale]}>
				{error && (
					<div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded">
						<FormattedMessage id={error} values={{ number: 1 }} />
					</div>
				)}

				{success && (
					<div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded">
						<FormattedMessage id={success} values={{ number: 1 }} />
					</div>
				)}
				<div className="bg-white p-6 rounded-md shadow-md md:w-1/2 max-w-[80%]">
					<div className="flex flex-row w-full items-center mb-4 relative">
						<div className="flex w-10 h-10 bg-gray-100 ml-2 rounded-full items-center justify-center">
							<FiCalendar />
						</div>
						<div>
							<h2 className="text-xl ml-4">
								<FormattedMessage id="report.plan" values={{ number: 1 }} />
							</h2>
						</div>
						{isSubmitting && (
							<div className="absolute top-1/2 left-1/2 z-10">
								<Throbber />
							</div>
						)}
					</div>
					<form onSubmit={(e) => handleSaveReminder(e)}>
						<div className="flex items-center mb-2">
							<label className="w-1/3 pr-2">Date:</label>
							<input
								type="date"
								name="start_date"
								value={reminderData.start_date || ""}
								onChange={handleChange}
								placeholder="Select start time"
								className="border rounded-full w-full p-2"
							/>
						</div>
						<div className="flex items-center mb-2">
							<label className="w-1/3 pr-2">Objet:</label>
							<input
								type="text"
								name="title"
								value={reminderData.title}
								onChange={handleChange}
								className="border rounded-full w-full p-2"
								placeholder="Entrez la raison"
							/>
						</div>
						<div className="flex items-center mb-2">
							<label className="w-1/3 pr-2">Commentaires:</label>
							<input
								type="text"
								name="comment"
								value={reminderData.comment}
								onChange={handleChange}
								className="border rounded-full w-full p-2"
								placeholder="Entrez un commentaire"
							/>
						</div>
						<div className="flex justify-center gap-3 py-3 w-full px-20">
							<button
								type="submit"
								className="md:w-1/2 bg-black rounded-md text-white px-3 pt-1 pb-1 font-semibold md:align-right disabled:bg-gray-300"
								disabled={isSubmitting}
							>
								<FormattedMessage id="report.save" values={{ number: 1 }} />
							</button>
							<button
								onClick={() => closeModal()}
								className="md:w-1/2 bg-gray-500 rounded-md px-3 text-white pl pr pt-1 pb-1 font-semibold md:align-right"
							>
								<FormattedMessage id="report.cancel" values={{ number: 1 }} />
							</button>
						</div>
					</form>
				</div>
			</IntlProvider>
		</div>
	);
};

export default ReminderModal;
