import React from "react";

const CustomerSatisfaction = ({
    field_id,
    handleInputChange,
    responses,
    options,
}) => {
    const handleCheckboxChange = (optionValue) => {
        // Reset all other options and only mark the selected one
        const newResponse = options.reduce((acc, option) => {
            acc[option.label] = { reponse: option.label === optionValue };
            return acc;
        }, {});

        handleInputChange(field_id, newResponse);
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="text-left w-full font-semibold">
                Satisfaction client
            </div>

            {options.map((option, index) => (
                <div
                    key={`${field_id}-${index}`}
                    className="flex flex-col md:flex-row gap-4 p-4 items-center"
                >
                    {/* Option Name */}
                    <div className="w-full md:w-1/6 text-left">
                        {option.label}
                    </div>

                    <input
                        type="checkbox"
                        checked={
                            responses[field_id]?.[option.label]?.reponse ||
                            false
                        }
                        onChange={() => handleCheckboxChange(option.label)}
                    />
                </div>
            ))}
        </div>
    );
};

export default CustomerSatisfaction;
