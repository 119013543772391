import config from './config';
import {jwtDecode} from 'jwt-decode';
import * as moment from 'moment';

import axios from 'axios';

class FastAPIClient {
  constructor(overrides) {
    this.config = {
      ...config,
      ...overrides,
    };
    this.authToken = config.authToken;
    this.login = this.login.bind(this);
    this.apiClient = this.getApiClient(this.config);
  }

  /* ----- Authentication & User Operations ----- */

  /* Authenticate the user with the backend services.
	 * The same JWT should be valid for both the api and cms */
  login(username, password) {
    delete this.apiClient.defaults.headers['Authorization'];

    // HACK: This is a hack for scenario where there is no login form
    const form_data = new FormData();
    const grant_type = 'password';
    const item = {grant_type, username, password};
    for (const key in item) {
      form_data.append(key, item[key]);
    }

    return this.apiClient
        .post('/auth/login', form_data)
        .then((resp) => {
          localStorage.setItem('token', JSON.stringify(resp.data));
          return this.fetchUserName();
        });
  }

  fetchUser() {
    return this.apiClient.get('/auth/me').then(({data}) => {
      localStorage.setItem('user', JSON.stringify(data));
      return data;
    });
  }

  fetchUserName() {
    return this.apiClient.get('/auth/me').then(({data}) => {
      localStorage.setItem('userfirstname', JSON.stringify(data["first_name"]).replace(/['"]+/g, ''));
      return data;
    });
  }


  // Logging out is just deleting the jwt.
  logout() {
    // Add here any other data that needs to be deleted from local storage
    // on logout
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('layoutData');
    localStorage.removeItem('language');
  }

  /* ----- Client Configuration ----- */

  /* Create Axios client instance pointing at the REST api backend */
  getApiClient(config) {
    const initialConfig = {
      baseURL: `${config.apiBasePath}/api/v1`,
    };
    const client = axios.create(initialConfig);

    // client.interceptors.request.use(localStorageTokenInterceptor);
    // Add the interceptor to Axios
    client.interceptors.request.use(
      config => {
        return localStorageTokenInterceptor(config);
      },
      error => {
        return Promise.reject(error);
      }
    );
    return client;
  }

  getProduct(productId) {
    return this.apiClient.get(`/product/${productId}`).then(({data}) => {
      return data;
    });
  }

  getProducts(clientId) {
    return this.apiClient.get(`/reco/products/${clientId}?max_results=50000`).then(({data}) => {
      return data;
    });
  }

  getAllProducts() {
    return this.apiClient.get(`/product/`).then(({data}) => {
      return data;
    });
  }

  getRecommendations(clientId) {
    return this.apiClient.get(`/recommendation/${clientId}`).then(({data}) => {
      return data;
    });
  }

  getProductSales(clientId) {
    return this.apiClient.get(`/productsales/${clientId}`).then(({data}) => {
      return data;
    });
  }

  getUsers(warehouse_id = null) {
    // Construct the URL with warehouse_id if provided
    let url = '/user/';

    // If warehouse_id is provided, add it to the query string
    if (warehouse_id) {
      url += `?warehouse_id=${warehouse_id}`;
    }

    return this.apiClient.get(url).then(({ data }) => {
      return data;
    });
  }

  getNotifications(userId) {
    return this.apiClient.get(`messages/notifications/${userId}`).then(({data}) => {
      return data;
    });
  }

  updateNotifications(userId, clientId) {
    return this.apiClient.patch(`messages/notifications/${clientId}/${userId}/read`).then(({data}) => {
      return data;
    });
  }

  getLeads(clientId) {
    return this.apiClient.get(`/leads/${clientId}`).then(({data}) => {
      return data;
    });
  }

  getReminder(clientId = null, userId = null) {
    const params = new URLSearchParams();

    if (clientId) {
        params.append("client_id", clientId);
    }
    if (userId) {
        params.append("user_id", userId);
    }

    const queryString = params.toString() ? `?${params.toString()}` : "";

    return this.apiClient.get(`/reminder/${queryString}`).then(({ data }) => {
        return data;
    });
}

  createReminder(data){
    console.log('Client JS',data);
    return this.apiClient.post(`/reminder/`,data);
  }

  updateLead(leadId, leadUpdate) {
    return this.apiClient.put(`/leads/${leadId}`, leadUpdate)
  }

  updateRecommendation(recommendationId, recommendationupdate) {
    return this.apiClient.put(`/recommendation/${recommendationId}`,recommendationupdate)
  }


  getClients(keyword) {
    return this.apiClient.get(`/clients/search/?keyword=${keyword}&max_results=10`).then(({data}) => {
      return data;
    });
  }


  getClient(id) {
    return this.apiClient.get(`/clients/${id}`).then(({data}) => {
      return data;
    });
  }

  getClientNew(id) {
    return this.apiClient.get(`/clients/full_details/${id}`).then(({data}) => {
      return data;
    });
  }

  getClientHistory(id) {
    return this.apiClient.get(`/clients/history/?id=${id}`).then(({data}) => {
      return data;
    });
  }

  getLayout() {
    return this.apiClient.get(`/layout/`).then(({data}) => {
      return data;
    });
  }

  // Send a new message
    sendMessage(messageData) {
      console.log(messageData)
        return this.apiClient.post(`/messages/`, messageData).then(({ data }) => {
            return data;
        }).catch(error => {
            console.error("Error sending message:", error);
            throw error;
        });
    }

    // Fetch messages for a chat
    getMessages(client_id) {
        return this.apiClient.get(`/messages/`, { params: { client_id } })
            .then(({ data }) => {
                return data.results;
            })
            .catch(error => {
                console.error("Error fetching messages:", error);
                throw error;
            });
    }

  getUserClients(user_id = null, client_id = null) {
    // Create the API endpoint URL
    let url = `/clients/full_details/`;

    // If a user_id is provided, append it to the URL
    if (user_id) {
      url += `?user_id=${user_id}`;
    }
    if (client_id) {
      url += `?client_id=${client_id}`;
    }

    // Make the API request
    return this.apiClient.get(url).then(({ data }) => {
      return data;
    });
  }

  getUserClientsOnline(user_id = null, client_id = null) {
    // Create the API endpoint URL
    let url = `/clients/user_clients/`;

    // If a user_id is provided, append it to the URL
    if (user_id) {
      url += `?user_id=${user_id}`;
    }
    if (client_id) {
      url += `?client_id=${client_id}`;
    }

    // Make the API request
    return this.apiClient.get(url).then(({ data }) => {
      return data;
    });
  }

  getUserClientswInfo(user_id = null, client_id = null) {
    // Create the API endpoint URL
    let url = `/clients/user_clients_w_info/`;

    // If a user_id is provided, append it to the URL
    if (user_id) {
      url += `?user_id=${user_id}`;
    }
    if (client_id) {
      url += `?client_id=${client_id}`;
    }

    // Make the API request
    return this.apiClient.get(url).then(({ data }) => {
      return data;
    });
  }

  getCharts() {
    return this.apiClient.get(`/charts/`).then(({data}) => {
      return data;
    });
  }

  getReceipts(client_id){
    return this.apiClient.get(`/receipt/?client_id=${client_id}`).then(({data}) => {
      return data;
    });
  }

  getProductClientOrders(client_id){
    return this.apiClient.get(`/product_client_orders/${client_id}`).then(({data}) => {
      return data;
    });
  }

  getReportLayout(meeting_type_id = null) {
    // Conditionally set the endpoint based on whether meeting_type_id is provided
    const url = meeting_type_id
        ? `/reports/layout/${meeting_type_id}`  // If meeting_type_id is provided, use it in the URL
        : '/reports/layout/';  // If no meeting_type_id, fetch all layouts

    return this.apiClient.get(url).then(({ data }) => {
        return data;
    });
}


  saveReport(reportdata){
    return this.apiClient.post(`/reports/`,reportdata);
  }

  getClientStats(client_id){

    return this.apiClient.get(`/client_stats/?client_id=${client_id}`).then(({data}) => {
      return data;
    });
  }


  resetNBA(recommendationId) {
    return this.apiClient.put(`/recommendation/reset/${recommendationId}`)
  }

  resetLeads() {
    return this.apiClient.put(`/leads/reset/`)
  }

  getMeetings(user_id, client_id, begin_time_frame, end_time_frame) {
    // Set up query parameters object, adding user_id, begin_time_frame, and end_time_frame if they exist
    const params = {};
    console.log(params);

    if (user_id) params.user_id = user_id;
    if (begin_time_frame) params.begin_time_frame = begin_time_frame;
    if (end_time_frame) params.end_time_frame = end_time_frame;
    if (client_id) params.client_id = client_id;

    // Pass the params as query parameters to the get request
    return this.apiClient.get(`/meetings/`, { params });
  }

  getMeetingTypes() {
    // Pass the params as query parameters to the get request
    return this.apiClient.get(`/meetings/meeting_types/`);
  }

  createMeeting(meeting) {
    // Set up query parameters object, adding user_id if it exists
    // const params = user_id ? { user_id } : {};

    // Pass the params as query parameters to the get request
    return this.apiClient.post(`/meetings/`,meeting);
  }

  updateMeeting(meeting_id, meeting) {
    console.log(meeting)
    return this.apiClient.put(`/meetings/${meeting_id}`, meeting)
  }


  deleteMeeting(meeting_id) {
    // Construct the endpoint URL with the meeting_id
    return this.apiClient.delete(`/meetings/${meeting_id}`);
}

deleteMeetingsByRecurrence(recurring_id, meeting_start_date) {
  // Construct the endpoint URL with the meeting_id
  return this.apiClient.delete(`/meetings/recurrence/${recurring_id}`, {
    params: { meeting_start_date: meeting_start_date },
  });
}

  resetClients() {
    return this.apiClient.put(`/clients/reset/`)
  }

  createClient(label, url, source, submitter_id) {
    const clientData = {
      label,
      url,
      source,
      submitter_id: submitter_id,
    };
    return this.apiClient.post(`/clients/`, clientData);
  }

  updateAlert(recommendationupdate) {
    return this.apiClient.put(`/clients/`,recommendationupdate)
  }

  getLeadNotifications() {
    return this.apiClient.get(`/leads/lead_count/`).then(({data}) => {
      return data;
    });
  }

  getRecommendationNotifications() {
    return this.apiClient.get(`/recommendation/notification_count/`).then(({data}) => {
      return data;
    });
  }

  getRecommendationValue() {
    return this.apiClient.get(`/recommendation/recommendation_value/`).then(({data}) => {
      return data;
    });
  }

  getReports(client_id, warehouse_id = null) {
    const params = {};

    if (client_id) params.client_id = client_id;
    if (warehouse_id) params.warehouse_id = warehouse_id; // Add warehouse_id if provided

    return this.apiClient.get(`/reports/`, { params }).then(({ data }) => {
        return data;
    });
}


  getClientProductCategories(warehouse_id = null, user_id = null) {
    // Construct the base URL
    let url = '/data/';
    const queryParams = [];

    // Add query parameters if they are provided
    if (warehouse_id) {
      queryParams.push(`warehouse_id=${warehouse_id}`);
    }
    if (user_id) {
      queryParams.push(`user_id=${user_id}`);
    }

    // Join the query parameters and append to the URL
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return this.apiClient.get(url).then(({ data }) => {
      return data;
    });
  }

  getClientCoverage(warehouse_id = null, user_id = null) {
    // Construct the URL with an optional warehouse_id query parameter
    let url = '/data/client_coverage/';

    const queryParams = [];

    // Add query parameters if they are provided
    if (warehouse_id) {
      queryParams.push(`warehouse_id=${warehouse_id}`);
    }
    if (user_id) {
      queryParams.push(`user_id=${user_id}`);
    }

    // Join the query parameters and append to the URL
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return this.apiClient.get(url).then(({ data }) => {
      return data;
    });
  }


  getManagementDashboard(userRoleId = null, warehouseId = null) {
    // Build query parameters if filters are provided
    let queryParams = '';

    console.log(userRoleId)
    console.log(warehouseId)

    if (userRoleId || warehouseId) {
      queryParams = `?`;

      if (userRoleId) {
        queryParams += `user_role_id=${encodeURIComponent(userRoleId)}`;
      }

      if (warehouseId) {
        if (userRoleId) queryParams += `&`; // If userRoleId exists, add an ampersand
        queryParams += `warehouse_id=${encodeURIComponent(warehouseId)}`;
      }
    }

    // Make the GET request with optional query parameters
    return this.apiClient.get(`/management_dashboard/${queryParams}`).then(({ data }) => {
      console.log(data)
      return data;
    });
  }


  getUserStats(user_id) {
    console.log(user_id)

    return this.apiClient
        .get(`/user_stats/?user_id=${user_id}`)
        .then(({ data }) => {
            return data;
        });
  }

  sendMail(mailData) {
    return this.apiClient.post(`/sendgrid/`, mailData);
  }

}


// Modify your localStorageTokenInterceptor function to work with Axios interceptors
function localStorageTokenInterceptor(request) {
  return new Promise((resolve) => {

    // Logging all elements in localStorage


    const headers = {};
    var tokenData_web = localStorage.getItem('@@auth0spajs@@::KKbOZIMeOk56DOxNpnwYCaEvwhnnoZjR::https://www.api.myway.technology::openid profile read:appointments');
    var tokenData = localStorage.getItem('token');

    // Check if tokenData is empty or null
    if ((tokenData == null || tokenData == undefined) && (tokenData_web == null || tokenData_web == undefined)) {
      // Wait for 3 seconds before retrying

      setTimeout(() => {
        // Call the function again recursively

        localStorageTokenInterceptor(request).then(resolve); // Call recursively after timeout
      }, 1000); // 10000 milliseconds = 10 seconds
    } else {


      const token = {"access_token":"","token_type":"bearer"}


      if (tokenData_web == null || tokenData_web == undefined){

        token.access_token = tokenData
      } else {
        const tokenObject = JSON.parse(tokenData_web);
        token.access_token = tokenObject.body.access_token

      }


      if (token) {
        // const token = JSON.parse(tokenString);
        const decodedAccessToken = jwtDecode(token.access_token);
        const isAccessTokenValid =
          moment.unix(decodedAccessToken.exp).toDate() > new Date();
        if (isAccessTokenValid) {
          headers['Authorization'] = `Bearer ${token.access_token}`;
        } else {
          console.log('expired')
        }
      }
      request.headers = headers;
      resolve(request); // Resolve the promise with the modified request object
    }
  });
}


export default FastAPIClient;
