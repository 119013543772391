import React from "react";
import { FiArrowUp, FiArrowDown } from "react-icons/fi"; // Make sure to install react-icons

const ClientStatistics = ({ statistics }) => {
    console.log(statistics);
    return (
        <div className="flex flex-col w-5/6 justify-center">
            {statistics.length > 0 &&
            statistics.some(
                (stat) =>
                    stat.display_main && stat.statistic_type === "comparison"
            ) ? (
                statistics
                    .filter(
                        (stat) =>
                            stat.display_main &&
                            stat.statistic_type === "comparison"
                    )
                    .sort((a, b) => a.data.name.localeCompare(b.data.name))
                    .map((stat, index) => (
                        <div key={index} className="mb-2">
                            <div className="w-full flex flex-row justify-between items-center">
                                <div className="text-left font-semibold w-2/3">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: stat.data.name,
                                        }}
                                    />
                                </div>
                                <div
                                    className={`rounded-full ${
                                        isNaN(
                                            parseFloat(
                                                stat.data.value_current_year
                                            )
                                        )
                                            ? "bg-rose-500 text-white" // Apply bg-rose-500 when NaN
                                            : stat.data.change > 0
                                            ? "bg-gold text-black"
                                            : stat.data.change < 0
                                            ? "bg-rose-500 text-white"
                                            : "bg-gold text-black"
                                    } font-bold text-sm w-12 h-5 py-1 flex items-center justify-center`}
                                >
                                    {isNaN(
                                        parseFloat(stat.data.value_current_year)
                                    )
                                        ? "0" // Display 0 if value_current_year is NaN
                                        : parseFloat(
                                              stat.data.value_current_year
                                          ).toFixed(0)}
                                </div>

                                <div
                                    className={`flex text-sm items-center ${
                                        stat.data.change > 0
                                            ? "text-black"
                                            : stat.data.change < 0
                                            ? "text-rose-500"
                                            : "text-rose-500" // Set text to red when change is 0 (like for 0 value_current_year)
                                    } font-semibold w-1/5 ml-2`}
                                >
                                    {isNaN(
                                        parseFloat(stat.data.value_current_year)
                                    ) || stat.data.value_current_year === 0 ? (
                                        <>
                                            <span className="ml-1">100%</span>
                                            <FiArrowDown className="text-rose-500" />
                                        </>
                                    ) : stat.data.change > 0 ? (
                                        <>
                                            <span className="ml-1">
                                                {parseFloat(
                                                    stat.data.change
                                                ).toFixed(0)}
                                                %
                                            </span>
                                            <FiArrowUp />
                                        </>
                                    ) : stat.data.change < 0 ? (
                                        <>
                                            <span className="ml-1">
                                                {parseFloat(
                                                    stat.data.change
                                                ).toFixed(0)}
                                                %
                                            </span>
                                            <FiArrowDown />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
            ) : (
                <div className="text-gray-500 text-center mt-4">
                    Pas de vente depuis le 1er janvier.
                </div>
            )}
        </div>
    );
};

export default ClientStatistics;
