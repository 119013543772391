import React, {useState} from "react";
import ProductSearch from "../../Search/ProductSearch";
import { FiRotateCw } from "react-icons/fi";

const ClientOrders = ({ clientorders }) => {
    // Helper function to format the date
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
        const day = String(date.getDate()).padStart(2, "0");
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year
        return `${day}/${month}/${year}`;
    };

    

    const sortedOrders = [...clientorders].sort((a, b) => {
        // First sort by `is_due` (true before false)
        if (a.is_due !== b.is_due) {
            return a.is_due ? -1 : 1;
        }
        // If `is_due` is the same, sort by `last_order_date` in descending order
        return new Date(b.last_order_date) - new Date(a.last_order_date);
    });

    const [filteredOrders, setFilteredOrders] = useState(sortedOrders)

    // TO DO : fix overflow pb
    // TO DO : sort by column

    return (
        <div className="md:pl-0 md:pr-0 w-11/12 pl-1 pr-1">
            <div className="sticky top-0 bg-white w-full">
            <ProductSearch clients={sortedOrders} handleClick={null} setFilteredOrders={setFilteredOrders}/>
            <div className="flex flex-row w-full justify-between items-center mb-4 h-8 mt-1">
                <div className="text-sm font-semibold text-left w-5/12">
                    Nom
                </div>
                <div className="text-center text-sm font-semibold w-1/12">
                    Dû
                </div>
                <div className=" text-left w-1/12"></div>

                <div className="text-sm font-semibold text-center w-1/5 hidden sm:block">
                    ID
                </div>
                <div className="text-sm  font-semibold text-center w-1/6">
                    Date
                </div>
                <div className="text-sm  font-semibold text-center w-1/6">
                    <span className="md:block hidden">Quantité</span>
                    <span className="md:hidden">Quant.</span>
                </div>
            </div>
            </div>
            <div className="overflow-y-scroll overflow-y-auto scrollbar">
                {filteredOrders.map((product, index) => (
                    <div
                        key={index}
                        className="flex flex-row w-full justify-between items-center pb-1 mt-1 border-b border-gray-200"
                    >
                        <div className="text-left text-sm w-5/12 ">
                            {product.product_name}
                        </div>
                        <div className="w-1/12 flex justify-center">
                            {product.is_due && (
                                <div className="flex w-11/12 md:w-1/2 justify-center rounded-full bg-black text-gold text-xs text-center items-center font-bold px-1 py-1">
                                    <FiRotateCw />
                                </div>
                            )}
                        </div>
                        <div className="w-1/12 flex justify-center">
                            {product.is_promo && (
                                <div className="rounded-full w-11/12 md:w-1/2 bg-rose-500 text-white text-xxxs text-center font-bold px-1 py-1 ">
                                    %
                                </div>
                            )}
                        </div>
                        <div className="text-xs  text-center w-1/5 hidden sm:block">
                            {product.product_id}
                        </div>
                        <div className="text-xs  text-center w-1/6 ">
                            {formatDate(product.last_order_date)}
                        </div>
                        <div className="text-xs w-1/6 text-center ">
                            {Math.round(product.last_order_quantity)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ClientOrders;
