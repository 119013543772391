import React, { useState } from "react";
import { FiChevronUp, FiChevronDown, FiTrash2 } from "react-icons/fi";

const DiscussedProductCategories = ({
    field_name,
    field_id,
    handleInputChange,
    responses,
    options,
}) => {
    const [expandedOptions, setExpandedOptions] = useState({});
    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleCheckboxChange = (optionValue, key) => {
        const currentResponse = responses[field_id] || {};
        const updatedOptionResponse = {
            ...currentResponse[optionValue],
            [key]: !currentResponse[optionValue]?.[key],
        };
        const newResponse = {
            ...currentResponse,
            [optionValue]: updatedOptionResponse,
        };
        handleInputChange(field_id, newResponse);
    };

    const handleTextChange = (optionValue, key, value) => {
        const currentResponse = responses[field_id] || {};
        const updatedOptionResponse = {
            ...currentResponse[optionValue],
            [key]: value,
        };
        const newResponse = {
            ...currentResponse,
            [optionValue]: updatedOptionResponse,
        };
        handleInputChange(field_id, newResponse);
    };

    const toggleExpandedOption = (optionLabel) => {
        setExpandedOptions((prevState) => ({
            ...prevState,
            [optionLabel]: !prevState[optionLabel],
        }));
    };

    const handleCategorySelect = (e) => {
        const selectedCategory = e.target.value;
        if (
            selectedCategory &&
            !selectedCategories.includes(selectedCategory)
        ) {
            setSelectedCategories([...selectedCategories, selectedCategory]);
        }
    };

    const handleDeleteCategory = (category) => {
        setSelectedCategories(
            selectedCategories.filter((item) => item !== category)
        );

        // Clear associated response data
        const currentResponse = responses[field_id] || {};
        const remainingResponse = { ...currentResponse };
        delete remainingResponse[category];
        handleInputChange(field_id, remainingResponse);
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="text-left w-full font-semibold">{field_name}</div>

            {/* Render Selected Categories */}
            {selectedCategories.map((category, index) => (
                <div
                    key={`${field_id}-${index}`}
                    className="flex flex-col md:flex-row text-left gap-4 items-center w-full"
                >
                    <div className="flex justify-between items-center w-full md:w-1/6">
                        <div>{category}</div>
                        <div className="flex gap-2">
                            <button
                                className="md:hidden flex items-center"
                                onClick={() => toggleExpandedOption(category)}
                            >
                                {expandedOptions[category] ? (
                                    <FiChevronUp size={24} />
                                ) : (
                                    <FiChevronDown size={24} />
                                )}
                            </button>
                            <button
                                onClick={() => handleDeleteCategory(category)}
                                className="text-red-600 block md:hidden"
                            >
                                <FiTrash2 size={20} />
                            </button>
                        </div>
                    </div>

                    {(expandedOptions[category] ||
                        window.innerWidth >= 768) && (
                        <div className="flex flex-col md:flex-row gap-4 w-full">
                            <label className="flex items-center gap-2 w-full md:w-1/6">
                                <input
                                    type="checkbox"
                                    checked={
                                        responses[field_id]?.[category]
                                            ?.discussed || false
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(
                                            category,
                                            "discussed"
                                        )
                                    }
                                />
                                Discutée
                            </label>

                            <label className="flex items-center gap-2 w-full md:w-1/6">
                                <input
                                    type="checkbox"
                                    checked={
                                        responses[field_id]?.[category]
                                            ?.interest_expressed || false
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(
                                            category,
                                            "interest_expressed"
                                        )
                                    }
                                />
                                Intérêt manifesté
                            </label>

                            <input
                                type="text"
                                value={
                                    responses[field_id]?.[category]
                                        ?.specific_interest || ""
                                }
                                onChange={(e) =>
                                    handleTextChange(
                                        category,
                                        "specific_interest",
                                        e.target.value
                                    )
                                }
                                placeholder="Intérêt spécifique"
                                className="border border-gray-400 rounded-md p-2 w-full md:w-1/6"
                            />

                            <input
                                type="text"
                                value={
                                    responses[field_id]?.[category]
                                        ?.product_interest || ""
                                }
                                onChange={(e) =>
                                    handleTextChange(
                                        category,
                                        "product_interest",
                                        e.target.value
                                    )
                                }
                                placeholder="Intérêt Produit"
                                className="border border-gray-400 rounded-md p-2 w-full md:w-1/6"
                            />

                            <input
                                type="text"
                                value={
                                    responses[field_id]?.[category]?.comments ||
                                    ""
                                }
                                onChange={(e) =>
                                    handleTextChange(
                                        category,
                                        "comments",
                                        e.target.value
                                    )
                                }
                                placeholder="Commentaires"
                                className="border border-gray-400 rounded-md p-2 w-full md:w-1/6"
                            />
                            <button
                                onClick={() => handleDeleteCategory(category)}
                                className="text-red-600 hidden md:block"
                            >
                                <FiTrash2 size={20} />
                            </button>
                        </div>
                    )}
                </div>
            ))}

            {/* Spacer to push the selection to the bottom */}
            <div className="flex-grow"></div>

            {/* Category Selection */}
            <div className="flex flex-col gap-4 items-center  w-full md:w-1/2">
                <select
                    value=""
                    onChange={handleCategorySelect}
                    className="p-2 border border-gray-300 rounded-md w-full"
                >
                    <option value="">
                        Sélectionnez une catégorie de produit
                    </option>
                    {options
                        .filter(
                            (option) =>
                                !selectedCategories.includes(option.label)
                        )
                        .map((option, index) => (
                            <option key={index} value={option.label}>
                                {option.label}
                            </option>
                        ))}
                </select>
            </div>
        </div>
    );
};

export default DiscussedProductCategories;
