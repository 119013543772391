import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom"; // Make sure you import useNavigate
import { FormattedMessage } from "react-intl";

const ProductSearch = ({
	clients,
	handleClick,
	selectedProduct,
	setFilteredOrders,
}) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [filteredClients, setFilteredClients] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);

	// const nav = useNavigate(); // Use the navigate function from react-router-dom

	useEffect(() => {
		if (searchTerm === "") {
			setFilteredClients([]);
            if(setFilteredOrders) {
                setFilteredOrders(clients)
            }
		} else {
			const filtered = clients.filter(
				(client) =>
					(client.short_label || client.product_name)
						.toLowerCase()
						.includes(searchTerm.toLowerCase()) ||
					client.product_id.toString().includes(searchTerm) // Convert product_id to string before searching
			);
			setFilteredClients(filtered);
			if (setFilteredOrders) {
				setFilteredOrders(filtered);
			}
		}
	}, [searchTerm]);

	const toggleShowSuggestions = (arg) => {
		if (!handleClick) {
			setShowSuggestions(false);
		} else {
			setShowSuggestions(arg);
		}
	};

	useEffect(() => {
		toggleShowSuggestions(false);
	}, [selectedProduct]);

	const handleChange = (e) => {
		setSearchTerm(e.target.value);
		if (handleClick) {
			setShowSuggestions(true);
		}
	};


	return (
		<div className="relative flex flex-col md:flex-row items-center w-full">
			<FormattedMessage
				id="home.SearchClients"
				defaultMessage="Rechercher un produit ..."
			>
				{(placeholder) => (
					<input
						type="text"
						className={`mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-black`}
						style={{
							minWidth: 350,
						}}
						placeholder={placeholder}
						value={searchTerm}
						onChange={handleChange}
						onBlur={() => toggleShowSuggestions(false)} // Hide suggestions on blur
						onFocus={() => toggleShowSuggestions(true)} // Show suggestions on focus
					/>
				)}
			</FormattedMessage>

			{/* Show suggestions if any clients match the search term */}
			{showSuggestions && filteredClients.length > 0 && (
				<div className="absolute top-full w-full bg-white border border-slate-300 rounded-lg shadow-lg max-h-60 overflow-y-auto mt-2 z-10 ">
					{filteredClients.map((client) => (
						<div
							key={client.product_id}
							onMouseDown={(e) => {
								e.preventDefault(); // Prevent any unwanted behavior
								handleClick(client);
							}}
							className="cursor-pointer px-3 py-2 hover:bg-slate-100 w-full"
						>
							<div className="flex flex-row items-center border-b border-gray-100">
								<div className={client.short_label ? "w-1/2" : "w-5/6"}>
									{client.short_label || client.product_name}
								</div>
								<div className="text-gray-500 text-sm ml-2 w-1/6">
									{client.product_id}
								</div>
								<div
									className={`text-gray-500 text-sm ml-2 w-1/3 ${
										client.packaging_code ? "w-1/3" : "w-0"
									}`}
								>
									{client.packaging_code}
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default ProductSearch;
