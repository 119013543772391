import React, { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory
import FastAPIClient from "../../client";
import config from "../../config";
import { FiArrowLeft } from "react-icons/fi";

const client = new FastAPIClient(config);

const POLLING_INTERVAL = 5000;

const { v4: uuidv4 } = require("uuid");

const formatDate = (timestamp) => {
    if (!timestamp) return "-";
    const date = new Date(timestamp);
    return date.toLocaleDateString("fr-FR", {
        // "fr-FR" for French formatting
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    });
};

const Channel = ({
    client_id,
    currentUser,
    clientName,
    header = true,
    setSelectedClient,
    isOnline,
    messages,
    setMessages,
    // setSelectedClient,
}) => {
    const [users, setUsers] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [rawMessage, setRawMessage] = useState("");
    const [showUserList, setShowUserList] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const [isSending, setIsSending] = useState(false);

    // const navigate = useNavigate(); // Initialize useNavigate to navigate programmatically

    // Fetch users
    const fetchUsers = async () => {
        try {
            const response = await client.getUsers();
            const activeUsers = response.results.filter(
                (user) => user.is_active === true
            );
            setUsers(activeUsers);
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    // Fetch messages
    const fetchMessages = async () => {
        try {
            const response = await client.getMessages(client_id);
            setMessages(response);
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchMessages();
        const interval = setInterval(fetchMessages, POLLING_INTERVAL);
        return () => clearInterval(interval);
    }, [client_id]);

    // Format message content for display
    const formatMessageContent = (content) => {
        return content.replace(/<tag>@([\w_]+)<\/tag>/g, (match, userId) => {
            const user = users.find((user) => user.user_id === userId);
            if (user) {
                return `@${user.first_name} ${user.last_name}`;
            } else {
                return match;
            }
        });
    };

    // Convert message to raw format for storage
    const formatMessageForStorage = (input) => {
        let updatedMessage = input;
        users.forEach((user) => {
            const fullName = `@${user.first_name} ${user.last_name}`;
            const mentionTag = `<tag>@${user.user_id}</tag>`;
            updatedMessage = updatedMessage.replace(fullName, mentionTag);
        });
        return updatedMessage;
    };

    // Handle input change for new message
    const handleInputChange = (e) => {
        const input = e.target.value;
        setNewMessage(input);
        setRawMessage(formatMessageForStorage(input));

        const match = input.match(/@(\w*)$/);
        if (match) {
            const query = match[1].toLowerCase();
            const usersToShow = users.filter((user) =>
                `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()}`.includes(
                    query
                )
            );
            setFilteredUsers(usersToShow);
            setShowUserList(true);
        } else {
            setShowUserList(false);
        }
    };

    const inputRef = useRef(null); // Create a ref for the input element

    // Handle user selection for tagging
    const handleUserSelect = (user) => {
        const updatedMessage = newMessage.replace(
            /@\w*$/,
            `@${user.first_name} ${user.last_name} `
        );
        const updatedRawMessage = rawMessage.replace(
            /@\w*$/,
            `<tag>@${user.user_id}</tag> `
        );

        setNewMessage(updatedMessage);
        setRawMessage(updatedRawMessage);
        setShowUserList(false);

        // Focus back on the input after selecting a user
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const messagesEndRef = useRef(null); // Reference for the scroll container

    // Scroll to the bottom when messages change
    useEffect(() => {
        const scrollToBottom = () => {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollTop =
                    messagesEndRef.current.scrollHeight;
            }
        };
        // Use setTimeout to ensure DOM is fully updated
        setTimeout(scrollToBottom, 0);
    }, [messages]);

    // Handle message submission
    // Handle message submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prevent sending empty messages
        if (!newMessage.trim()) {
            return;
        }

        setIsSending((prevIsSending) => !prevIsSending);
        try {
            const newMsg = {
                message_id: uuidv4(),
                user_id: currentUser,
                client_id: client_id,
                content: rawMessage,
                message_type: "text",
            };
            await client.sendMessage(newMsg);
            setMessages((prevMessages) => [...prevMessages, newMsg]);
            setNewMessage("");
            setRawMessage("");
        } catch (error) {
            console.error("Error sending message:", error);
        }
        setIsSending((prevIsSending) => !prevIsSending);
    };

    return (
        <div className="flex flex-col h-full ">
            {/* Mobile Back Button */}
            <div className="block md:hidden text-left">
                <button
                    onClick={() => setSelectedClient()} // Go back to the previous page
                    className="bg-gray-300 text-black p-2 rounded-full mb-4"
                >
                    <FiArrowLeft />
                </button>
            </div>

            {header && (
                <h3 className="p-4 text-cl font-bold text-left w-full border-b gray-300">
                    {clientName}
                </h3>
            )}

            {/* <button onClick={setSelectedClient(null)}>Go Back</button> */}

            <div
                className="flex-grow overflow-y-auto overflow-x-hidden h-60 p-4 w-full relative"
                ref={messagesEndRef}
            >
                {messages
                    .filter((msg) => msg.user_id !== undefined) // Exclude undefined users
                    .map((msg) => (
                        <div
                            key={msg.id}
                            className={`mb-6 flex flex-col ${
                                msg.user_id === currentUser
                                    ? "items-end"
                                    : "items-start"
                            }`}
                            style={{
                                maxWidth: "100%",
                                wordBreak: "break-word",
                            }}
                        >
                            {msg.user_id !== currentUser && (
                                <div className="text-xs text-gray-500 mb-3 ml-3 font-bold">
                                    {users.find(
                                        (u) => u.user_id === msg.user_id
                                    )?.first_name &&
                                    users.find((u) => u.user_id === msg.user_id)
                                        ?.last_name ? (
                                        <>
                                            {
                                                users.find(
                                                    (u) =>
                                                        u.user_id ===
                                                        msg.user_id
                                                )?.first_name
                                            }{" "}
                                            {
                                                users.find(
                                                    (u) =>
                                                        u.user_id ===
                                                        msg.user_id
                                                )?.last_name
                                            }
                                        </>
                                    ) : (
                                        `User ${msg.user_id}`
                                    )}
                                </div>
                            )}
                            <div
                                className={`rounded-full px-6 py-1 text-left relative ${
                                    msg.user_id === currentUser
                                        ? "text-white bg-black"
                                        : "bg-gray-200 text-black"
                                }`}
                            >
                                <div
                                    className={`absolute bottom-0 w-[500px] text-xs text-gray-400 italic translate-y-3/4 pt-[9px] ${
                                        msg.user_id === currentUser
                                            ? "text-right  right-0  pr-1"
                                            : "text-left left-0 pl-1"
                                    }`}
                                >
                                    {formatDate(msg.created_at)}
                                </div>
                                <div
                                    className="text-sm"
                                    dangerouslySetInnerHTML={{
                                        __html: msg.content
                                            ? formatMessageContent(msg.content)
                                            : msg.content,
                                    }}
                                />
                            </div>
                        </div>
                    ))}
            </div>

            <form
                onSubmit={handleSubmit}
                className="relative flex items-center p-2 bg-white border-t border-gray-300 sticky rounded-b-full"
            >
                {isSending && (
                    <div className="absolute top-0 -translate-y-3/4 pb-6 w-full text-center flex justify-center">
                        <span className="bg-green-100 px-3 py-1 rounded-full text-sm italic">
                            Envoi en cours ...
                        </span>
                    </div>
                )}
                <input
                    type="text"
                    ref={inputRef} // Attach the ref to the input element
                    value={newMessage}
                    onChange={handleInputChange}
                    placeholder={
                        isOnline
                            ? "Tapez un message ..."
                            : "Pas d'envoi possible hors-ligne"
                    }
                    className="flex-1 p-2 border border-gray-300 rounded-full"
                    disabled={!isOnline}
                />
                <button
                    type="submit"
                    className={`ml-2 px-4 py-2 bg-black text-white rounded-full text-sm ${
                        !newMessage.trim() && "opacity-50 cursor-not-allowed"
                    }`}
                    disabled={!newMessage.trim()}
                >
                    Envoyer
                </button>
                {showUserList && (
                    <div className="absolute bottom-16 left-4 w-64 bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-auto text-xs">
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map((user) => (
                                <div
                                    key={user.user_id}
                                    onClick={() => handleUserSelect(user)}
                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                >
                                    @{user.first_name} {user.last_name}
                                </div>
                            ))
                        ) : (
                            <div className="px-4 py-2 text-gray-500">
                                No users found
                            </div>
                        )}
                    </div>
                )}
            </form>
        </div>
    );
};

export default Channel;
